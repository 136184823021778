import { Button, Card, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import contentApi from "../../../../api/contentApi";
import InputField from "../../../../components/InputField";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";
import { ConvertSlug } from "../../../../utils/functions/convertSlug";
import InputNumberWrap from "../../../../components/InputNumber";

interface FormCategoryProps {
  type: number;
}

const FormCategoryBaiViet: FC<FormCategoryProps> = ({ type }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery(
    ["danh-muc-bai-viet-post", "post", id],
    () => contentApi.getCategoryBaiBietV3(id),
    { enabled: type === 2 }
  );

  const listKeys: any = useQuery(
    "list-page-category",
    contentApi.getListDanhMucByPage
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(contentApi.editCategoryBaiBietV3, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("create-category-bai-viet");
        navigate(appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(contentApi.addCategoryBaiBietV3, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("create-category-bai-viet");
        navigate(appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST);
        window.scrollTo(0, 0);
      }
    },
  });

  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };
  return (
    <PageWrap
      title="Chi tiết danh mục bài viết"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục bài viết</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  <InputField
                    label="Tiêu đề"
                    name="ten_vi"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.ten_vi}
                    errorMsg={errors?.ten_vi?.message}
                    rules={{
                      required: "Vui lòng nhập tiêu đề",
                    }}
                    onChange={(e: any) => {
                      setValue("slug_vi", ConvertSlug(e.target.value));
                    }}
                  />
                  <InputField
                    label="Slug"
                    name="slug_vi"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.slug_vi}
                    errorMsg={errors?.slug_vi?.message}
                    rules={{
                      required: "Vui lòng nhập slug",
                    }}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="stt"
                    control={control}
                    defaultValue={data?.data?.stt ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("stt", 1);
                    }}
                    min={1}
                  />
                  <SelectField
                    placeholder="Vui lòng chọn page"
                    control={control}
                    label="Page"
                    name="type"
                    disable={type === 2}
                    options={listKeys?.data?.data?.map((e: any) => ({
                      key: e.type,
                      value: e.page,
                    }))}
                    defaultValue={data?.data?.type ?? listKeys?.data?.data[0].type}
                    errorMsg={errors?.type?.message}
                    rules={{
                      required: "Vui lòng chọn page",
                    }}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default FormCategoryBaiViet;

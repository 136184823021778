import { Button, Card, Form, Row } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Ckeditor from "../../../../components/Ckeditor";
import Loading from "../../../../components/Loading";
import { formItemLayoutSm } from "../../../../utils/constants";

interface TermFormProps {
  loading: boolean;
  dieukhoan: string | undefined;
  baomat: string | undefined;
  quydinh: string | undefined;
  onSubmit: (e: any) => void;
}
const TermForm: FC<TermFormProps> = ({
  loading,
  dieukhoan,
  baomat,
  quydinh,
  onSubmit,
}) => {
  const { handleSubmit, setValue } = useForm();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card bordered={false}>
          <Form {...formItemLayoutSm} colon={false}>
            <Form.Item label="Điều khoản sử dụng">
              <Ckeditor
                initData={dieukhoan}
                onChange={(evt: any) =>
                  setValue("dieukhoan", evt.editor.getData())
                }
              />
            </Form.Item>
            <Form.Item label="Bảo mật thông tin">
              <Ckeditor
                initData={baomat}
                onChange={(evt: any) =>
                  setValue("baomat", evt.editor.getData())
                }
              />
            </Form.Item>
            <Form.Item label="Quy định truyền thông">
              <Ckeditor
                initData={quydinh}
                onChange={(evt: any) =>
                  setValue("quydinh", evt.editor.getData())
                }
              />
            </Form.Item>
            <Row justify="start" gutter={[10, 0]}>
              <Button type="primary" danger onClick={handleSubmit(onSubmit)}>
                Lưu
              </Button>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};

export default TermForm;

import { Button, Card, Checkbox, Col, Form, Row, Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { formItemLayoutSm } from "../../utils/constants";
import { loadFile } from "../../utils/functions/loadFile";
import Ckeditor from "../Ckeditor";
import InputField from "../InputField";
import InputNumberWrap from "../InputNumber";
import Loading from "../Loading";
import TextAreaField from "../TextAreaField";
import TopContent from "../TopContent";
import { ConvertSlug } from "../../utils/functions/convertSlug";
import SelectField from "../SelectField";
import contentApi from "../../api/contentApi";

interface ContentFormProps {
  link: string;
  keyQuery: any;
  query: any;
  headContent: string;
  mutationEdit: any;
  mutationAdd: any;
  type: number;
  conditions: boolean;
  seo: React.ReactNode;
  file: any;
  seoVal: any;
  danhmuc: any | undefined;
  setFile: (e: any) => void;
  setValues: (e: any) => void;
}

const ContentForm: FC<ContentFormProps> = ({
  type,
  link,
  keyQuery,
  query,
  headContent,
  mutationAdd,
  mutationEdit,
  conditions,
  file,
  seo,
  seoVal,
  danhmuc,
  setFile,
  setValues,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, isFetching }: any = useQuery(keyQuery, query, {
    enabled: conditions,
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [image, setImage]: any = useState();
  const [tagVal, setTagVal]: any = useState();
  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };
  useEffect(() => {
    if (conditions) setValues(data?.data);
    setTagVal(data?.data?.tag !== "" ? data?.data?.tag?.split(",") : []);
  }, [data]);
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("photo", file);
    formData.set("chuyenmuc", dt.chuyenmuc ?? "");
    formData.set("ten_vi", dt.ten_vi ?? "");
    formData.set("stt", dt.stt ?? "");
    formData.set("slug_vi", dt.slug_vi ?? "");
    formData.set("mota_vi", dt.mota_vi ?? "");
    formData.set("noidung_vi", dt.noidung_vi ?? data?.data?.noidung_vi ?? "");
    formData.set(
      "noidung1_vi",
      dt.noidung1_vi ?? data?.data?.noidung1_vi ?? ""
    );
    formData.set(
      "noidung2_vi",
      dt.noidung2_vi ?? data?.data?.noidung2_vi ?? ""
    );
    formData.set(
      "noidung3_vi",
      dt.noidung3_vi ?? data?.data?.noidung3_vi ?? ""
    );
    formData.set(
      "noidung4_vi",
      dt.noidung4_vi ?? data?.data?.noidung4_vi ?? ""
    );
    formData.set(
      "noidung5_vi",
      dt.noidung5_vi ?? data?.data?.noidung5_vi ?? ""
    );
    formData.set(
      "noidung6_vi",
      dt.noidung6_vi ?? data?.data?.noidung6_vi ?? ""
    );
    formData.set(
      "noidung7_vi",
      dt.noidung7_vi ?? data?.data?.noidung7_vi ?? ""
    );

    formData.set("tag", dt.tag ?? data?.data?.tag ?? []);
    formData.set("category", dt.category ?? data?.data?.category);
    formData.set("title_vi", seoVal?.title_vi ?? data?.data?.title_vi ?? "");
    formData.set(
      "keywords_vi",
      seoVal?.keywords_vi ?? data?.data?.keywords_vi ?? ""
    );
    formData.set(
      "description_vi",
      seoVal?.description_vi ?? data?.data?.description_vi ?? ""
    );
    if (type === 1) {
      formData.set("hienthi", dt.hienthi ?? data?.data?.hienthi ?? false);
      formData.set("noibat", dt.noibat ?? data?.data?.noibat ?? false);
      formData.set("premium", dt.premium ?? data?.data?.premium ?? false);
    }
    if (dt.hienthi !== undefined && type === 2)
      formData.set("hienthi", dt.hienthi);
    if (dt.premium !== undefined && type === 2)
      formData.set("premium", dt.premium);
    if (dt.noibat !== undefined && type === 2)
      formData.set("noibat", dt.noibat);
    if (type === 2) mutationEdit.mutate({ id: id, data: formData });
    if (type === 1) mutationAdd.mutate(formData);
  };

  const { data: listKeys }: any = useQuery(
    ["list-danhmuc-category", danhmuc],
    () => contentApi.getListDanhMucByID(danhmuc),
    {
      enabled: !!danhmuc,
    }
  );

  const labels = ["Tổng quan", "SEO option"];
  const getLayout = (index: number) => {
    if (index === 0)
      return (
        <>
          {isLoading || isFetching ? (
            <Loading />
          ) : (
            <Card bordered={false}>
              <Form {...formItemLayoutSm} className="ckeditor_form">
                {(image || (data?.data?.photo && type === 2)) && (
                  <div className="cash_img">
                    <img
                      src={
                        image ??
                        process.env.REACT_APP_DOMAIN + data?.data?.photo
                      }
                      alt=""
                    />
                  </div>
                )}
                <InputField
                  isImage={true}
                  name="photo"
                  control={control}
                  rules={
                    type === 1
                      ? {
                          required: "Vui lòng chọn hình ảnh",
                        }
                      : undefined
                  }
                  label="Hình ảnh"
                  errorMsg={errors?.photo?.message}
                  onChange={(e: any) => {
                    handleUpload(e.target.files?.[0]);
                  }}
                />
                <InputField
                  label="Tiêu đề"
                  name="ten_vi"
                  setValue={setValue}
                  control={control}
                  defaultValue={data?.data?.ten_vi}
                  errorMsg={errors?.ten_vi?.message}
                  rules={{
                    required: "Vui lòng nhập tiêu đề",
                  }}
                  onChange={(e: any) => {
                    setValues(getValues());
                    setValue("slug_vi", ConvertSlug(e.target.value));
                  }}
                />
                <InputField
                  label="Slug"
                  name="slug_vi"
                  control={control}
                  setValue={setValue}
                  defaultValue={data?.data?.slug_vi}
                  errorMsg={errors?.slug_vi?.message}
                  rules={{
                    required: "Vui lòng nhập slug",
                    minLength: {
                      value: 10,
                      message: "Vui lòng nhập trên 10 kí tự",
                    },
                  }}
                  onChange={() => {
                    setValues(getValues());
                  }}
                />
                <TextAreaField
                  label="Mô tả"
                  name="mota_vi"
                  control={control}
                  defaultValue={data?.data?.mota_vi}
                  errorMsg={errors?.mota_vi?.message}
                  onChange={() => {
                    setValues(getValues());
                  }}
                />
                {danhmuc && (
                  <SelectField
                    placeholder="Danh mục"
                    control={control}
                    label="Danh mục"
                    name="category"
                    options={listKeys?.data?.map((e: any) => ({
                      key: e._id,
                      value: e.ten_vi,
                    }))}
                    defaultValue={
                      data?.data?.category
                    }
                    errorMsg={errors?.category?.message}
                  />
                )}

                <Form.Item className="ckeditor_form__item">
                  <Ckeditor
                    initData={data?.data?.noidung_vi}
                    onChange={(evt: any) => {
                      setValue("noidung_vi", evt.editor.getData());
                      setValues(getValues());
                    }}
                  />
                  <Row
                    gutter={[30, 23]}
                    style={{ marginTop: "23px", marginBottom: "23px" }}
                  >
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung1_vi}
                        onChange={(evt: any) => {
                          setValue("noidung1_vi", evt.editor.getData());
                          setValues(getValues());
                        }}
                      />
                    </Col>
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung2_vi}
                        onChange={(evt: any) => {
                          setValue("noidung2_vi", evt.editor.getData());
                          setValues(getValues());
                        }}
                      />
                    </Col>
                  </Row>
                  <Ckeditor
                    initData={data?.data?.noidung3_vi}
                    onChange={(evt: any) => {
                      setValue("noidung3_vi", evt.editor.getData());
                      setValues(getValues());
                    }}
                  />
                  <Row
                    gutter={[30, 23]}
                    style={{ marginTop: "23px", marginBottom: "23px" }}
                  >
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung4_vi}
                        onChange={(evt: any) => {
                          setValue("noidung4_vi", evt.editor.getData());
                          setValues(getValues());
                        }}
                      />
                    </Col>
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung5_vi}
                        onChange={(evt: any) => {
                          setValue("noidung5_vi", evt.editor.getData());
                          setValues(getValues());
                        }}
                      />
                    </Col>
                  </Row>
                  <div style={{ marginBottom: "23px" }}>
                    <Ckeditor
                      initData={data?.data?.noidung6_vi}
                      onChange={(evt: any) => {
                        setValue("noidung6_vi", evt.editor.getData());
                        setValues(getValues());
                      }}
                    />
                  </div>
                  <Ckeditor
                    initData={data?.data?.noidung7_vi}
                    onChange={(evt: any) => {
                      setValue("noidung7_vi", evt.editor.getData());
                      setValues(getValues());
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Tag"
                  colon={false}
                  style={{ marginTop: "26px" }}
                >
                  <TagsInput
                    value={tagVal}
                    onChange={(e: any) => {
                      setValue("tag", e);
                    }}
                    onBlur={() => {
                      if (getValues()?.tag) {
                        setTagVal(getValues()?.tag);
                      }
                      setValues(getValues());
                    }}
                    onKeyUp={(e: any) => {
                      if (e?.key === "Enter") {
                        e.target.value = "";
                      }
                    }}
                  />
                </Form.Item>
                <InputNumberWrap
                  label="STT"
                  name="stt"
                  control={control}
                  defaultValue={data?.data?.stt ?? 1}
                  onBlur={(e: any) => {
                    if (e.target.value === "") setValue("stt", 1);
                  }}
                  min={1}
                  onChange={() => {
                    setValues(getValues());
                  }}
                />
                <Form.Item className="checkbox">
                  <Row gutter={[20, 5]}>
                    <Col>
                      <Checkbox
                        defaultChecked={data?.data?.premium}
                        name="premium"
                        onClick={(e: any) => {
                          setValue("premium", e.target.checked);
                          setValues(getValues());
                        }}
                      />
                      &nbsp;Premium
                    </Col>
                    <Col>
                      <Checkbox
                        defaultChecked={data?.data?.noibat}
                        name="noibat"
                        onClick={(e: any) => {
                          setValue("noibat", e.target.checked);
                          setValues(getValues());
                        }}
                      />
                      &nbsp;Nổi bật
                    </Col>
                    <Col>
                      <Checkbox
                        defaultChecked={data?.data?.hienthi}
                        name="hienthi"
                        onClick={(e: any) => {
                          setValue("hienthi", e.target.checked);
                          setValues(getValues());
                        }}
                      />
                      &nbsp;Hiển thị
                    </Col>
                  </Row>
                </Form.Item>
                <Row justify="start" gutter={[10, 0]}>
                  <Col>
                    <Button
                      type="primary"
                      danger
                      onClick={handleSubmit(onSubmit)}
                    >
                      Lưu
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(link);
                      }}
                    >
                      Hủy
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          )}
        </>
      );
    else return seo;
  };
  return (
    <>
      <TopContent
        content={
          <Row>
            <Col>
              <b>{headContent}</b>
            </Col>
          </Row>
        }
      />
      <div className="content">
        <Tabs
          type="card"
          items={labels.map((item: any, i: any) => {
            return {
              label: item,
              key: i,
              children: getLayout(i),
            };
          })}
        />
      </div>
    </>
  );
};

export default ContentForm;

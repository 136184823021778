import { message } from "antd";
import axios, { AxiosResponse } from "axios";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const callApi = async (
  method: string,
  url: string,
  data?: any,
  config?: any
) => {
  try {
    const response: any = await callApiMethod(method, url, data, false, config);
    return response;
  } catch (err: any) {
    if (err?.status === 402) {
      const newToken = await refreshToken();
      if (newToken) {
        return await callApiMethod(method, url, data, true, config);
      }
    }
  }
};

const callApiMethod = async (
  method: string,
  url: string,
  data: any,
  recall: boolean,
  config?: any
) => {
  let response: any;
  if (method === "GET") {
    response = await axiosClient.get(url, config);
  }
  if (method === "POST") {
    response = await axiosClient.post(url, data, config);
  }
  if (method === "PUT") {
    response = await axiosClient.put(url, data, config);
  }
  if (method === "DELETE" && data) {
    response = await axiosClient.delete(url, data);
  }
  if (method === "DELETE" && !data) {
    response = await axiosClient.delete(url);
  }
  if (recall && response.status === 401) {
    sessionStorage.clear();
    window.location.href = "/";
  }
  return response;
};

const refreshToken = async () => {
  try {
    const response = await axiosClient.post("auth/refresh-token", {
      token: sessionStorage.getItem("token"),
    });
    if (response?.data) {
      if (response?.data.statusCode && response?.data.statusCode == 401) {
        sessionStorage.clear();
        window.location.href = "/";
        return false;
      }
      sessionStorage.setItem("token", response?.data);
      return true;
    }
  } catch (err: any) {
    if (err?.status === 429) {
      return true;
    }
    if (err?.status === 401) {
      sessionStorage.clear();
      window.location.href = "/";
      return false;
    }
  }
};

axiosClient.interceptors.request.use(function (config: any) {
  if (config?.lang)
    config.headers = {
      tokenadmin: sessionStorage.getItem("token"),
      lang: config.lang,
    };
  else
    config.headers = {
      tokenadmin: sessionStorage.getItem("token"),
    };

  return config;
});

axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error) {
    switch (error.response.status) {
      case 422:
        return error?.response;
      case 500:
        return error?.response;
      case 504:
        message.error("Request timeout");
        throw error?.response;
      case 401:
        message.error(error?.response?.data?.message ?? "Hết phiên đăng nhập");
        return error?.response;
      case 404:
        window.location.href = "/404";
        return error?.response;
      default:
        break;
    }
    throw error?.response;
  }
);

export default axiosClient;

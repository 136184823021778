import { callApi } from "./axiosClient";

const auth = "auth";

const authApi = {
  async login(data: any) {
    const url = `${auth}/login`;
    return callApi("POST", url, data);
  },
  async login2F(data: any) {
    const url = `${auth}/login/verify2Fa`;
    return callApi("POST", url, data);
  },
  async getInfo2F() {
    const url = `user/twofa/register`;
    return callApi("GET", url);
  },
  async register2F(data: string) {
    const url = `user/twofa/verify`;
    return callApi("POST", url, {token2fa: data});
  },
  async checkRegister2F() {
    const url = `user/twofa/check`;
    return callApi("GET", url);
  },
  async disable2F() {
    const url = `user/twofa/disable`;
    return callApi("GET", url);
  },
  async getProfile() {
    const url = 'user/info';
    return callApi("GET", url);
  },
  async updateProfile(data: any) {
    const url = 'user/info';
    return callApi("PUT", url, data);
  },
};
export default authApi;

import { configs } from "../config";
import { callApi } from "./axiosClient";

const telegram = "tin-hieu-telegram";
const channel = "kenh";
const cate = "danh-muc";

const telegramApi = {
  async getCate() {
    const url = `${telegram}/${cate}`;
    return callApi("GET", url);
  },
  async getCateDetails(id: string | undefined) {
    const url = `${telegram}/${cate}/${id}`;
    return callApi("GET", url);
  },
  async addCate(data: any) {
    const url = `${telegram}/${cate}`;
    return callApi("POST", url, data);
  },
  async editCate({ id, data }: { id: string | undefined; data: any }) {
    const url = `${telegram}/${cate}/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteCate(id: string) {
    const url = `${telegram}/${cate}/${id}`;
    return callApi("DELETE", url);
  },
  async getChannel() {
    const url = `${telegram}/${channel}`;
    return callApi("GET", url);
  },
  async getChannelDetails(id: string | undefined) {
    const url = `${telegram}/${channel}/${id}`;
    return callApi("GET", url);
  },
  async addChannel(data: any) {
    const url = `${telegram}/${channel}`;
    return callApi("POST", url, data);
  },
  async editChannel({ id, data }: { id: string | undefined; data: any }) {
    const url = `${telegram}/${channel}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteChannel(id: string) {
    const url = `${telegram}/${channel}/${id}`;
    return callApi("DELETE", url);
  },
};

export default telegramApi;

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import openAccountApi from "../../api/openAccountApi";
import DateTimePicker from "../../components/DateTimePicker";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import TableWrap from "../../components/Table";
import TopContent from "../../components/TopContent";
import PageWrap from "../../layout/PageWrap";
import { appRoutes } from "../../routes/routes";
import { stts } from "../../utils/constants";
import { messages } from "../../utils/messages";

const OpenAccount = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [email, setEmail] = useState<string | undefined>();
  const [hoten, setHoten] = useState<string | undefined>();
  const [dienthoai, setDienthoai] = useState<string | undefined>();
  const [ngaytao, setNgaytao] = useState<string | undefined>();
  const [trangthai, setTrangthai] = useState<number | undefined>();

  const { handleSubmit, control, setValue } = useForm();

  const { data, isLoading, isFetching }: any = useQuery(
    [
      "open_account",
      currentPage,
      limit,
      email,
      hoten,
      dienthoai,
      ngaytao,
      trangthai,
    ],
    () =>
      openAccountApi.getAll({
        page: currentPage,
        page_size: limit,
        email,
        hoten,
        dienthoai,
        ngaytao,
        trangthai,
      })
  );
  const mutation = useMutation(openAccountApi.delete, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("open_account");
        queryClient.invalidateQueries("noti");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "#",
      width: "3%",
      dataIndex: "index",
      align: "center",
      key: "index",
    },
    {
      title: "Name",
      dataIndex: "ten",
      key: "ten",
    },
    {
      title: "Phone",
      dataIndex: "dienthoai",
      key: "dienthoai",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Tiêu đề",
      dataIndex: "tieude",
      key: "tieude",
    },
    {
      title: "Date",
      dataIndex: "ngaytao",
      key: "ngaytao",
      render: (record: string) => {
        if (record?.length === 0) return <></>;
        else return <>{moment(record).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "trangthai",
      key: "trangthai",
      align: "center",
    },
    {
      title: "Detail",
      key: "detail",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <a
          onClick={() =>
            navigate(appRoutes.OPEN_ACCOUNT.LIST + `/${record?._id}`)
          }
        >
          Xem
        </a>
      ),
    },
    {
      title: "Delete",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const onFilter = (dt: any) => {
    setEmail(dt.email);
    setDienthoai(dt.dienthoai);
    setHoten(dt.hoten);
    setNgaytao(dt.ngaytao ? dt.ngaytao.format("DD/MM/YYYY") : undefined);
    setTrangthai(dt.trangthai);
    setCurrentPage(1);
  };

  return (
    <PageWrap
      title="Đăng ký mở tài khoản MT5"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Đăng ký mở tài khoản MT5</b>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <>
              <Form className="register_common__form">
                <Row
                  style={{ marginBottom: "30px" }}
                  align="bottom"
                  gutter={[20, 10]}
                >
                  <Col md={20} sm={24}>
                    <Row gutter={[20, 10]} align="bottom">
                      <Col md={4} sm={24}>
                        <InputField
                          label="Email"
                          placeholder="Email khách hàng"
                          name="email"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24}>
                        <InputField
                          label="Tên"
                          placeholder="Tên khách hàng"
                          name="hoten"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24}>
                        <InputField
                          label="Điện thoại"
                          placeholder="Điện thoại"
                          name="dienthoai"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24}>
                        <Controller
                          name="ngaytao"
                          control={control}
                          render={({ field }) => (
                            <DateTimePicker
                              defaultValue={undefined}
                              props={{
                                ...field,
                                onBlur: (e: any) => {
                                  if (e.target.value === "")
                                    setValue("ngaytao", undefined);
                                  else if (
                                    moment(
                                      e.target.value,
                                      "DD/MM/YYYY",
                                      true
                                    )?.isValid()
                                  )
                                    setValue(
                                      "ngaytao",
                                      moment(e.target.value, "DD/MM/YYYY")
                                    );
                                  else setValue("ngaytao", moment());
                                },
                              }}
                              label="Ngày đặt"
                              allowClear={true}
                              action={handleSubmit(onFilter)}
                            />
                          )}
                        />
                      </Col>
                      <Col md={4} sm={24}>
                        <SelectField
                          label="Trạng thái đơn hàng"
                          placeholder="Trạng thái đơn hàng"
                          options={stts}
                          control={control}
                          name="trangthai"
                          allowClear={true}
                          listHeight={128}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} sm={24}>
                    <Row gutter={[20, 10]} justify="end">
                      <Col>
                        <Button
                          htmlType="submit"
                          className="primary_btn"
                          onClick={handleSubmit(onFilter)}
                        >
                          Lọc
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TableWrap
                loading={isLoading || isFetching}
                columns={columns}
                isShowTotal
                dataSource={data?.data?.source}
                rowKey="_id"
                bordered
                pagination={{
                  pageSize: limit,
                  current: currentPage,
                  total: data?.data?.totalItems,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page);
                    setLimit(pageSize);
                  },
                }}
                className="hover table-slide"
                hasDefaultColumn={false}
              />
            </>
          </Card>
        </div>
      }
    />
  );
};

export default OpenAccount;

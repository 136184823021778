import { callApi } from "./axiosClient";

const tuvan = "danh-muc/tu-van";

const tuvanApi = {
  async getAll(
    page: number,
    page_size: number,
    status?: number,
    chuyengia?: number
  ) {
    const url = `${tuvan}?page=${page}&page_size=${page_size}${
      status ? "&status=" + status : ""
    }${chuyengia ? "&chuyengia=" + chuyengia : ""}`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined) {
    const url = `${tuvan}/${id}`;
    return callApi("GET", url);
  },
  async edit({ id, data }: { id: string | undefined; data: any }) {
    const url = `${tuvan}/${id}`;
    return callApi("POST", url, data);
  },

  async saveTemplate(data: String) {
    const url = `${tuvan}/save`;
    return callApi("POST", url, { content: data });
  },
  async getTemplate() {
    const url = `${tuvan}/save`;
    return callApi("GET", url);
  },

  async getListStatus() {
    const url = `${tuvan}/status`;
    return callApi("GET", url);
  },

  async getListChuyenggia() {
    const url = `system/chuyengia`;
    return callApi("GET", url);
  },
};

export default tuvanApi;

import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import sliderApi from "../../../../api/sliderApi";
import SliderList from "../../../../components/SliderList";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const SliderGuide = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(sliderApi.deleteGuideSlider, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("guide-slider");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Slider hướng dẫn"
      content={
        <SliderList
          headContent="Slider hướng dẫn"
          handleRemove={(e) => mutation.mutate(e)}
          keyQuery="guide-slider"
          link="/slider/huong-dan"
          query={sliderApi.getGuideSlider}
          visible={false}
          column={[
            {
              title: "Link",
              dataIndex: "url",
              key: "url",
              width: "30%",
              render: (record: string) => {
                return (
                  <a target="_blank" href={record} rel="noreferrer">
                    {record}
                  </a>
                );
              },
            },
            {
              title: "Hình ảnh",
              dataIndex: "photo",
              key: "photo",
              width: "45%",
              className: "td-slider-picture",
              render: (record: string) => {
                return (
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${record}`}
                    alt=""
                    className="slider-picture"
                  ></img>
                );
              },
            },
          ]}
        />
      }
    />
  );
};

export default SliderGuide;

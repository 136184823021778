import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import contentApi from "../../../../api/contentApi";
import CheckboxSlider from "../../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { labels } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

const GuideList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [idx, setIdx] = useState(0);

  const { data, isLoading, isFetching }: any = useQuery(
    ["content_guide", idx === 0 ? "vi" : "en"],
    () => contentApi.getGuides(idx === 1)
  );

  const mutation = useMutation(contentApi.deleteGuide, {
    onSuccess: async(data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        await queryClient.invalidateQueries(["content_guide"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const mutationEdit = useMutation(contentApi.editGuide, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("content_guide");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      if (err?.status === 422)
        message.error(err?.data?.message ?? err?.data?.message?.error ?? "Lỗi");
      else message.error(messages.error);
    },
  });

  const handleRemove = (e: any) => {
    mutation.mutate({ id: e, en: idx === 1 });
  };
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: 50,
      dataIndex: "stt",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      width: "10%",
      render: (record: string) => {
        return (
          <div style={{ textAlign: "center" }}>
            <img src={`${process.env.REACT_APP_DOMAIN}${record}`} alt=""></img>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "ten_vi",
      width: "10%",
    },
    {
      title: "Content",
      dataIndex: "mota_vi",
      width: "10%",
    },
    // {
    //   title: "Hình ảnh",
    //   dataIndex: "photo",
    //   width: "15%",
    //   className: "td-slider-picture",
    //   render: (record: string) => {
    //     return (
    //       <img
    //         src={`${process.env.REACT_APP_DOMAIN}${record}`}
    //         alt=""
    //         className="slider-picture"
    //       ></img>
    //     );
    //   },
    // },
    {
      title: "Image/Clip",
      width: "10%",
      align: "center",
      key: "Image/Clip",
      render: (record: any) => {
        if (record?.photo !== "")
          return (
            <img
              src={`${process.env.REACT_APP_DOMAIN}${record?.photo}`}
              alt=""
              className="slider-picture"
            ></img>
          );
        else if (record.youtube !== "")
          return (
            <iframe
              title={record.youtube}
              className="embed-responsive-item"
              src={`https://www.youtube.com/embed/${
                record?.youtube?.split("?v=").reverse()?.[0]
              }?autoplay=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        return <></>;
      },
    },
    {
      title: "Mô tả",
      dataIndex: "mota_vi",
      width: "40%",
      key: "mota_vi",
    },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      key: "hienthi",
      align: "center",
      width: 90,
      render: (record: boolean, data: any) => (
        <CheckboxSlider
          _id={data._id}
          check={record}
          formName="hienthi"
          action={mutationEdit}
          en={idx === 1}
        />
      ),
    },
    {
      title: "Sửa",
      align: "center",
      width: 90,
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.CONTENT.GUIDE.LIST}/${record?._id}`, {
                state: { en: idx === 1 },
              })
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: 90,
      render: (record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={() => handleRemove(record._id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <PageWrap
      title="Hướng dẫn"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Hướng dẫn</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(`${appRoutes.CONTENT.GUIDE.ADD}`, {
                        state: {
                          en: idx === 1,
                        },
                      })
                    }
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <Tabs
              type="card"
              onChange={(e: any) => {
                setIdx(e);
              }}
              items={labels.map((item: any, i: any) => {
                return {
                  label: item,
                  key: i,
                  children: (
                    <TableWrap
                      loading={isLoading || isFetching}
                      columns={columns}
                      isShowTotal
                      dataSource={data?.data}
                      rowKey="_id"
                      bordered
                      pagination={false}
                      className="hover table-slide"
                      hasDefaultColumn={false}
                    />
                  ),
                };
              })}
            />
          </div>
        </>
      }
    />
  );
};

export default GuideList;

import { Button, Card, Form } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Ckeditor from "../../../../components/Ckeditor";
import InputField from "../../../../components/InputField";
import Loading from "../../../../components/Loading";
import { formItemLayoutSm } from "../../../../utils/constants";

interface AboutFormProps {
  loading: boolean;
  title: string | undefined;
  noidung: string | undefined;
  onSubmit: (e: any) => void;
}

const AboutForm: FC<AboutFormProps> = ({
  loading,
  title,
  noidung,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card bordered={false}>
          <Form {...formItemLayoutSm} colon={false}>
            <InputField
              label="Tiêu đề"
              name="title"
              control={control}
              defaultValue={title}
              errorMsg={errors?.title?.message}
              rules={{
                required: "Vui lòng nhập tiêu đề",
              }}
            />
            <Form.Item label="Nội dung chính">
              <Ckeditor
                initData={noidung}
                onChange={(evt: any) =>
                  setValue("noidung_vi", evt.editor.getData())
                }
              />
            </Form.Item>
            <Button type="primary" danger onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Form>
        </Card>
      )}
    </>
  );
};

export default AboutForm;

import { Button, Card, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import telegramApi from "../../../../api/telegramApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface CateFormProps {
  type: number;
}
const CateForm: FC<CateFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isLoading }: any = useQuery(
    ["telegram-cate", id],
    () => telegramApi.getCateDetails(id),
    {
      enabled: !!id && type === 2,
    }
  );

  const mutationEdit = useMutation(telegramApi.editCate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("telegram-cate");
        navigate(appRoutes.TELEGRAM.CATEGORIES.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(telegramApi.addCate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("telegram-cate");
        navigate(appRoutes.TELEGRAM.CATEGORIES.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };

  return (
    <PageWrap
      title="Danh mục"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  <InputField
                    label="Tên"
                    name="ten_vi"
                    control={control}
                    defaultValue={data?.data?.ten_vi}
                    errorMsg={errors?.ten_vi?.message}
                    rules={{
                      required: "Vui lòng nhập tên",
                    }}
                  />
                  <InputField
                    label="Tiêu đề"
                    name="mota_vi"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.mota_vi}
                    errorMsg={errors?.mota_vi?.message}
                    rules={{
                      required: "Vui lòng nhập tiêu đề",
                    }}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="index"
                    control={control}
                    defaultValue={data?.data?.index ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("index", 1);
                    }}
                    min={1}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.TELEGRAM.CATEGORIES.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default CateForm;

import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import contentApi from "../../../../api/contentApi";
import ListContent from "../../../../components/ListContent";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const TradingBlogList = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(contentApi.deleteBlog, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("content_blog");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  return (
    <PageWrap
      title="Trading Blog"
      content={
        <ListContent
          headContent="Trading Blog"
          handleRemove={(e) => mutation.mutate(e)}
          action={useMutation(contentApi.editBlog, {
            onSuccess: (data: any) => {
              if (data.statusCode === 200) {
                message.success(messages.updateSuccess);
                queryClient.invalidateQueries("content_blog");
              }
            },
            onError: (err: any) => {
              message.error(messages.error);
            },
          })}
          keyQuery="content_blog"
          link="/noi-dung-bai-viet/trading-blog"
          query={contentApi.getBlogs}
        />
      }
    />
  );
};

export default TradingBlogList;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CheckboxSlider from "../SliderList/CheckboxSlider";
import TableWrap from "../Table";
import TopContent from "../TopContent";
import { Duplicate } from "../../assets/icon/duplicate";
interface ListContentProps {
  link: string;
  keyQuery: string;
  query: any;
  handleRemove: (id: any) => void;
  action?: any;
  headContent: string;
}

const ListContent: FC<ListContentProps> = ({
  link,
  keyQuery,
  query,
  headContent,
  handleRemove,
  action,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate();
  const { data, isLoading, isFetching }: any = useQuery(
    [`${keyQuery}`, currentPage, limit],
    () => query(currentPage, limit)
  );

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "3%",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Tên",
      width: "27%",
      dataIndex: "ten_vi",
      key: "ten_vi",
      render: (record: string, data: any) => (
        <a href={`${link}/${data?._id}`}>{record}</a>
      ),
    },
    {
      title: "Hình ảnh",
      dataIndex: "photo",
      width: "20%",
      key: "photo",
      className: "td-slider-picture",
      render: (record: string) => {
        return (
          <img
            src={`${process.env.REACT_APP_DOMAIN}${record}`}
            alt=""
            className="slider-picture"
          ></img>
        );
      },
    },
    {
      title: "Xem",
      align: "center",
      width: "5%",
      key: "views",
      render: (record: boolean, data: any) => {
        let time = "00:00";
        if (data.timeRead && data.timeRead > 0) {
          const hours = Math.floor(data.timeRead / 3600);
          const minutes = Math.floor((data.timeRead % 3600) / 60);
          const seconds = data.timeRead % 60;

          if(hours > 0) {
            time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
          } else {
            time = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
          }
        }
        return (
          <div style={{ whiteSpace: "pre", textAlign: "left" }}>
            {`Lượt xem: ${data.views ?? 0}\nThời gian xem: ${time}`}
          </div>
        );
      },
    },
    {
      title: "Premium",
      dataIndex: "premium",
      align: "center",
      width: "3%",
      key: "premium",
      render: (record: boolean, data: any) => {
        return (
          <CheckboxSlider
            _id={data?._id}
            check={record}
            action={action}
            dataName="premium"
          />
        );
      },
    },
    // {
    //   title: "Nổi bật",
    //   dataIndex: "noibat",
    //   align: "center",
    //   width: "3%",
    //   key: "noibat",
    //   render: (record: boolean, data: any) => {
    //     return (
    //       <CheckboxSlider
    //         _id={data?._id}
    //         check={record}
    //         action={action}
    //         dataName="noibat"
    //       />
    //     );
    //   },
    // },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      align: "center",
      width: "3%",
      key: "hienthi",
      render: (record: boolean, data: any) => {
        return (
          <CheckboxSlider
            _id={data?._id}
            check={record}
            action={action}
            dataName="hienthi"
          />
        );
      },
    },
    {
      title: "Duplicate",
      align: "center",
      width: "3%",
      render: (_, record: any) => {
        return (
          <div
            className="copy_wrap"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(`${link}/add`, {
                state: { id: record._id },
              });
            }}
          >
            <Duplicate />
          </div>
        );
      },
    },
    {
      title: "Sửa",
      align: "center",
      width: "3%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(`${link}/${record?._id}`);
            }}
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "3%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              handleRemove(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <div className="slider_list">
      <TopContent
        content={
          <Row>
            <Col>
              <b>{headContent}</b>
            </Col>
            <Col>
              <Button onClick={() => navigate(`${link}/add`)}>
                <PlusOutlined /> Add
              </Button>
            </Col>
          </Row>
        }
      />
      <div className="content">
        <TableWrap
          loading={isLoading || isFetching}
          columns={columns}
          isShowTotal
          dataSource={data?.data?.source}
          rowKey="_id"
          bordered
          pagination={{
            pageSize: limit,
            current: currentPage,
            total: data?.data?.totalItems,
            onChange: (page: number, pageSize: number) => {
              window.scrollTo(0, 0);
              setCurrentPage(page);
              setLimit(pageSize);
            },
          }}
          className="hover table-slide"
          hasDefaultColumn={false}
        />
      </div>
    </div>
  );
};

export default ListContent;

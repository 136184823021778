import { callApi } from "./axiosClient";

const register = "dang-ky";
const train = "dao-tao";

const registerApi = {
  async getAll(
    page: number,
    page_size: number,
    email?: string,
    ngaytao?: string,
    name?: string,
    phone?: string,
    status?: number
  ) {
    const url = `${train}/${register}?page=${page}&page_size=${page_size}${
      email ? "&email=" + email : ""
    }${ngaytao ? "&ngaytao=" + ngaytao : ""}${name ? "&ten=" + name : ""}${
      phone ? "&dienthoai=" + phone : ""
    }${status ? "&trangthai=" + status : ""}`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined) {
    const url = `${train}/${register}/${id}`;
    return callApi("GET", url);
  },
  async updateStatus({
    id,
    status,
  }: {
    id: string | undefined;
    status: number | undefined;
  }) {
    const url = `${train}/${register}/${id}`;
    return callApi("POST", url, {
      trangthai: status,
    });
  },
  async delete(id: string) {
    const url = `${train}/${register}/${id}`;
    return callApi("DELETE", url);
  },
};

export default registerApi;

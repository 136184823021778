import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import moment from "moment";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import couponApi from "../../../api/couponApi";
import InputField from "../../../components/InputField";
import InputNumberWrap from "../../../components/InputNumber";
import Loading from "../../../components/Loading";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import { regex } from "../../../utils/regex";
import "./style.scss";
interface CouponFormProps {
  type: number;
}
const CouponForm: FC<CouponFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["coupon", id],
    () => couponApi.getDetails(id),
    {
      enabled: type === 2,
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(couponApi.edit, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("coupon");
        navigate(appRoutes.COUPON.LIST);
        window.scrollTo(0, 0);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(couponApi.add, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("coupon");
        navigate(appRoutes.COUPON.LIST);
        window.scrollTo(0, 0);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };

  return (
    <PageWrap
      title="Mã giảm giá"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Mã giảm giá</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  <InputField
                    label="Code"
                    name="code"
                    control={control}
                    defaultValue={data?.data?.code}
                    errorMsg={errors?.code?.message}
                    rules={{
                      required: "Vui lòng nhập code",
                    }}
                    suffix={
                      <Button
                        type="primary"
                        onClick={() => {
                          const uuid = Math.random()
                            .toString(36)
                            .slice(-6)
                            .toUpperCase();
                          setValue("code", uuid);
                        }}
                      >
                        Tạo mã tự động
                      </Button>
                    }
                  />
                  <InputField
                    label="% giảm"
                    name="discount"
                    suffix={"%"}
                    type="number"
                    min={0}
                    className="number_input"
                    control={control}
                    defaultValue={data?.data?.discount ?? 0}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("discount", 0);
                    }}
                  />
                  <InputNumberWrap
                    label="Số lần dùng"
                    name="number"
                    control={control}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("number", 0);
                    }}
                    defaultValue={data?.data?.number ?? 0}
                    min={0}
                  />
                  {type === 1 ? (
                    <InputField
                      label="Ngày bắt đầu"
                      name="ngaytao"
                      placeholder="Từ bây giờ"
                      control={control}
                      defaultValue={
                        data?.data?.ngaytao
                          ? moment(data?.data?.ngaytao).format("DD/MM/YYYY")
                          : undefined
                      }
                    />
                  ) : (
                    <InputField
                      label="Ngày bắt đầu"
                      name="ngaytao"
                      placeholder="Từ bây giờ"
                      control={control}
                      defaultValue={
                        data?.data?.ngaytao
                          ? moment(data?.data?.ngaytao).format("DD/MM/YYYY")
                          : undefined
                      }
                      rules={{
                        required: "Vui lòng nhập ngày bắt đầu",
                        validate: (value: string) => {
                          if (value?.trim() === "") {
                            return "Vui lòng nhập ngày bắt đầu";
                          }
                          if (
                            regex.validateDate.test(value?.trim()) === false
                          ) {
                            return "Ngày bắt đầu không hợp lệ";
                          }
                        },
                      }}
                      errorMsg={errors?.ngaytao?.message}
                    />
                  )}
                  <InputField
                    label="Ngày hết hạn"
                    name="expire"
                    placeholder="Vô thời hạn"
                    control={control}
                    defaultValue={
                      data?.data?.expire
                        ? moment(data?.data?.expire).format("DD/MM/YYYY")
                        : undefined
                    }
                  />
                  <Form.Item className="checkbox slider_checkbox">
                    <Row>
                      <Col>
                        <Checkbox
                          defaultChecked={data?.data?.active}
                          name="active"
                          onClick={(e: any) => {
                            setValue("active", e.target.checked);
                          }}
                        />
                        &nbsp;Active
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(appRoutes.COUPON.LIST)}>
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default CouponForm;

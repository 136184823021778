import { callApi } from "./axiosClient";

const openAccount = "mt5/dang-ky";

const openAccountApi = {
  async getAll({
    page,
    page_size,
    email,
    hoten,
    dienthoai,
    ngaytao,
    trangthai,
  }: {
    page: number;
    page_size: number;
    email: string | undefined;
    hoten: string | undefined;
    dienthoai: string | undefined;
    ngaytao: string | undefined;
    trangthai: number | undefined;
  }) {
    const url = `${openAccount}?page=${page}&page_size=${page_size}${
      email ? `&email=${email}` : ""
    }${hoten ? `&ten=${hoten}` : ""}${
      dienthoai ? `&dienthoai=${dienthoai}` : ""
    }${ngaytao ? `&ngaytao=${ngaytao}` : ""}${
      trangthai ? `&trangthai=${trangthai}` : ""
    }`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined) {
    const url = `${openAccount}/${id}`;
    return callApi("GET", url);
  },
  async edit({ id, stt }: { id: string | undefined; stt: number | undefined }) {
    const url = `${openAccount}/${id}`;
    return callApi("PUT", url, { trangthai: stt });
  },
  async delete(id: string) {
    const url = `${openAccount}/${id}`;
    return callApi("DELETE", url);
  },
};

export default openAccountApi;

import { Menu, MenuProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReportIcon } from "../../assets/icon";
import {
  SettingOutlined,
  MailOutlined,
  PictureOutlined,
  DollarOutlined,
  BarsOutlined,
  ReadOutlined,
  UploadOutlined,
  CalendarOutlined,
  ExceptionOutlined,
  ProfileOutlined,
  FormOutlined,
  ContainerOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  InfoOutlined,
  ControlOutlined,
  CalculatorOutlined,
  QrcodeOutlined,
  ContactsOutlined,
  BellOutlined,
  AntDesignOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { appRoutes } from "../../routes/routes";
import { useQuery } from "react-query";
import systemApi from "../../api/systemApi";

interface NavigationProps {}

const Navigation: FC<NavigationProps> = () => {
  const [data, setData]: any = useState();
  const noti = useQuery("noti", systemApi.getNoti);
  type MenuItem = Required<MenuProps>["items"][number];
  // const [openKeys, setOpenKeys] = useState<string[]>([]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }
  useEffect(() => {
    let cache: any = sessionStorage.getItem("profile");
    if (cache) setData(JSON.parse(cache));
  }, [sessionStorage?.getItem("profile")]);

  const menuRole4: MenuItem[] = [
    getItem(
      <div className="flex-wrap">
        Tư vấn danh mục
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "v3_tuvan_danhmuc")
              ?.number
          }
        </div>
      </div>,
      appRoutes.HOME.ROOT,
      <Link to={appRoutes.HOME.ROOT}>
        <CalculatorOutlined />
      </Link>
    ),
  ];
  const menuRole3: MenuItem[] = [
    getItem(
      "Tổng quan",
      appRoutes.HOME.ROOT,
      <Link to={appRoutes.HOME.ROOT}>
        <ReportIcon />
      </Link>
    ),
    getItem("Thông báo", "Thông báo", <BellOutlined />, [
      getItem(
        <Link to={appRoutes.NOTIFICATION.GENERAL.LIST}>
          <BarsOutlined />
          &nbsp;&nbsp;&nbsp;Hệ thống
        </Link>,
        appRoutes.NOTIFICATION.GENERAL.LIST
      ),
      getItem(
        <Link to={appRoutes.NOTIFICATION.SIGN.LIST}>
          <AntDesignOutlined />
          &nbsp;&nbsp;&nbsp;Tín hiệu
        </Link>,
        appRoutes.NOTIFICATION.SIGN.LIST
      ),
    ]),
    getItem(
      <div className="flex-wrap">
        Quản lý đơn hàng
        <div className="badge">
          {noti?.data?.data?.find((e: any) => e._id === "don_hang")?.number}
        </div>
      </div>,
      appRoutes.ORDER.ROOT,
      <Link to={appRoutes.ORDER.ROOT}>
        <CalculatorOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Đơn hàng Global
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "don_hang_global")
              ?.number
          }
        </div>
      </div>,
      appRoutes.ORDER_GLOBAL.ROOT,
      <Link to={appRoutes.ORDER_GLOBAL.ROOT}>
        <CalculatorOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Tư vấn danh mục
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "v3_tuvan_danhmuc")
              ?.number
          }
        </div>
      </div>,
      appRoutes.TU_VAN_DANH_MUC.ROOT,
      <Link to={appRoutes.TU_VAN_DANH_MUC.ROOT}>
        <CalculatorOutlined />
      </Link>
    ),
    getItem(
      "Mã giảm giá",
      appRoutes.COUPON.LIST,
      <Link to={appRoutes.COUPON.LIST}>
        <QrcodeOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Thư liên hệ
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "contact_lienhe")
              ?.number
          }
        </div>
      </div>,
      appRoutes.MAIL.ROOT,
      <Link to={appRoutes.MAIL.ROOT}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Nhận tín hiệu
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "contact_tinhieu")
              ?.number
          }
        </div>
      </div>,
      appRoutes.GET_SIGN.ROOT,
      <Link to={appRoutes.GET_SIGN.ROOT}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Mở tài khoản MT5
        <div className="badge">
          {noti?.data?.data?.find((e: any) => e._id === "mt5")?.number}
        </div>
      </div>,
      appRoutes.OPEN_ACCOUNT.LIST,
      <Link to={appRoutes.OPEN_ACCOUNT.LIST}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Đăng ký đào tạo
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "contact_daotao")
              ?.number
          }
        </div>
      </div>,
      appRoutes.REGISTER.ROOT,
      <Link to={appRoutes.REGISTER.ROOT}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      "Nội dung & Bài viết",
      "Nội dung & Bài viết",
      <ContainerOutlined />,
      [
        getItem(
          <Link to={appRoutes.CONTENT.ABOUT.ROOT}>
            <UserOutlined />
            &nbsp;&nbsp;&nbsp;Về chúng tôi
          </Link>,
          appRoutes.CONTENT.ABOUT.ROOT
        ),
        getItem(
          <Link to={appRoutes.CONTENT.INVESTMENT.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Cơ hội đầu tư
          </Link>,
          appRoutes.CONTENT.INVESTMENT.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.SIGN.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Tín hiệu
          </Link>,
          appRoutes.CONTENT.SIGN.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.TRADING_BLOG.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Trading Blog
          </Link>,
          appRoutes.CONTENT.TRADING_BLOG.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.TRADING_THUC_CHIEN_V3.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Trading thực chiến
          </Link>,
          appRoutes.CONTENT.TRADING_THUC_CHIEN_V3.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.REVIEW_BROKER_V3.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Review broker
          </Link>,
          appRoutes.CONTENT.REVIEW_BROKER_V3.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST}>
            <BarsOutlined />
            &nbsp;&nbsp;&nbsp;Danh mục bài viết
          </Link>,
          appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.QUESTIONS.LIST}>
            <QuestionCircleOutlined />
            &nbsp;&nbsp;&nbsp;Câu hỏi thường gặp
          </Link>,
          appRoutes.CONTENT.QUESTIONS.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.GUIDE.LIST}>
            <ControlOutlined />
            &nbsp;&nbsp;&nbsp;Hướng dẫn
          </Link>,
          appRoutes.CONTENT.GUIDE.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.TERM.ROOT}>
            <InfoOutlined />
            &nbsp;&nbsp;&nbsp;Điều khoản & Điều kiện
          </Link>,
          appRoutes.CONTENT.TERM.ROOT
        ),
      ]
    ),
    getItem("Thư viện chỉ báo", "Thư viện chỉ báo", <ReadOutlined />, [
      getItem(
        <Link to={appRoutes.LIBRARY.CATEGORIES.LIST}>
          <BarsOutlined />
          &nbsp;&nbsp;&nbsp;Danh mục
        </Link>,
        appRoutes.LIBRARY.CATEGORIES.LIST
      ),
      getItem(
        <Link to={appRoutes.LIBRARY.INDICATOR.LIST}>
          <ExceptionOutlined />
          &nbsp;&nbsp;&nbsp;Các chỉ báo
        </Link>,
        appRoutes.LIBRARY.INDICATOR.LIST
      ),
    ]),
    getItem("Tạo lập danh mục", "Tạo lập danh mục", <UploadOutlined />, [
      getItem("Tin quan trọng", "Tin quan trọng", <ProfileOutlined />, [
        getItem(
          <Link to={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST}>
            <BarsOutlined />
            &nbsp;&nbsp;&nbsp;Danh mục
          </Link>,
          appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST
        ),
        getItem(
          <Link to={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST}>
            <FormOutlined />
            &nbsp;&nbsp;&nbsp;Tin tức
          </Link>,
          appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST
        ),
      ]),
      getItem(
        <Link to={appRoutes.CREATE_CATEGORY.EVENTS.LIST}>
          <CalendarOutlined />
          &nbsp;&nbsp;&nbsp;Lịch sự kiện
        </Link>,
        appRoutes.CREATE_CATEGORY.EVENTS.LIST
      ),
      getItem(
        <Link to={appRoutes.CREATE_CATEGORY.FUND.ROOT}>
          <BarsOutlined />
          &nbsp;&nbsp;&nbsp;Quản lý quỹ chủ động
        </Link>,
        appRoutes.CREATE_CATEGORY.FUND.ROOT
      ),
    ]),
    getItem("Tín hiệu Telegram", "Tín hiệu Telegram", <PictureOutlined />, [
      getItem(
        <Link to={appRoutes.TELEGRAM.CATEGORIES.LIST}>Danh mục</Link>,
        appRoutes.TELEGRAM.CATEGORIES.LIST
      ),
      getItem(
        <Link to={appRoutes.TELEGRAM.CHANNELS.LIST}>Kênh</Link>,
        appRoutes.TELEGRAM.CHANNELS.LIST
      ),
    ]),
    getItem("Đào tạo", "Đào tạo", <PictureOutlined />, [
      getItem(
        <Link to={appRoutes.TRAINING.CATEGORIES.LIST}>Danh mục</Link>,
        appRoutes.TRAINING.CATEGORIES.LIST
      ),
      getItem(
        <Link to={appRoutes.TRAINING.POSTS.LIST}>Bài viết</Link>,
        appRoutes.TRAINING.POSTS.LIST
      ),
      getItem(
        <Link to={appRoutes.TRAINING.GIANG_VIEN.LIST}>Giảng viên</Link>,
        appRoutes.TRAINING.GIANG_VIEN.LIST
      ),
      getItem(
        <Link to={appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST}>
          Đánh giá khóa học
        </Link>,
        appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST
      ),
    ]),
    getItem(
      "Xác định dòng tiền",
      appRoutes.CASH.ROOT,
      <Link to={appRoutes.CASH.ROOT}>
        <DollarOutlined />
      </Link>
    ),
    getItem(
      "Import",
      appRoutes.IMPORT.ROOT,
      <Link to={appRoutes.IMPORT.ROOT}>
        <ImportOutlined />
      </Link>
    ),
    getItem(
      "Tài khoản thành viên",
      appRoutes.ACCOUNT.ROOT,
      <Link to={appRoutes.ACCOUNT.ROOT}>
        <ContactsOutlined />
      </Link>
    ),
    getItem(
      "Hình ảnh, slideshow",
      appRoutes.SLIDERS.ROOT,
      <PictureOutlined />,
      [
        getItem(
          <Link to={appRoutes.SLIDERS.HOME.LIST}>Slidershow chính</Link>,
          appRoutes.SLIDERS.HOME.LIST
        ),
        getItem(
          <Link to={appRoutes.SLIDERS.CASH_FLOW.LIST}>Slider dòng tiền</Link>,
          appRoutes.SLIDERS.CASH_FLOW.LIST
        ),
        getItem(
          <Link to={appRoutes.SLIDERS.TOOL_DOWNLOAD.LIST}>
            Slidershow Tool Download
          </Link>,
          appRoutes.SLIDERS.TOOL_DOWNLOAD.LIST
        ),
        getItem(
          <Link to={appRoutes.SLIDERS.WHY_AI.LIST}>Slidershow Why AI</Link>,
          appRoutes.SLIDERS.WHY_AI.LIST
        ),
        getItem(
          <Link to={appRoutes.SLIDERS.GUEST.LIST}>Slider khách hàng</Link>,
          appRoutes.SLIDERS.GUEST.LIST
        ),
        getItem(
          <Link to={appRoutes.SLIDERS.GUIDE.LIST}>Slider hướng dẫn</Link>,
          appRoutes.SLIDERS.GUIDE.LIST
        ),
        getItem(
          <Link to={appRoutes.SLIDERS.FEEDBACK.LIST}>Khách hàng nói gì</Link>,
          appRoutes.SLIDERS.FEEDBACK.LIST
        ),
      ]
    ),
    getItem(
      <div className="flex-wrap">
        Danh sách emails
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "danh_sach_mail")
              ?.number
          }
        </div>
      </div>,
      appRoutes.MAIL_LIST.ROOT,
      <Link to={appRoutes.MAIL_LIST.ROOT}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      "Thiết lập website",
      appRoutes.SYSTEM_SETTING.ROOT,
      <Link to={appRoutes.SYSTEM_SETTING.ROOT}>
        <SettingOutlined />
      </Link>
    ),
  ];
  const menuRole2: MenuItem[] = [
    getItem(
      "Tổng quan",
      appRoutes.HOME.ROOT,
      <Link to={appRoutes.HOME.ROOT}>
        <ReportIcon />
      </Link>
    ),
    // getItem(
    //   <div className="flex-wrap">
    //     Thư liên hệ
    //     <div className="badge">
    //       {
    //         noti?.data?.data?.find((e: any) => e._id === "contact_lienhe")
    //           ?.number
    //       }
    //     </div>
    //   </div>,
    //   appRoutes.MAIL.ROOT,
    //   <Link to={appRoutes.MAIL.ROOT}>
    //     <MailOutlined />
    //   </Link>
    // ),
    // getItem(
    //   <div className="flex-wrap">
    //     Nhận tín hiệu
    //     <div className="badge">
    //       {
    //         noti?.data?.data?.find((e: any) => e._id === "contact_tinhieu")
    //           ?.number
    //       }
    //     </div>
    //   </div>,
    //   appRoutes.GET_SIGN.ROOT,
    //   <Link to={appRoutes.GET_SIGN.ROOT}>
    //     <MailOutlined />
    //   </Link>
    // ),
    // getItem(
    //   <div className="flex-wrap">
    //     Mở tài khoản MT5
    //     <div className="badge">
    //       {noti?.data?.data?.find((e: any) => e._id === "mt5")?.number}
    //     </div>
    //   </div>,
    //   appRoutes.OPEN_ACCOUNT.LIST,
    //   <Link to={appRoutes.OPEN_ACCOUNT.LIST}>
    //     <MailOutlined />
    //   </Link>
    // ),
    // getItem(
    //   <div className="flex-wrap">
    //     Đăng ký đào tạo
    //     <div className="badge">
    //       {
    //         noti?.data?.data?.find((e: any) => e._id === "contact_daotao")
    //           ?.number
    //       }
    //     </div>
    //   </div>,
    //   appRoutes.REGISTER.ROOT,
    //   <Link to={appRoutes.REGISTER.ROOT}>
    //     <MailOutlined />
    //   </Link>
    // ),
    getItem(
      "Nội dung & Bài viết",
      "Nội dung & Bài viết",
      <ContainerOutlined />,
      [
        getItem(
          <Link to={appRoutes.CONTENT.ABOUT.ROOT}>
            <UserOutlined />
            &nbsp;&nbsp;&nbsp;Về chúng tôi
          </Link>,
          appRoutes.CONTENT.ABOUT.ROOT
        ),
        getItem(
          <Link to={appRoutes.CONTENT.INVESTMENT.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Cơ hội đầu tư
          </Link>,
          appRoutes.CONTENT.INVESTMENT.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.SIGN.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Tín hiệu
          </Link>,
          appRoutes.CONTENT.SIGN.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.TRADING_BLOG.LIST}>
            <ExclamationCircleOutlined />
            &nbsp;&nbsp;&nbsp;Trading Blog
          </Link>,
          appRoutes.CONTENT.TRADING_BLOG.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.QUESTIONS.LIST}>
            <QuestionCircleOutlined />
            &nbsp;&nbsp;&nbsp;Câu hỏi thường gặp
          </Link>,
          appRoutes.CONTENT.QUESTIONS.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.GUIDE.LIST}>
            <ControlOutlined />
            &nbsp;&nbsp;&nbsp;Hướng dẫn
          </Link>,
          appRoutes.CONTENT.GUIDE.LIST
        ),
        getItem(
          <Link to={appRoutes.CONTENT.TERM.ROOT}>
            <InfoOutlined />
            &nbsp;&nbsp;&nbsp;Điều khoản & Điều kiện
          </Link>,
          appRoutes.CONTENT.TERM.ROOT
        ),
      ]
    ),
    getItem("Thư viện chỉ báo", "Thư viện chỉ báo", <ReadOutlined />, [
      getItem(
        <Link to={appRoutes.LIBRARY.CATEGORIES.LIST}>
          <BarsOutlined />
          &nbsp;&nbsp;&nbsp;Danh mục
        </Link>,
        appRoutes.LIBRARY.CATEGORIES.LIST
      ),
      getItem(
        <Link to={appRoutes.LIBRARY.INDICATOR.LIST}>
          <ExceptionOutlined />
          &nbsp;&nbsp;&nbsp;Các chỉ báo
        </Link>,
        appRoutes.LIBRARY.INDICATOR.LIST
      ),
    ]),
    getItem("Tạo lập danh mục", "Tạo lập danh mục", <UploadOutlined />, [
      getItem("Tin quan trọng", "Tin quan trọng", <ProfileOutlined />, [
        getItem(
          <Link to={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST}>
            <BarsOutlined />
            &nbsp;&nbsp;&nbsp;Danh mục
          </Link>,
          appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST
        ),
        getItem(
          <Link to={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST}>
            <FormOutlined />
            &nbsp;&nbsp;&nbsp;Tin tức
          </Link>,
          appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST
        ),
      ]),
      getItem(
        <Link to={appRoutes.CREATE_CATEGORY.EVENTS.LIST}>
          <CalendarOutlined />
          &nbsp;&nbsp;&nbsp;Lịch sự kiện
        </Link>,
        appRoutes.CREATE_CATEGORY.EVENTS.LIST
      ),
      getItem(
        <Link to={appRoutes.CREATE_CATEGORY.FUND.ROOT}>
          <BarsOutlined />
          &nbsp;&nbsp;&nbsp;Quản lý quỹ chủ động
        </Link>,
        appRoutes.CREATE_CATEGORY.FUND.ROOT
      ),
    ]),
    getItem("Tín hiệu Telegram", "Tín hiệu Telegram", <PictureOutlined />, [
      getItem(
        <Link to={appRoutes.TELEGRAM.CATEGORIES.LIST}>Danh mục</Link>,
        appRoutes.TELEGRAM.CATEGORIES.LIST
      ),
      getItem(
        <Link to={appRoutes.TELEGRAM.CHANNELS.LIST}>Kênh</Link>,
        appRoutes.TELEGRAM.CHANNELS.LIST
      ),
    ]),
    getItem("Đào tạo", "Đào tạo", <PictureOutlined />, [
      getItem(
        <Link to={appRoutes.TRAINING.CATEGORIES.LIST}>Danh mục</Link>,
        appRoutes.TRAINING.CATEGORIES.LIST
      ),
      getItem(
        <Link to={appRoutes.TRAINING.POSTS.LIST}>Bài viết</Link>,
        appRoutes.TRAINING.POSTS.LIST
      ),
      getItem(
        <Link to={appRoutes.TRAINING.GIANG_VIEN.LIST}>Giảng viên</Link>,
        appRoutes.TRAINING.GIANG_VIEN.LIST
      ),
      getItem(
        <Link to={appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST}>
          Đánh giá khóa học
        </Link>,
        appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST
      ),
    ]),
    // getItem(
    //   "Hình ảnh, slideshow",
    //   appRoutes.SLIDERS.ROOT,
    //   <PictureOutlined />,
    //   [
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.HOME.LIST}>Slidershow chính</Link>,
    //       appRoutes.SLIDERS.HOME.LIST
    //     ),
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.CASH_FLOW.LIST}>Slider dòng tiền</Link>,
    //       appRoutes.SLIDERS.CASH_FLOW.LIST
    //     ),
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.TOOL_DOWNLOAD.LIST}>
    //         <ExclamationCircleOutlined />
    //         &nbsp;&nbsp;&nbsp;Slidershow Tool Download
    //       </Link>,
    //       appRoutes.SLIDERS.TOOL_DOWNLOAD.LIST
    //     ),
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.WHY_AI.LIST}>
    //         <ExclamationCircleOutlined />
    //         &nbsp;&nbsp;&nbsp;Slidershow Why AI
    //       </Link>,
    //       appRoutes.SLIDERS.WHY_AI.LIST
    //     ),
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.GUEST.LIST}>Slider khách hàng</Link>,
    //       appRoutes.SLIDERS.GUEST.LIST
    //     ),
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.GUIDE.LIST}>Slider hướng dẫn</Link>,
    //       appRoutes.SLIDERS.GUIDE.LIST
    //     ),
    //     getItem(
    //       <Link to={appRoutes.SLIDERS.FEEDBACK.LIST}>Khách hàng nói gì</Link>,
    //       appRoutes.SLIDERS.FEEDBACK.LIST
    //     ),
    //   ]
    // ),
    getItem(
      <div className="flex-wrap">
        Danh sách emails
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "danh_sach_mail")
              ?.number
          }
        </div>
      </div>,
      appRoutes.MAIL_LIST.ROOT,
      <Link to={appRoutes.MAIL_LIST.ROOT}>
        <MailOutlined />
      </Link>
    ),
    // getItem(
    //   "Thiết lập website",
    //   appRoutes.SYSTEM_SETTING.ROOT,
    //   <Link to={appRoutes.SYSTEM_SETTING.ROOT}>
    //     <SettingOutlined />
    //   </Link>
    // ),
  ];

  const menuRole6: MenuItem[] = [
    getItem(
      "Tổng quan",
      appRoutes.HOME.ROOT,
      <Link to={appRoutes.HOME.ROOT}>
        <ReportIcon />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Quản lý đơn hàng
        <div className="badge">
          {noti?.data?.data?.find((e: any) => e._id === "don_hang")?.number}
        </div>
      </div>,
      appRoutes.ORDER.ROOT,
      <Link to={appRoutes.ORDER.ROOT}>
        <CalculatorOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Đơn hàng Global
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "don_hang_global")
              ?.number
          }
        </div>
      </div>,
      appRoutes.ORDER_GLOBAL.ROOT,
      <Link to={appRoutes.ORDER_GLOBAL.ROOT}>
        <CalculatorOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Thư liên hệ
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "contact_lienhe")
              ?.number
          }
        </div>
      </div>,
      appRoutes.MAIL.ROOT,
      <Link to={appRoutes.MAIL.ROOT}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Nhận tín hiệu
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "contact_tinhieu")
              ?.number
          }
        </div>
      </div>,
      appRoutes.GET_SIGN.ROOT,
      <Link to={appRoutes.GET_SIGN.ROOT}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Mở tài khoản MT5
        <div className="badge">
          {noti?.data?.data?.find((e: any) => e._id === "mt5")?.number}
        </div>
      </div>,
      appRoutes.OPEN_ACCOUNT.LIST,
      <Link to={appRoutes.OPEN_ACCOUNT.LIST}>
        <MailOutlined />
      </Link>
    ),
    getItem(
      <div className="flex-wrap">
        Đăng ký đào tạo
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "contact_daotao")
              ?.number
          }
        </div>
      </div>,
      appRoutes.REGISTER.ROOT,
      <Link to={appRoutes.REGISTER.ROOT}>
        <MailOutlined />
      </Link>
    ),

    getItem(
      <div className="flex-wrap">
        Danh sách emails
        <div className="badge">
          {
            noti?.data?.data?.find((e: any) => e._id === "danh_sach_mail")
              ?.number
          }
        </div>
      </div>,
      appRoutes.MAIL_LIST.ROOT,
      <Link to={appRoutes.MAIL_LIST.ROOT}>
        <MailOutlined />
      </Link>
    ),
  ];
  const location = useLocation();

  const getUIforRole = () => {
    if(data?.role === 2) {
      return menuRole2;
    }
    if(data?.role === 3) {
      return menuRole3;
    }
    if(data?.role === 6) {
      return menuRole6;
    }
    return menuRole4;
  }

  return (
    <Menu
      mode="inline"
      theme="dark"
      items={
        getUIforRole()
      }
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
    />
  );
};

export default Navigation;

import PageWrap from "../../../layout/PageWrap";
import { Col, Row } from "antd";
import "./style.scss";
import Service from "../Service";
import AboutExpire from "../aboutExpire";
import RegisterDaoTao from "../RegisterDaoTao";
import ListHocVien from "../ListHocVien";
import BaiVietMoi from "../BaiVietMoi";
import useWindowDimensions from "../../../hook/WindowDimensions";
const HomeMain = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <PageWrap
      title="Manage"
      content={
        <div className="orver_view_wrap">
          <div className="home_wrap">
            <Row gutter={[10, 10]}>
              <Col span={isMobile ? 24 : 12} className="home_wrap_left">
                <Service></Service>
              </Col>
              <Col span={isMobile ? 24 : 12} className="home_wrap_right">
                <AboutExpire></AboutExpire>
              </Col>
            </Row>
            <Row className="overview_row">
              <Col span={isMobile ? 24 : 12} className="home_wrap_left">
                <RegisterDaoTao></RegisterDaoTao>
              </Col>
              <Col span={isMobile ? 24 : 12} className="home_wrap_right">
                <ListHocVien></ListHocVien>
              </Col>
            </Row>
            <Col span={24} className="overview_new">
              <BaiVietMoi></BaiVietMoi>
            </Col>
          </div>
        </div>
      }
    />
  );
};

export default HomeMain;

import { configs, configsEn, header } from "../config";
import { callApi } from "./axiosClient";

const compass = "dong-tien/la-ban-dong-von";

const cashApi = {
  async getCompass(en: boolean) {
    const url = compass;
    return callApi("GET", url, null, en ? header : undefined);
  },
  async editCompass({ data, en }: { data: any, en: boolean }) {
    const url = compass;
    return callApi("PUT", url, data, en ? configsEn : configs);
  },
};
export default cashApi;

import { Col, message, Row } from "antd";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import mailApi from "../../../api/mailApi";
import RegisterCommon from "../../../components/RegisterCommon";
import CheckboxSlider from "../../../components/SliderList/CheckboxSlider";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { messages } from "../../../utils/messages";

const Mails = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(mailApi.delete, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("mails");
        queryClient.invalidateQueries("noti");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Thư liên hệ"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Thư liên hệ</b>
                </Col>
              </Row>
            }
          />
          <RegisterCommon
            keyQuery="mails"
            query={mailApi.getAll}
            link={appRoutes.MAIL.ROOT}
            isThuLienHe={true}
            action={mutation}
            download={mailApi.download}
            columnsParram={[
              {
                title: "#",
                width: "50px",
                dataIndex: "index",
                key: "index",
                align: "center",
              },
              {
                title: "Name",
                dataIndex: "ten",
                key: "ten",
              },
              {
                title: "Phone",
                dataIndex: "dienthoai",
                key: "dienthoai",
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: "8%",
              },
              {
                title: "Tiêu đề",
                dataIndex: "tieude",
                key: "tieude",
                width: "25%",
                render: (record: string) => (
                  <div dangerouslySetInnerHTML={{ __html: record }}></div>
                ),
              },
              {
                title: "Date",
                dataIndex: "ngaytao",
                key: "ngaytao",
                render: (record: string) => (
                  <>{moment(record).format("DD/MM/YYYY")}</>
                ),
              },
              {
                title: "Admin tư vấn",
                dataIndex: "chuyengia",
                key: "chuyengia",
                width: "10%",
                render: (record: any) => (
                  <>{record?.name}</>
                ),
              },
              {
                title: "Đã ĐK chuyên gia",
                dataIndex: "hasRegisterChuyengia",
                key: "hasRegisterChuyengia",
                align: "center",
                width: "50px",
                render: (record: boolean, data: any) => {
                  return (
                    <CheckboxSlider
                      _id={data?._id}
                      disable={true}
                      check={record ?? false}
                      dataName="hienthi"
                    />
                  );
                },
              },
              {
                title: "Trạng thái",
                dataIndex: "trangthai",
                align: "center",
                key: "trangthai",
                width: "8%",
              },
            ]}
          />
        </div>
      }
    />
  );
};

export default Mails;

import { Button, Card, Col, Form, message, Row } from "antd";
import moment from "moment";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import notificationApi from "../../../../api/notificationApi";
import DateTimePicker from "../../../../components/DateTimePicker";
import InputField from "../../../../components/InputField";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface NotificationSignDetailsProps {
  type: number;
}

const NotificationSignDetails: FC<NotificationSignDetailsProps> = ({
  type,
}) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useQuery(
    ["notification_sign", id],
    () => notificationApi.getDetailsSignNotification(id),
    {
      enabled: type === 2,
    }
  );
  const listChannel = useQuery("listChannel", notificationApi.getChannels);
  const { handleSubmit, control, setValue } = useForm();

  const mutationEdit = useMutation(notificationApi.editSignNotification, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("notification_sign");
        navigate(appRoutes.NOTIFICATION.SIGN.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(notificationApi.addSignNotification, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("notification_sign");
        navigate(appRoutes.NOTIFICATION.SIGN.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    if (type === 1) mutationAdd.mutate(dt);
    else mutationEdit.mutate({ id: id, data: dt });
  };

  return (
    <PageWrap
      title="Thông báo tín hiệu"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Thông báo tín hiệu</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching || listChannel?.isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  <InputField
                    label="Tiêu đề"
                    name="title"
                    placeholder="Tiêu đề"
                    control={control}
                    defaultValue={data?.data?.title}
                  />
                  <TextAreaField
                    label="Nội dung"
                    name="message"
                    placeholder="Nội dung"
                    control={control}
                    defaultValue={data?.data?.message}
                  />
                  <SelectField
                    label="Channel"
                    name="id_channel"
                    control={control}
                    options={listChannel?.data?.data?.map((e: any) => {
                      return {
                        key: e?.id,
                        value: e?.name,
                      };
                    })}
                    defaultValue={
                      data?.data?.id_channel ?? listChannel?.data?.data?.[0]?.id
                    }
                    listHeight={300}
                  />
                  <Controller
                    name="time"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        dateFormat="DD-MM-YYYY HH:mm"
                        defaultValue={moment(
                          data?.data?.time ?? new Date(),
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                        props={{
                          ...field,
                          onBlur: (e: any) => {
                            if (e.target.value === "")
                              setValue("time", undefined);
                            else if (
                              moment(
                                e.target.value,
                                "DD-MM-YYYY HH:mm",
                                true
                              )?.isValid()
                            )
                              setValue(
                                "time",
                                moment(e.target.value, "DD-MM-YYYY HH:mm")
                              );
                            else setValue("time", moment());
                          },
                        }}
                        label="Thời gian thực hiện"
                        allowClear={true}
                      />
                    )}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.NOTIFICATION.SIGN.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default NotificationSignDetails;

import { Table, Typography } from "antd";
import { ColumnType, TablePaginationConfig, TableProps } from "antd/lib/table";
import { FC, ReactNode } from "react";
import "./style.scss";

interface IProps<T = any> extends TableProps<T> {
  name?: string;
  headerExtend?: ReactNode;
  hasDefaultColumn?: boolean;
  isShowTotal?: boolean;
  rowClassName?: string;
  onRowClick?: (e: any) => void;
  recordName?: string;
}

const defaultColumn: ColumnType<any> = {
  title: "STT",
  dataIndex: "id",
  render: (_: any, record: any, index: number) => index + 1,
  align: "center",
};

const TableWrap: FC<IProps> = ({
  name,
  pagination,
  className,
  headerExtend,
  columns,
  hasDefaultColumn,
  isShowTotal = false,
  onRowClick,
  rowClassName,
  recordName="trangthai",
  ...props
}) => {
  const showTotal = () => {
    if (!isShowTotal) return undefined;

    const _pagination = pagination as TablePaginationConfig;
    if (
      _pagination &&
      _pagination.current &&
      _pagination.pageSize &&
      props.dataSource
    ) {
      return (
        <Typography.Paragraph
          style={{
            fontWeight: 400,
            color: "#525253",
          }}
        >
          {`Hiển thị kết quả từ ${
            (_pagination?.current - 1) * _pagination.pageSize + 1
          } - ${
            (_pagination?.current - 1) * _pagination.pageSize +
            props.dataSource?.length
          } trên tổng ${_pagination.total} mục`}
        </Typography.Paragraph>
      );
    }
  };

  const paginationConfig = pagination
    ? {
        ...pagination,
        showTotal,
      }
    : false;

  return (
    <Table
      className={`table${isShowTotal ? " showTotal" : ""}`}
      rowClassName={(record, index) => {
        return `${record?.[`${recordName}`] === "Chưa xem" ? "bold" : ""} ${
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        } ${rowClassName ? rowClassName : ""}`;
      }}
      columns={hasDefaultColumn ? [defaultColumn, ...(columns as [])] : columns}
      pagination={paginationConfig}
      onRow={
        props?.onRow
          ? props?.onRow
          : (record: any) => {
              return {
                onClick: () => {
                  var isCollapsed: any = window.getSelection()?.toString();
                  if (!isCollapsed && onRowClick) {
                    onRowClick(record);
                  }
                },
              };
            }
      }
      {...props}
    />
  );
};

export default TableWrap;

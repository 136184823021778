import { configs } from "../config";
import { callApi } from "./axiosClient";

const impt = "import";

const imptApi = {
  async getHistory({
    time,
    key,
    isAuto,
  }: {
    time?: string;
    key?: number;
    isAuto?: boolean;
  }) {
    const url = `${impt}/history${time ? `?time=${time}` : ""}${
      key && !time ? `?key=${key}` : key ? `&key=${key}` : ""
    }${
      isAuto !== undefined && !key && !time
        ? `?isauto=${isAuto}`
        : isAuto !== undefined
        ? `&isauto=${isAuto}`
        : ""
    }`;
    return callApi("GET", url);
  },
  async delHistory(id: string | undefined) {
    const url = `${impt}/history/${id}`;
    return callApi("DELETE", url);
  },
  async getListKeys() {
    const url = `${impt}/orther/listKeys`;
    return callApi("GET", url);
  },
  async getSaveKeys() {
    const url = `${impt}/orther/saveKey`;
    return callApi("GET", url);
  },
  async checkToken(token: string) {
    const url = `${impt}/orther/checkToken/${token}`;
    return callApi("GET", url);
  },
  async importFile(data: any) {
    const url = `${impt}/file`;
    return callApi("POST", url, data, configs);
  },
  async importFileAuto(data: any) {
    const url = `${impt}/file/auto`;
    return callApi("POST", url, data);
  },
  async getStatusImport() {
    const url = `${impt}/file/status`;
    return callApi("GET", url);
  },
  async cancelImport() {
    const url = `${impt}/orther/removeAll`;
    return callApi("POST", url);
  },
};

export default imptApi;

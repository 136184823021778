import { Button, Card, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import categoryApi from "../../../../api/categoryApi";
import InputField from "../../../../components/InputField";
import Loading from "../../../../components/Loading";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface FormCategoryProps {
  type: number;
}

const FormCategory: FC<FormCategoryProps> = ({ type }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery(
    ["training_post", "post", id],
    () => categoryApi.getCateDetailsNews(id),
    { enabled: type === 2 }
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(categoryApi.editCate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("create-category_categories");
        navigate(appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(categoryApi.addCateNews, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("create-category_categories");
        navigate(appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST);
        window.scrollTo(0, 0);
      }
    },
  });

  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };

  return (
    <PageWrap
      title="Tin quan trọng"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  <InputField
                    label="Tiêu đề"
                    name="name"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.name}
                    errorMsg={errors?.name?.message}
                    rules={{
                      required: "Vui lòng nhập tiêu đề",
                    }}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(
                            appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY
                              .LIST
                          )
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default FormCategory;

import React, { FC } from "react";
import './style.scss';

interface TopContentProps {
  content: React.ReactNode;
}
const TopContent: FC<TopContentProps> = ({ content }) => {
  return <div className="top_content_wrap">{content}</div>;
};

export default TopContent;

import { CKEditor } from "ckeditor4-react";
import { FC } from "react";

interface CkeditorProps {
  initData: any;
  onLoaded?: any;
  onChange: (e: any) => void;
}
const Ckeditor: FC<CkeditorProps> = ({ initData, onChange, onLoaded }) => {
  return (
    <CKEditor
      editorUrl={`${process.env.REACT_APP_DOMAIN}uploads/ckeditor/ckeditor.js`}
      initData={initData}
      onChange={onChange}
      onPaste={(evt: any) => {
        setTimeout(() => {
          return onChange(evt);
        }, 100);
      }}
      config={{
        filebrowserImageUploadUrl: `${process.env.REACT_APP_API_URL}ckediter/upload/picture`,
        filebrowserUploadUrl: `${process.env.REACT_APP_API_URL}ckediter/upload/file`,
      }}
      onLoaded={onLoaded}
    />
  );
};
export default Ckeditor;

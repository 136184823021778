import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import thongTinKhoaHocApi from "../../../../api/thongTinKhoaHoc";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const ListGiangVien = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching }: any = useQuery(
    ["list_giang_vien"],
    thongTinKhoaHocApi.getListGiangVien
  );
  const mutation = useMutation(thongTinKhoaHocApi.deleteGiangVien, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(["list_giang_vien"]);
      }
    },
    onError: (err: any) => {
      message.error(messages.error);
    },
  });
  const update = useMutation(thongTinKhoaHocApi.editGiangVien, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries(["list_giang_vien"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Tên giảng viên",
      dataIndex: "ten_vi",
      key: "ten_vi",
      width: "20%",
    },
    {
      title: "Sửa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.TRAINING.GIANG_VIEN.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa đánh giá này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <PageWrap
      title="Danh sách giảng viên"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh sách giảng viên</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(`${appRoutes.TRAINING.GIANG_VIEN.ADD}`)
                    }
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data}
              rowKey="_id"
              bordered
              pagination={false}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </>
      }
    />
  );
};

export default ListGiangVien;

import { configs, header } from "../config";
import { callApi } from "./axiosClient";

const content = "bai-viet";
const about = "ve-chung-toi";
const question = "cau-hoi-thuong-gap";
const guide = "huong-dan";
const term = "dieu-khoan";
const investment = "co-hoi-dau-tu";
const sign = "tin-hieu";
const blog = "blogs";
const category = "category";
const trading_thuc_chien = "trading-thuc-chien";
const review_broker = "review-broker";

const contentApi = {
  async getAbout(en: boolean) {
    const url = `${content}/${about}`;
    return callApi("GET", url, null, en ? header : undefined);
  },
  async editAbout({ data, en }: { data: any; en: boolean }) {
    const url = `${content}/${about}`;
    return callApi("PUT", url, data, en ? header : undefined);
  },
  async getTerm(en: boolean) {
    const url = `${content}/${term}`;
    return callApi("GET", url, null, en ? header : undefined);
  },
  async editTerm({ data, en }: { data: any; en: boolean }) {
    const url = `${content}/${term}`;
    return callApi("PUT", url, data, en ? header : undefined);
  },
  async getQuestions() {
    const url = `${content}/${question}`;
    return callApi("GET", url);
  },
  async getQuestionDetails(id: string | undefined) {
    const url = `${content}/${question}/${id}`;
    return callApi("GET", url);
  },
  async addQuestion(data: any) {
    const url = `${content}/${question}`;
    return callApi("POST", url, data);
  },
  async editQuestion({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${question}/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteQuestion(id: string) {
    const url = `${content}/${question}/${id}`;
    return callApi("DELETE", url);
  },
  async getGuides(en: boolean) {
    const url = `${content}/${guide}`;
    return callApi("GET", url, null, en ? header : undefined);
  },
  async getGuideDetails(id: string | undefined) {
    const url = `${content}/${guide}/${id}`;
    return callApi("GET", url);
  },
  async addGuide({ data, en }: { data: any; en: boolean }) {
    const url = `${content}/${guide}`;
    return callApi("POST", url, data, en ? header : undefined);
  },
  async editGuide({
    id,
    data,
    en,
  }: {
    id: string | undefined;
    data: any;
    en: boolean;
  }) {
    const url = `${content}/${guide}/${id}`;
    return callApi("PUT", url, data, en ? header : undefined);
  },
  async deleteGuide({ id, en }: { id: string; en: boolean }) {
    const url = `${content}/${guide}/${id}`;
    return callApi("DELETE", url, en ? header : undefined);
  },
  async getInvestments(page: number, page_size: number) {
    const url = `${content}/${investment}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getInvestmentDetails(id: string | undefined) {
    const url = `${content}/${investment}/${id}`;
    return callApi("GET", url);
  },
  async addInvestment(data: any) {
    const url = `${content}/${investment}`;
    return callApi("POST", url, data, configs);
  },
  async editInvestment({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${investment}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteInvestment(id: string) {
    const url = `${content}/${investment}/${id}`;
    return callApi("DELETE", url);
  },
  async getSigns(page: number, page_size: number) {
    const url = `${content}/${sign}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getSignDetails(id: string | undefined) {
    const url = `${content}/${sign}/${id}`;
    return callApi("GET", url);
  },
  async addSign(data: any) {
    const url = `${content}/${sign}`;
    return callApi("POST", url, data, configs);
  },
  async editSign({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${sign}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteSign(id: string) {
    const url = `${content}/${sign}/${id}`;
    return callApi("DELETE", url);
  },
  async getBlogs(page: number, page_size: number) {
    const url = `${content}/${blog}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getBlogDetails(id: string | undefined) {
    const url = `${content}/${blog}/${id}`;
    return callApi("GET", url);
  },
  async addBlog(data: any) {
    const url = `${content}/${blog}`;
    return callApi("POST", url, data, configs);
  },
  async editBlog({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${blog}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteBlog(id: string) {
    const url = `${content}/${blog}/${id}`;
    return callApi("DELETE", url);
  },
  async getDanhMucBaiViet({ type }: { type?: number }) {
    const url = type
      ? `${content}/${category}/${type}`
      : `${content}/${category}`;
    return callApi("GET", url);
  },
  async deleteDanhMucBaiViet(id: string) {
    const url = `${content}/${category}/${id}`;
    return callApi("DELETE", url);
  },
  async getListDanhMucByPage() {
    const url = `${content}/${category}/list-page`;
    return callApi("GET", url);
  },

  async addCategoryBaiBietV3(data: any) {
    const url = `${content}/${category}`;
    return callApi("POST", url, data);
  },
  async editCategoryBaiBietV3({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${category}/${id}`;
    return callApi("PUT", url, data);
  },
  async getCategoryBaiBietV3(id: string | undefined) {
    const url = `${content}/${category}/detail/${id}`;
    return callApi("GET", url);
  },


  async getListDanhMucByID(danhmuc: any) {
    const url = `${content}/${category}/${danhmuc}`;
    return callApi("GET", url);
  },


  async getTradingThucChien(page: number, page_size: number) {
    const url = `${content}/${trading_thuc_chien}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getTradingThucChienDetails(id: string | undefined) {
    const url = `${content}/${trading_thuc_chien}/${id}`;
    return callApi("GET", url);
  },
  async addTradingThucChien(data: any) {
    const url = `${content}/${trading_thuc_chien}`;
    return callApi("POST", url, data, configs);
  },
  async editTradingThucChien({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${trading_thuc_chien}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteTradingThucChien(id: string) {
    const url = `${content}/${trading_thuc_chien}/${id}`;
    return callApi("DELETE", url);
  },

  async getReviewBroker(page: number, page_size: number) {
    const url = `${content}/${review_broker}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getReviewBrokerDetails(id: string | undefined) {
    const url = `${content}/${review_broker}/${id}`;
    return callApi("GET", url);
  },
  async addReviewBroker(data: any) {
    const url = `${content}/${review_broker}`;
    return callApi("POST", url, data, configs);
  },
  async editReviewBroker({ id, data }: { id: string | undefined; data: any }) {
    const url = `${content}/${review_broker}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteReviewBroker(id: string) {
    const url = `${content}/${review_broker}/${id}`;
    return callApi("DELETE", url);
  },

};

export default contentApi;

import { Col, message, Row, Tabs } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import contentApi from "../../../api/contentApi";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { labels } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import AboutForm from "./AboutForm";

const About = () => {
  const queryClient = useQueryClient();
  const [idx, setIdx] = useState(0);

  const { data, isLoading, isFetching } = useQuery(
    ["content_about", idx === 0 ? "vi" : "en"],
    () => contentApi.getAbout(idx === 1)
  );

  const mutation = useMutation(contentApi.editAbout, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries([
          "content_about",
          idx === 0 ? "vi" : "en",
        ]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    mutation.mutate({ data: dt, en: idx === 1 });
  };

  return (
    <PageWrap
      title="Về chúng tôi"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Giới thiệu</b>
                </Col>
              </Row>
            }
          />
          <div className="content about">
            <Tabs
              type="card"
              onChange={(e: any) => {
                setIdx(e);
                queryClient.invalidateQueries([
                  "content_about",
                  e === 0 ? "vi" : "en",
                ]);
              }}
              items={labels.map((item: any, i: any) => {
                return {
                  label: item,
                  key: i,
                  children: (
                    <AboutForm
                      loading={isLoading || isFetching}
                      noidung={data?.data?.noidung_vi}
                      title={data?.data?.title}
                      onSubmit={onSubmit}
                    />
                  ),
                };
              })}
            />
          </div>
        </>
      }
    />
  );
};

export default About;

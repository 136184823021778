import { ConfigProvider, Empty } from "antd";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./store";
import App from "./App";
import { createRoot } from "react-dom/client";
import "./index.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  },
});

createRoot(document.getElementById("root") as HTMLElement).render(
  <StoreProvider>
    <ConfigProvider
      renderEmpty={() => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data"} />
      )}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback="loading">
          <App />
        </Suspense>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </ConfigProvider>
  </StoreProvider>
);

reportWebVitals();

import { Form, Input } from "antd";
import { FC } from "react";
import ErrorMessage from "../ErrorMessage";
import { Controller } from "react-hook-form";
import "./style.scss";

// interface InputRulesProps {
//   required?: boolean;
//   message?: string;
//   [key: string]: any;
// }
interface IInputFieldProps {
  label?: React.ReactNode;
  name?: string | any;
  required?: boolean;
  message?: string;
  placeholder?: string;
  onChange?: any;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  prefix?: React.ReactNode;
  disable?: boolean;
  rules?: any;
  defaultValue?: string;
  maxLength?: number;
  height?: string;
  width?: string;
  className?: string;
  onBlur?: any;
  autoFocus?: boolean;
  type?: string;
  errorMsg?: any;
  control?: any;
  suffix?: React.ReactNode;
  isPassword?: boolean;
  min?: number;
  max?: number;
  setValue?: any;
  isImage?: boolean;
  msg?: string;
  readOnly?: boolean
}

const InputField: FC<IInputFieldProps> = ({
  label,
  name,
  placeholder,
  onChange,
  onKeyDown,
  prefix,
  disable,
  rules,
  defaultValue,
  maxLength,
  height,
  width,
  className,
  onBlur,
  autoFocus,
  type,
  errorMsg,
  control,
  suffix,
  isPassword = false,
  min,
  max,
  setValue,
  isImage,
  msg,
  readOnly = false
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Form.Item
          label={label}
          help={msg ?? <ErrorMessage message={errorMsg} />}
          className={errorMsg ? "error-field" : ""}
          colon={false}
        >
          {isImage ? (
            <Input
              {...field}
              type="file"
              accept="image/*"
              multiple={false}
              readOnly={readOnly}
              onChange={(e: any) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
            />
          ) : isPassword ? (
            <Input.Password
              width={width}
              suffix={suffix}
              type={type}
              {...field}
              height={height}
              placeholder={placeholder}
              prefix={prefix}
              disabled={disable}
              maxLength={maxLength}
              className={"input_password" + className ? className : ""}
              onBlur={(e) => {
                field.onBlur();
                if (onBlur) onBlur(e);
              }}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
              onKeyDown={onKeyDown}
              autoFocus={autoFocus ?? undefined}
            />
          ) : (
            <Input
              width={width}
              suffix={suffix}
              type={type}
              min={min}
              max={max}
              {...field}
              readOnly={readOnly}
              height={height}
              placeholder={placeholder}
              prefix={prefix}
              disabled={disable}
              maxLength={maxLength}
              className={className}
              onBlur={(e) => {
                field.onBlur();
                if (setValue) setValue(name, e.target.value.trim());
                if (onBlur) onBlur(e);
              }}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
              onKeyDown={onKeyDown}
              autoFocus={autoFocus ?? undefined}
            />
          )}
        </Form.Item>
      )}
    />
  );
};

export default InputField;

import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC } from "react";
import { Controller } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";

interface ITextAreaFieldProps {
  label: React.ReactNode;
  name: string;
  required?: boolean;
  message?: string;
  placeholder?: string;
  rows?: number;
  maxLength?: number;
  disable?: boolean;
  readOnly?: boolean;
  className?: string;
  onChange?: any;
  onBlur?: any;
  control: any;
  defaultValue?: string;
  errorMsg?: any;
  rules?: any;
}

const TextAreaField: FC<ITextAreaFieldProps> = ({
  label,
  name,
  required,
  message,
  placeholder,
  rows = 5,
  maxLength,
  disable,
  className,
  onChange,
  onBlur,
  control,
  defaultValue,
  errorMsg,
  readOnly,
  rules,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <Form.Item
          label={label}
          help={<ErrorMessage message={errorMsg} />}
          className={errorMsg ? "error-field" : ""}
          colon={false}
        >
          <TextArea
            rows={rows}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disable}
            readOnly={readOnly ?? false}
            {...field}
            className={className}
            onBlur={(e) => {
              field.onBlur();
              if (onBlur) onBlur(e);
            }}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
          />
        </Form.Item>
      )}
    />
  );
};

export default TextAreaField;

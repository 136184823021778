import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import sliderApi from "../../../../api/sliderApi";
import SliderList from "../../../../components/SliderList";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const SliderFeedback = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(sliderApi.deleteFeedbackSlider, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("feedback-slider");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Khách hàng nói gì"
      content={
        <SliderList
          headContent="Khách hàng nói gì"
          handleRemove={(e) => mutation.mutate(e)}
          action={useMutation(sliderApi.updateVisibleFeedback, {
            onSuccess: (data: any) => {
              if (data.statusCode === 200) {
                message.success(messages.updateSuccess);
                queryClient.invalidateQueries("feedback-slider");
              } else {
                message.error(data?.data?.message ?? messages.error);
              }
            },
            onError: (err: any) => {
              message.error(err?.data?.message ?? messages.error);
            },
          })}
          keyQuery="feedback-slider"
          link="/slider/feedback"
          query={sliderApi.getFeedbackSlider}
          column={[
            {
              title: "Tên / Chức danh",
              dataIndex: "khachhang",
              width: "15%",
              key: "khachhang",
              render: (record: string) => (
                <>
                  <b>{record}</b>
                  <div>Khách hàng Finashark</div>
                </>
              ),
            },
            {
              title: "Nội dung",
              dataIndex: "commemt",
              key: "commemt",
              width: "25%",
            },
            {
              title: "Hình ảnh",
              dataIndex: "photo",
              key: "photo",
              width: "35%",
              className: "td-slider-picture",
              render: (record: string) => {
                return (
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${record}`}
                    alt=""
                    className="slider-picture"
                  ></img>
                );
              },
            },
          ]}
        />
      }
    />
  );
};

export default SliderFeedback;

import { message } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import sliderApi from "../../../../api/sliderApi";
import SliderList from "../../../../components/SliderList";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const SliderCash = () => {
  const queryClient = useQueryClient();
  const [idx, setIdx] = useState(0);

  const mutation = useMutation(sliderApi.deleteCashSlider, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(["cash-slider", idx === 0 ? "vi" : "en"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Slider dòng tiền"
      content={
        <SliderList
          idx={idx}
          setIdx={(e: number) => setIdx(e)}
          headContent="Slider dòng tiền"
          handleRemove={(e) => mutation.mutate({ id: e, en: idx === 1 })}
          action={useMutation(sliderApi.updateVisibleCash, {
            onSuccess: (data: any) => {
              if (data.statusCode === 200) {
                message.success(messages.updateSuccess);
                queryClient.invalidateQueries([
                  "cash-slider",
                  idx === 0 ? "vi" : "en",
                ]);
              } else {
                message.error(data?.data?.message ?? messages.error);
              }
            },
            onError: (err: any) => {
              message.error(err?.data?.message ?? messages.error);
            },
          })}
          keyQuery={["cash-slider", idx === 0 ? "vi" : "en"]}
          link="/slider/dong-tien"
          query={() => sliderApi.getCashSlider(idx === 1)}
          column={[
            {
              title: "Link",
              dataIndex: "url",
              width: "40%",
              key: "url",
              render: (record: string) => {
                return (
                  <a target="_blank" href={record} rel="noreferrer">
                    {record}
                  </a>
                );
              },
            },
            {
              title: "Hình ảnh",
              dataIndex: "photo",
              width: "20%",
              key: "photo",
              className: "td-slider-picture",
              render: (record: string) => {
                return (
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${record}`}
                    alt=""
                    className="slider-picture"
                  ></img>
                );
              },
            },
          ]}
        />
      }
    />
  );
};

export default SliderCash;

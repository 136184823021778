import { Button, Card, Col, Form, message, Row } from "antd";
import Ckeditor from "../../../components/Ckeditor";
import { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import systemSettingApi from "../../../api/systemSettingApi";
import Loading from "../../../components/Loading";
import { messages } from "../../../utils/messages";
import "./style.scss";

interface GuideContentProps {
  index: number;
}
const GuideContent: FC<GuideContentProps> = ({ index }) => {
  const { data, isLoading, isFetching }: any = useQuery(
    ["setting", "guide"],
    systemSettingApi.getGuideSetting
  );
  const [dataChuyenkhoan, setChuyenkhoan]: any = useState("");
  const [dataChibao, setChiBao]: any = useState("");

  useEffect(() => {
    if (data?.data?.[1]?.content) setChuyenkhoan(data.data[1].content);
    if (data?.data?.[0]?.content) setChiBao(data.data[0].content);
  }, [data]);
  const mutation = useMutation(systemSettingApi.editGuideSetting, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const handleSubmit = () => {
    mutation.mutate({
      chibao: dataChibao,
      chuyenkhoan: dataChuyenkhoan,
    });
  };
  useEffect(() => {
    if (data?.data?.[1]?.content) setChuyenkhoan(data.data[1].content);
    if (data?.data?.[0]?.content) setChiBao(data.data[0].content);
  }, [index]);

  return (
    <>
      {isLoading || isFetching ? (
        <div className="setting-loading">
          <Loading />
        </div>
      ) : (
        <Card bordered={false}>
          <Form onFinish={handleSubmit}>
            <Row gutter={[16, 10]}>
              <Col md={24} lg={12}>
                <p className="setting_title">Hướng dẫn chuyển khoản</p>
                <Ckeditor
                  initData={data?.data?.[1]?.content}
                  onChange={(evt: any) => setChuyenkhoan(evt.editor.getData())}
                ></Ckeditor>
              </Col>
              <Col md={24} lg={12}>
                <p className="setting_title">
                  Hướng dẫn chuyển khoản thư viện chỉ báo
                </p>
                <Ckeditor
                  initData={data?.data?.[0]?.content}
                  onChange={(evt: any) => setChiBao(evt.editor.getData())}
                ></Ckeditor>
                <Row
                  justify="end"
                  style={{ paddingTop: "30px", paddingRight: "8px" }}
                >
                  <Form.Item>
                    <Button type="primary" danger htmlType="submit">
                      Cập nhật
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};

export default GuideContent;

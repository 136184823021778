import { Form, InputNumber } from "antd";
import { FC } from "react";
import { Controller } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";

interface InputNumberProps {
  control: any;
  label?: string;
  name: string;
  defaultValue?: string;
  min?: number;
  max?: number;
  errorMsg?: any;
  rules?: any;
  onBlur?: any;
  onChange?: any;
}
const InputNumberWrap: FC<InputNumberProps> = ({
  control,
  label,
  name,
  defaultValue,
  min,
  max,
  errorMsg,
  rules,
  onBlur,
  onChange,
}) => {
  return (
    <Form.Item
      label={label}
      help={<ErrorMessage message={errorMsg} />}
      className={errorMsg ? "error-field" : ""}
      colon={false}
      rules={rules}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <InputNumber
            min={min}
            max={max}
            {...field}
            onBlur={(e) => {
              field.onBlur();
              if (onBlur) onBlur(e);
            }}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
          />
        )}
      />
    </Form.Item>
  );
};

export default InputNumberWrap;

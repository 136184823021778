import { Checkbox } from "antd";
import { FC, useEffect, useState } from "react";

interface CheckboxSliderProps {
  check: boolean;
  _id: string;
  action?: any;
  name?: any;
  dataName?: any;
  formName?: any;
  en?: boolean | undefined;
  disable?: boolean | undefined
}
const CheckboxSlider: FC<CheckboxSliderProps> = ({
  check,
  _id,
  action,
  name,
  dataName,
  formName,
  en,
  disable
}) => {
  const [checked, setChecked]: any = useState(check);

  useEffect(() => {
    if (check == undefined) {
      setChecked(false);
    } else {
      setChecked(check);
    }
  }, [check]);

  return (
    <Checkbox
      className={`checkbox_table_${
        checked !== "" && check !== null && check !== undefined ? check : false
      }`}
      defaultChecked={checked}
      checked={checked}
      onChange={(e: any) => {
        if(disable) return;
        if (action && dataName)
          action.mutate({
            id: _id,
            data: { [`${dataName}`]: e.target.checked, en: en },
          });
        if (action && name)
          action.mutate({ id: _id, [`${name}`]: e.target.checked, en: en });
        if (action && formName) {
          const formData = new FormData();
          formData.set(formName, e.target.checked);
          action.mutate({ id: _id, data: formData, en: en });
        }
        setChecked(e.target.checked);
      }}
      id={`checkbox-slider-home-${_id}`}
    ></Checkbox>
  );
};

export default CheckboxSlider;

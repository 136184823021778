import { callApi } from "./axiosClient";

const register = "dang-ky";
const mail = "thu-lien-he";

const mailApi = {
  async getAll(
    page: number,
    page_size: number,
    email?: string,
    ngaytao?: string,
    name?: string,
    phone?: string,
    status?: number,
    dk_chuyen_gia?: number,
    admin?: number
  ) {
    const url = `${mail}/${register}?page=${page}&page_size=${page_size}${
      email ? "&email=" + email : ""
    }${ngaytao ? "&ngaytao=" + ngaytao : ""}${admin ? "&admin=" + admin : ""}${
      dk_chuyen_gia ? "&dk_chuyen_gia=" + dk_chuyen_gia : ""
    }${status ? "&trangthai=" + status : ""}`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined) {
    const url = `${mail}/${register}/${id}`;
    return callApi("GET", url);
  },
  async updateStatus({
    id,
    status,
    hasRegisterChuyengia,
  }: {
    id: string | undefined;
    status: number | undefined;
    hasRegisterChuyengia: boolean | undefined;
  }) {
    const url = `${mail}/${register}/${id}`;
    return callApi("POST", url, {
      trangthai: status,
      hasRegisterChuyengia: hasRegisterChuyengia,
    });
  },
  async delete(id: string) {
    const url = `${mail}/${register}/${id}`;
    return callApi("DELETE", url);
  },
  async download() {
    const url = `${mail}/download`;
    const headers: any = { responseType: "arraybuffer" };
    const response: any = await callApi("GET", url, null, headers);
    const result =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      Buffer.from(response, "binary").toString("base64");
    const aElement = document.createElement("a");
    aElement.href = result;
    aElement.download = "ThuLienHe.xlsx";
    aElement.click();
    return result;
  },
};

export default mailApi;

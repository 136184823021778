import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import contentApi from "../../../../api/contentApi";
import CheckboxSlider from "../../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const ListQuestions = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching }: any = useQuery(
    ["content_question"],
    contentApi.getQuestions
  );
  const mutation = useMutation(contentApi.deleteQuestion, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(["content_question"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationEdit = useMutation(contentApi.editQuestion, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("content_question");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const handleRemove = (e: any) => {
    mutation.mutate(e);
  };
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Nội dung",
      dataIndex: "ten_vi",
      render: (_, record: any) => (
        <>
          <b>{record?.ten_vi}</b>
          <br />
          {record?.mota_vi}
        </>
      ),
    },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      align: "center",
      width: "8%",
      render: (record: boolean, data: any) => (
        <CheckboxSlider
          _id={data._id}
          check={record}
          dataName="hienthi"
          action={mutationEdit}
        />
      ),
    },
    {
      title: "Sửa",
      align: "center",
      width: "5%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.CONTENT.QUESTIONS.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "5%",
      render: (record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={() => handleRemove(record._id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <PageWrap
      title="Câu hỏi thường gặp"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Câu hỏi thường gặp</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(`${appRoutes.CONTENT.QUESTIONS.ADD}`)
                    }
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content ">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data}
              rowKey="_id"
              bordered
              pagination={false}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </>
      }
    />
  );
};

export default ListQuestions;

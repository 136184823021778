import { callApi } from "./axiosClient";

const cate = "lap-danh-muc";
const news = "tin-tuc";

const categoryApi = {
  async getListKeys() {
    const url = `${cate}/${news}/san-lich-su-kien`;
    return callApi("GET", url);
  },
  async getFund() {
    const url = `${cate}/quy-chu-dong`;
    return callApi("GET", url);
  },
  async editFund(data: any) {
    const url = `${cate}/quy-chu-dong`;
    return callApi("PUT", url, data);
  },
  async getCateNews() {
    const url = `${cate}/${news}/san-tin-quan-trong`;
    return callApi("GET", url);
  },
  async getCateDetailsNews(id: string | undefined) {
    const url = `${cate}/${news}/san-tin-quan-trong/${id}`;
    return callApi("GET", url);
  },
  async addCateNews(data: any) {
    const url = `${cate}/${news}/san-tin-quan-trong`;
    return callApi("POST", url, data);
  },
  async editCate({ id, data }: { id: string | undefined; data: any }) {
    const url = `${cate}/${news}/san-tin-quan-trong/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteCate(id: string) {
    const url = `${cate}/${news}/san-tin-quan-trong/${id}`;
    return callApi("DELETE", url);
  },
  async getListNews(page: number, page_size: number) {
    const url = `${cate}/${news}/tin-quan-trong?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getNewDetails(id: string | undefined) {
    const url = `${cate}/${news}/tin-quan-trong/${id}`;
    return callApi("GET", url);
  },
  async addNews(data: any) {
    const url = `${cate}/${news}/tin-quan-trong`;
    return callApi("POST", url, data);
  },
  async editNews({ id, data }: { id: string | undefined; data: any }) {
    const url = `${cate}/${news}/tin-quan-trong/${id}`;
    return callApi("PUT", url, data);
  },
  async getListEvents(page: number, page_size: number) {
    const url = `${cate}/${news}/lich-su-kien?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getEventDetails(id: string | undefined) {
    const url = `${cate}/${news}/lich-su-kien/${id}`;
    return callApi("GET", url);
  },
  async addEvent(data: any) {
    const url = `${cate}/${news}/lich-su-kien`;
    return callApi("POST", url, data);
  },
  async editEvent({ id, data }: { id: string | undefined; data: any }) {
    const url = `${cate}/${news}/lich-su-kien/${id}`;
    return callApi("PUT", url, data);
  },
  async deletePost(id: string) {
    const url = `${cate}/${news}/xoa-bai-viet/${id}`;
    return callApi("DELETE", url);
  },
  async getCategoryBaiBiet(id: string | undefined) {
    const url = `${cate}/${news}/san-tin-quan-trong/${id}`;
    return callApi("GET", url);
  },
};

export default categoryApi;

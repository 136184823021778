import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { stts, dk_chuyen_gia_option } from "../../utils/constants";
import DateTimePicker from "../DateTimePicker";
import InputField from "../InputField";
import SelectField from "../SelectField";
import TableWrap from "../Table";
import tuvanApi from "../../api/tuvanApi";
import "./style.scss";
interface RegisterCommonProps {
  link: string;
  keyQuery: any;
  query: any;
  action?: any;
  download?: any;
  filter?: boolean;
  columnsParram?: ColumnsType<any>;
  isThuLienHe?: boolean | undefined;
}

const RegisterCommon: FC<RegisterCommonProps> = ({
  link,
  keyQuery,
  query,
  action,
  download,
  columnsParram,
  isThuLienHe,
  filter = true
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [email, setEmail] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [status, setStatus] = useState<number | undefined>();
  const [dk_chuyen_gia, setDkChuyenGia] = useState<number | undefined>();
  const [admin, setAdmin] = useState<number | undefined>();
  const [exprt, setExport] = useState(false);
  const [date, setDate]: any = useState();

  const { handleSubmit, control, setValue } = useForm();

  const { data, isLoading, isFetching }: any = useQuery(
    [
      keyQuery,
      currentPage,
      limit,
      email,
      name,
      phone,
      status,
      date,
      dk_chuyen_gia,
      admin,
    ],
    () =>
      query(
        currentPage,
        limit,
        email,
        date,
        name,
        phone,
        status,
        dk_chuyen_gia,
        admin
      )
  );
  const exportData = useQuery([`${keyQuery}_download`], download, {
    enabled: exprt,
  });

  const columns: ColumnsType<any> = columnsParram ?? [
    {
      title: "#",
      width: "5%",
      dataIndex: "index",
      key: "index",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "ten",
      key: "ten",
    },
    {
      title: "Phone",
      dataIndex: "dienthoai",
      key: "dienthoai",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "8%",
    },
    {
      title: "Tiêu đề",
      dataIndex: "tieude",
      key: "tieude",
      width: "30%",
      render: (record: string) => (
        <div dangerouslySetInnerHTML={{ __html: record }}></div>
      ),
    },
    {
      title: "Date",
      dataIndex: "ngaytao",
      key: "ngaytao",
      render: (record: string) => <>{moment(record).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangthai",
      align: "center",
      key: "trangthai",
      width: "8%",
    },
  ];

  const columnsDefault: ColumnsType<any> = [
    {
      title: "Detail",
      key: "detail",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <a onClick={() => navigate(link + `/${record?._id}`)}>Xem</a>
      ),
    },
    {
      title: "Delete",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              if (action) action.mutate(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const onFilter = (dt: any) => {
    setEmail(dt.email);
    setDkChuyenGia(dt.dk_chuyen_gia);
    setAdmin(dt.admin);
    setName(dt.ten);
    setPhone(dt.dienthoai);
    setStatus(dt.trangthai);
    setDate(dt.ngaytao ? dt.ngaytao.format("DD/MM/YYYY") : undefined);
    setCurrentPage(1);
  };

  useEffect(() => {
    setExport(false);
  }, [exportData]);

  const listChuyengia: any = useQuery(
    "list_chuyen_gia",
    tuvanApi.getListChuyenggia,
    {
      enabled: isThuLienHe,
    }
  );

  return (
    <Card bordered={false} className="register_common">
      <>
        <Form className="register_common__form">
          <Row
            style={{ marginBottom: "30px" }}
            align="bottom"
            gutter={[20, 10]}
          >
            <Col xl={19} md={24}>
              {filter && <Row gutter={[20, 10]} align="bottom">
                <Col lg={5} md={24} sm={24} xs={24}>
                  <InputField
                    label="Email khách hàng"
                    placeholder="Email khách hàng"
                    name="email"
                    control={control}
                  />
                </Col>

                {!isThuLienHe && (
                  <Col lg={5} md={24} sm={24} xs={24}>
                    <InputField
                      label="Tên người gửi"
                      placeholder="Tên người gửi"
                      name="ten"
                      control={control}
                    />
                  </Col>
                )}
                {!isThuLienHe && (
                  <Col lg={5} md={24} sm={24} xs={24}>
                    <InputField
                      label="Điện thoại"
                      placeholder="Điện thoại"
                      name="dienthoai"
                      control={control}
                    />
                  </Col>
                )}
                <Col lg={5} md={24} sm={24} xs={24}>
                  <SelectField
                    label="Trạng thái"
                    placeholder="Trạng thái"
                    options={stts}
                    control={control}
                    name="trangthai"
                    listHeight={160}
                    allowClear={true}
                    style={{ minWidth: "120px" }}
                  />
                </Col>
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Controller
                    name="ngaytao"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        defaultValue={undefined}
                        props={{
                          ...field,
                          onBlur: (e: any) => {
                            if (e.target.value === "")
                              setValue("ngaytao", undefined);
                            else if (
                              moment(
                                e.target.value,
                                "DD/MM/YYYY",
                                true
                              )?.isValid()
                            )
                              setValue(
                                "ngaytao",
                                moment(e.target.value, "DD/MM/YYYY")
                              );
                            else setValue("ngaytao", moment());
                          },
                        }}
                        label="Ngày gửi"
                        allowClear={true}
                        action={handleSubmit(onFilter)}
                      />
                    )}
                  />
                </Col>
                {isThuLienHe && (
                  <Col lg={5} md={24} sm={24} xs={24}>
                    <SelectField
                      label="Admin tư vấn"
                      placeholder="Admin tư vấn"
                      options={listChuyengia?.data?.data?.map((e: any) => ({
                        key: e.id,
                        value: e.name,
                      }))}
                      allowClear={true}
                      control={control}
                      name="admin"
                      listHeight={160}
                      style={{ minWidth: "120px" }}
                    />
                  </Col>
                )}
                {isThuLienHe && (
                  <Col lg={5} md={24} sm={24} xs={24}>
                    <SelectField
                      label="Đăng ký chuyên gia"
                      placeholder="Đăng ký chuyên gia"
                      options={dk_chuyen_gia_option}
                      control={control}
                      name="dk_chuyen_gia"
                      listHeight={160}
                      allowClear={true}
                      style={{ minWidth: "120px" }}
                    />
                  </Col>
                )}
              </Row>}
            </Col>
            <Col xl={5} md={24}>
              <Row gutter={[20, 10]} justify="end">
                {filter && <Col>
                  <Button
                    htmlType="submit"
                    className="primary_btn"
                    onClick={handleSubmit(onFilter)}
                  >
                    Lọc
                  </Button>
                </Col>}
                {download && (
                  <Col>
                    <Button
                      className="export_btn"
                      onClick={() => setExport(true)}
                      loading={exportData?.isLoading || exportData?.isFetching}
                    >
                      Export Excel
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
        <TableWrap
          loading={isLoading || isFetching}
          columns={[...columns, ...columnsDefault]}
          isShowTotal
          dataSource={data?.data?.source}
          rowKey="_id"
          bordered
          pagination={{
            pageSize: limit,
            current: currentPage,
            total: data?.data?.totalItems,
            onChange: (page: number, pageSize: number) => {
              setCurrentPage(page);
              setLimit(pageSize);
            },
          }}
          className="hover table-slide"
          hasDefaultColumn={false}
        />
      </>
    </Card>
  );
};

export default RegisterCommon;

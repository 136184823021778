import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import sliderApi from "../../../../api/sliderApi";
import SliderList from "../../../../components/SliderList";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const SliderGuest = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(sliderApi.deleteGuestSlider, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("guest-slider");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Slider khách hàng"
      content={
        <SliderList
          headContent="Slider khách hàng"
          handleRemove={(e) => mutation.mutate(e)}
          keyQuery="guest-slider"
          link="/slider/khach-hang"
          query={sliderApi.getGuestSlider}
          visible={false}
          column={[
            {
              title: "Hình ảnh",
              dataIndex: "photo",
              width: "60%",
              key: "photo",
              className: "td-slider-picture",
              render: (record: string) => {
                return (
                  <img
                    src={`${process.env.REACT_APP_DOMAIN}${record}`}
                    alt=""
                    className="slider-picture"
                  ></img>
                );
              },
            },
            {
              title: "Tên",
              dataIndex: "ten_vi",
              width: "25%",
              key: "ten_vi",
            },
          ]}
        />
      }
    />
  );
};

export default SliderGuest;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import libraryApi from "../../../../api/libraryApi";
import CheckboxSlider from "../../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const CategoryLibrary = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching }: any = useQuery(
    ["library_categories"],
    libraryApi.getListCate
  );

  const mutation = useMutation(libraryApi.deleteCate, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("library_categories");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationEdit = useMutation(libraryApi.editCate, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("library_categories");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Tiêu đề",
      dataIndex: "ten_vi",
      key: "ten_vi",
    },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      align: "center",
      width: "8%",
      key: "hienthi",
      render: (record: boolean, data: any) => {
        return (
          <CheckboxSlider
            _id={data?._id}
            check={record}
            dataName="hienthi"
            action={mutationEdit}
          />
        );
      },
    },
    {
      title: "Sửa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.LIBRARY.CATEGORIES.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <PageWrap
      title="Thư viện chỉ báo"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục</b>
                </Col>
                <Col>
                  <Button
                    onClick={() => navigate(appRoutes.LIBRARY.CATEGORIES.ADD)}
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data}
              rowKey="_id"
              bordered
              pagination={false}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </div>
      }
    />
  );
};

export default CategoryLibrary;

import { callApi } from "./axiosClient";

const register = "dang-ky";
const sign = "tin-hieu-telegram";

const signApi = {
  async getAll(
    page: number,
    page_size: number,
    email?: string,
    ngaytao?: string,
    name?: string,
    phone?: string,
    status?: number
  ) {
    const url = `${sign}/${register}?page=${page}&page_size=${page_size}${
      email ? "&email=" + email : ""
    }${name ? "&ten=" + name : ""}${ngaytao ? "&ngaytao=" + ngaytao : ""}${
      phone ? "&dienthoai=" + phone : ""
    }${status ? "&trangthai=" + status : ""}`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined) {
    const url = `${sign}/${register}/${id}`;
    return callApi("GET", url);
  },
  async updateStatus({
    id,
    status,
  }: {
    id: string | undefined;
    status: number | undefined;
  }) {
    const url = `${sign}/${register}/${id}`;
    return callApi("POST", url, {
      trangthai: status,
    });
  },
  async delete(id: string) {
    const url = `${sign}/${register}/${id}`;
    return callApi("DELETE", url);
  },
  async download() {
    const url = `${sign}/download`;
    const headers: any = { responseType: "arraybuffer" };
    const response: any = await callApi("GET", url, null, headers);
    const result =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      Buffer.from(response, "binary").toString("base64");
    const aElement = document.createElement("a");
    aElement.href = result;
    aElement.download = "NhanTinHieu.xlsx";
    aElement.click();
    return result;
  },
};

export default signApi;

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
export const formItemLayoutSm = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 16 },
  },
};
export const formItemLayoutSEO = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 3 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 20 },
  },
};
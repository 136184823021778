import { callApi } from "./axiosClient";

const coupon = "ma-giam-gia";
const discount = "giam-gia";

const couponApi = {
  async getAll(
    page: number,
    page_size: number,
    code?: string,
    discount_number?: number,
    active?: boolean | string,
    hethan?: boolean | string,
    hetnguoidung?: boolean | string
  ) {
    const url = `${discount}/${coupon}?page=${page}&page_size=${page_size}${
      code ? "&code=" + code : ""
    }${discount_number ? "&discount=" + discount_number : ""}${
      active !== undefined && active !== "0" ? "&active=" + active : ""
    }${hethan !== undefined && hethan !== "0" ? "&hethan=" + hethan : ""}${
      hetnguoidung !== undefined && hetnguoidung !== "0"
        ? "&hetnguoidung=" + hetnguoidung
        : ""
    }`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined) {
    const url = `${discount}/${coupon}/${id}`;
    return callApi("GET", url);
  },
  async add(data: any) {
    const url = `${discount}/${coupon}`;
    return callApi("POST", url, data);
  },
  async edit({ id, data }: { id: string | undefined; data: any }) {
    const url = `${discount}/${coupon}/${id}`;
    return callApi("PUT", url, data);
  },
  async delete(id: string) {
    const url = `${discount}/${coupon}/${id}`;
    return callApi("DELETE", url);
  },
  async download() {
    const url = `${discount}/download`;
    const headers: any = { responseType: "arraybuffer" };
    const response: any = await callApi("GET", url, null, headers);
    const result =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      Buffer.from(response, "binary").toString("base64");
    const aElement = document.createElement("a");
    aElement.href = result;
    aElement.download = "MaGiamGia.xlsx";
    aElement.click();
    return result;
  },
};

export default couponApi;

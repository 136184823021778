import { callApi } from "./axiosClient";

const setting = "thiet-lap";

const systemSettingApi = {
  async getSystemSetting() {
    const url = "system/general";
    return callApi("GET", url);
  },
  async editSystemSetting(data: any) {
    const url = `${setting}/setting`;
    return callApi("PUT", url, data);
  },
  async getGuideSetting() {
    const url = `${setting}/huong-dan-chuyen-khoan`;
    return callApi("GET", url);
  },
  async editGuideSetting(data: any) {
    const url = `${setting}/huong-dan-chuyen-khoan`;
    return callApi("PUT", url, data);
  },
  async getSeoSetting() {
    const url = `${setting}/seo`;
    return callApi("GET", url);
  },
  async editSeoSetting({ id, data }: { id: string; data: any }) {
    const url = `${setting}/seo/${id}`;
    return callApi("PUT", url, data);
  },
};

export default systemSettingApi;

import { ConsoleSqlOutlined, DeleteOutlined } from "@ant-design/icons";
import "./style.scss";

import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  message,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import imptApi from "../../api/imptApi";
import DateTimePicker from "../../components/DateTimePicker";
import Popup from "../../components/Popup";
import SelectField from "../../components/SelectField";
import TableWrap from "../../components/Table";
import TopContent from "../../components/TopContent";
import Waiting from "../../components/Waiting";
import PageWrap from "../../layout/PageWrap";
import { messages } from "../../utils/messages";
import ModalImport from "./ModalImport";
import ModalImportAuto from "./ModalImportAuto";
import { LoadingOutlined } from "@ant-design/icons";
import ModalImportProcess from "./ModalProcessAuto";

const options = [
  {
    key: 1,
    value: "Tự động",
  },
  {
    key: 2,
    value: "Thủ công",
  },
];
const Import = () => {
  const queryClient = useQueryClient();
  const [date, setDate]: any = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [key, setKey] = useState();
  const [type, setType]: any = useState();
  const [open, setOpen] = useState(false);
  const [openAuto, setOpenAuto] = useState(false);
  const [openAutoProcess, setOpenAutoProcess] = useState(false);
  const [show, setShow] = useState(false);
  const [importing, setImporting] = useState(true);
  const [interval, sInterval] = useState(false);
  const [dataProcess, setDataProcess] = useState([]);
  const [messageProcess, setMessage] = useState("");

  const { handleSubmit, control, setValue } = useForm();

  const { data, isLoading, isFetching, refetch }: any = useQuery(
    ["import_history", date, key, type],
    () => imptApi.getHistory({ key: key, time: date, isAuto: type })
  );
  const listKeys: any = useQuery("import_keys", imptApi.getListKeys);

  const mutation = useMutation(imptApi.delHistory, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("import_history");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const mutationHuyImport = useMutation(imptApi.cancelImport, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success("Hủy import thành công.");
        queryClient.invalidateQueries("import_history");
        setImporting(false);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {},
  });

  const getStatus = useQuery("getStatusImport", imptApi.getStatusImport, {
    enabled: importing,
    refetchInterval: 5000,
  });

  useEffect(() => {
    let intervalId: any;
    if (interval) {
      intervalId = setInterval(() => {
        setImporting(true);
      }, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);

  useEffect(() => {
    if (getStatus.data?.statusCode === 200) {
      if (getStatus.data?.data?.imported) {
        sInterval(true);
        setDate(moment().format("DD/MM/YYYY"));
        refetch();
      } else {
        sInterval(false);
        setDate(moment().format("DD/MM/YYYY"));
        refetch();
      }
      setDataProcess(getStatus.data?.data?.data);
      setMessage(getStatus.data?.data?.message ?? "");
    } else {
      sInterval(false);
    }
    setImporting(false);
  }, [getStatus.data]);

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      width: "3%",
      align: "center",
      key: "index",
      render: (_, record: any) => <>{data?.data?.indexOf(record) + 1}</>,
    },
    {
      title: "Ngày import",
      dataIndex: "time",
      key: "time",
      width: "12%",
      render: (record: string) => (
        <>{moment(record).format("HH:mm DD/MM/YYYY")}</>
      ),
    },
    {
      title: "Kiểu",
      dataIndex: "isAuto",
      key: "isAuto",
      width: "10%",
      render: (record: any) => <>{record ? "Tự động" : "Thủ công"}</>,
    },
    {
      title: "Loại",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Data được import",
      dataIndex: "number",
      key: "number",
      width: "10%",
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      width: "20%",
      render: (record: string) => {
        return (
          <a
            download={record?.split("/")?.reverse()[0]}
            href={process.env.REACT_APP_DOMAIN + record}
          >
            {record?.split("/")?.reverse()[0]}
          </a>
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const onFilter = (dt: any) => {
    setDate(
      dt?.date
        ? moment(dt.date).format("DD/MM/YYYY")
        : moment().format("DD/MM/YYYY")
    );
    setKey(dt.key);
    if (dt.type) {
      setType(dt.type === 1);
    } else {
      setType(undefined);
    }
  };
  const optionsType = (listKeys?.data?.data ?? []).map((e: any) => {
    return {
      key: e?.key,
      value: e?.data,
    };
  });

  return (
    <>
      <PageWrap
        title="Import"
        content={
          <div className="slider_list">
            <TopContent
              content={
                <Row>
                  <Col>
                    <b>Import</b>
                  </Col>

                  <Col>
                    <div className="import_right">
                      {interval && (
                        <Alert
                          message="Đang import"
                          showIcon
                          description={messageProcess}
                          type="warning"
                          className="import_right_alert"
                          action={
                            <div className="import_right_wrap">
                              <Button
                                size="small"
                                onClick={() => setOpenAutoProcess(true)}
                              >
                                Chi tiết
                              </Button>
                              <Button
                                size="small"
                                danger
                                onClick={() => mutationHuyImport.mutate()}
                              >
                                Hủy import
                              </Button>
                            </div>
                          }
                        />
                      )}
                      <div className="import_right_button">
                        <Button onClick={() => setOpenAuto(true)}>
                          Import Tự động
                        </Button>
                        <Button onClick={() => setOpen(true)}>
                          Import Thủ công
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              }
            />
            <Card bordered={false} className="register_common">
              <>
                <Form className="register_common__form">
                  <Row
                    style={{ marginBottom: "30px" }}
                    align="bottom"
                    gutter={[20, 10]}
                  >
                    <Col lg={4} md={6} sm={24}>
                      <Controller
                        name="date"
                        control={control}
                        render={({ field }) => (
                          <DateTimePicker
                            defaultValue={moment().format("DD/MM/YYYY")}
                            props={{
                              ...field,
                              onBlur: (e: any) => {
                                if (e.target.value === "")
                                  setValue("date", undefined);
                                else if (
                                  moment(
                                    e.target.value,
                                    "DD/MM/YYYY",
                                    true
                                  )?.isValid()
                                )
                                  setValue(
                                    "date",
                                    moment(e.target.value, "DD/MM/YYYY")
                                  );
                                else setValue("date", moment());
                              },
                            }}
                            label="Ngày import"
                            allowClear={false}
                            action={handleSubmit(onFilter)}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={24}>
                      <SelectField
                        label="Kiểu import"
                        placeholder="Kiểu import"
                        options={options}
                        control={control}
                        name="type"
                        allowClear={true}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={24}>
                      <SelectField
                        label="Loại import"
                        placeholder="Loại import"
                        options={optionsType}
                        control={control}
                        name="key"
                        allowClear={true}
                        listHeight={224}
                      />
                    </Col>
                    <Col lg={12} md={6} sm={24}>
                      <Row justify="end">
                        <Button
                          htmlType="submit"
                          className="primary_btn"
                          onClick={handleSubmit(onFilter)}
                        >
                          Lọc
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                <TableWrap
                  loading={isLoading || isFetching}
                  columns={columns}
                  isShowTotal
                  dataSource={data?.data}
                  rowKey="_id"
                  bordered
                  pagination={false}
                  className="hover table-slide"
                  hasDefaultColumn={false}
                />
              </>
            </Card>
          </div>
        }
      />
      <Popup
        show={open}
        title="Import thủ công"
        handleClose={() => setOpen(false)}
        content={
          <ModalImport
            options={listKeys?.data?.data}
            open={(e: any) => setOpen(e)}
            show={(e: any) => setShow(e)}
          />
        }
      />
      <Popup
        show={openAuto}
        title="Import tự động"
        handleClose={() => setOpenAuto(false)}
        content={
          <ModalImportAuto
            open={(e: any) => setOpenAuto(e)}
            show={(e: any) => setShow(e)}
            importSuccess={() => setImporting(true)}
          />
        }
      />
      <Popup
        show={openAutoProcess}
        title="Tiến trình import"
        handleClose={() => setOpenAutoProcess(false)}
        content={<ModalImportProcess data={dataProcess} />}
      />
      <Waiting show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export default Import;

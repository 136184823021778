import { Button, Col, Form, message, Row } from "antd";
import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import imptApi from "../../../api/imptApi";
import InputField from "../../../components/InputField";
import TextAreaField from "../../../components/TextAreaField";
import { messages } from "../../../utils/messages";
import "./style.scss";

interface ModalImportAutoProps {
  open: (e: any) => void;
  show: (e: any) => void;
  importSuccess: () => void;
}

const ModalImportAuto: FC<ModalImportAutoProps> = ({ open, show, importSuccess }) => {
  const queryClient = useQueryClient();
  const [msgToken, setMsgToken]: any = useState("");
  const [tokenValid, setTokenValid]: any = useState(false);
  const [dataHelp, setDataHelp]: any = useState({});

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const { data }: any = useQuery("import_saveKeys", imptApi.getSaveKeys);

  useEffect(() => {
    if (data?.statusCode == 200) {
      setValue("linkdrive", data.data?.linkdrive);
      setValue("help", data.data?.help);
      setValue("token", data.data?.token);
      setDataHelp(data.data);
    }
  }, [data]);

  const mutation = useMutation(imptApi.importFileAuto, {
    onSuccess: (data: any) => {
      if ((data?.data?.statusCode == 500 && data?.data?.message == "Cannot define the same handler twice __default__") || data?.statusCode === 200 || !data) {
        message.success(messages.importSuccess);
        queryClient.invalidateQueries("import_history");
        setValue("token", "");
        setMsgToken("");
        show(false);
        importSuccess();
      } else {
        show(false);
        open(true);
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      show(false);
      open(true);
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const checkToken = useMutation(imptApi.checkToken, {
    onSuccess: (data: any) => {
      if (data?.statusCode === 200) {
        const isNumeric = (num: any) =>
          (typeof num === "number" ||
            (typeof num === "string" && num.trim() !== "")) &&
          !isNaN(num as number);
        if (isNumeric(data?.data)) {
          setMsgToken(
            "Token còn hạn khoảng " +
              Math.round(Number(data?.data) / 60) +
              " phút"
          );
          setTokenValid(true);
        } else {
          setMsgToken("Token hết hạn");
          setTokenValid(false);
        }
      } else {
        setMsgToken(data?.data?.message ?? messages.error);
        setTokenValid(false);
      }
    },
    onError: (err: any) => {
      setMsgToken(err?.data?.message ?? messages.error);
      setTokenValid(false);
    },
  });
  const onSubmit = (dt: any) => {
    open(false);
    show(true);
    mutation.mutate(dt);
  };

  return (
    <Form className="slider-form form_import" layout="vertical">
      <TextAreaField
        label="Link"
        control={control}
        rules={{
          required: "Vui lòng nhập link",
        }}
        rows={2}
        name="linkdrive"
        errorMsg={errors?.linkdrive?.message}
        defaultValue={dataHelp?.help}
        placeholder="Nhập link của thư mục chứa file import"
      />
      <div className="import_auto">
        <p>Token</p>
        <div>
          <a
            type="primary"
            href={dataHelp?.help}
            rel="noreferrer"
            target="_blank"
          >
            Hướng dẫn lấy token
          </a>
          <a
            type="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              if (
                getValues("token") === undefined ||
                getValues("token").length === 0
              ) {
                setMsgToken("Bạn chưa nhập token");
                setTokenValid(false);
                return;
              }
              checkToken.mutate(getValues("token"));
            }}
          >
            Kiểm tra token
          </a>
        </div>
      </div>
      <div className="import_auto"></div>
      <TextAreaField
        name="token"
        rules={{
          required: "Vui lòng nhập token",
        }}
        errorMsg={msgToken.length > 0 ? msgToken : errors?.token?.message}
        control={control}
        rows={3}
        defaultValue={dataHelp?.token}
        label=""
        placeholder="Nhập token hợp lệ"
      />

      <Row justify="center">
        <Col>
          <Button
            type="primary"
            danger
            onClick={handleSubmit(onSubmit)}
            className="import_auto_button"
          >
            Import
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalImportAuto;

import { callApi } from "./axiosClient";

const order = "don-hang";

const orderApi = {
  async getServices({ isGlobal }: { isGlobal: Boolean }) {
    const url = `${order}/goi-dich-vu${isGlobal? "/global" : ""}`;
    return callApi("GET", url);
  },
  async getAll(
    isGlobal: Boolean,
    page: number,
    page_size: number,
    code?: string,
    email?: string,
    hoten?: string,
    dienthoai?: string,
    ngaytao?: string,
    trangthai?: number
  ) {
    const url = `${order}/${isGlobal ? 'don-hang-global' : order}?page=${page}&page_size=${page_size}${
      code ? "&code=" + code : ""
    }${email ? "&email=" + email : ""}${hoten ? "&hoten=" + hoten : ""}${
      dienthoai ? "&dienthoai=" + dienthoai : ""
    }${ngaytao ? "&ngaytao=" + ngaytao : ""}${
      trangthai ? "&trangthai=" + trangthai : ""
    }`;
    return callApi("GET", url);
  },
  async getDetails(id: string | undefined, isGlobal: Boolean,) {
    const url = `${order}/${isGlobal ? 'don-hang-global' : order}/${id}`;
    return callApi("GET", url);
  },
  async edit({ id, data, isGlobal, }: { id: string | undefined; data: any, isGlobal: Boolean }) {
    const url = `${order}/${isGlobal ? 'don-hang-global' : order}/${id}`;
    return callApi("PUT", url, data);
  },
  async delete({ id, isGlobal }: { id: string | undefined; isGlobal: Boolean }) {
    const url = `${order}/${isGlobal ? 'don-hang-global' : order}/${id}`;
    return callApi("DELETE", url);
  },
  async download(isGlobal: Boolean) {
    const url = `${order}/download${isGlobal ? "/global" : ""}`;
    const headers: any = { responseType: "arraybuffer" };
    const response: any = await callApi("GET", url, null, headers);
    const result =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      Buffer.from(response, "binary").toString("base64");
    const aElement = document.createElement("a");
    aElement.href = result;
    aElement.download = "DanhSachDonHang.xlsx";
    aElement.click();
    return result;
  },
};

export default orderApi;

import { Button, Card, Form, message, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import systemSettingApi from "../../../api/systemSettingApi";
import InputField from "../../../components/InputField";
import Loading from "../../../components/Loading";
import TextAreaField from "../../../components/TextAreaField";
import { formItemLayoutSm } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
interface SeoContentProps {
  keySeo: string;
  index: number;
}

const SeoContent: FC<SeoContentProps> = ({ keySeo, index }) => {
  const { data, isLoading, isFetching }: any = useQuery(
    ["setting", "seo"],
    systemSettingApi.getSeoSetting
  );

  const { handleSubmit, control, reset } = useForm();

  const mutation = useMutation(systemSettingApi.editSeoSetting, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const [content, setContent]: any = useState();
  const onSubmit = (data: any) => {
    mutation.mutate({ id: keySeo, data: data });
  };
  useEffect(() => {
    if (data?.data?.length > 0) {
      const dt: any = data?.data?.filter((e: any) => e.page === keySeo);
      if (dt.length === 1) setContent(dt?.[0]);
    }
  }, [data]);
  useEffect(() => {
    reset();
  }, [index]);

  return (
    <>
      {isLoading || isFetching || !content ? (
        <div className="setting-loading">
          <Loading />
        </div>
      ) : (
        <Card bordered={false}>
          <Form {...formItemLayoutSm}>
            <TextAreaField
              name="desPage"
              control={control}
              rows={3}
              defaultValue={content?.desPage}
              label="Mô tả page"
              placeholder="Mô tả page"
            />
            <InputField
              name="title"
              control={control}
              label="Title"
              placeholder="Title"
              defaultValue={content?.title}
            />
            <TextAreaField
              name="keyword"
              control={control}
              rows={3}
              defaultValue={content?.keyword}
              label="Keywords"
              placeholder="Keywords"
            />
            <TextAreaField
              name="description"
              control={control}
              rows={3}
              defaultValue={content?.description}
              label="Description"
              placeholder="Description"
            />
            <Row justify="end">
              <Form.Item>
                <Button type="primary" danger onClick={handleSubmit(onSubmit)}>
                  Cập nhật
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};

export default SeoContent;

import { Button, Card, Col, Form, message, Radio, Row } from "antd";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import notificationApi from "../../../../api/notificationApi";
import orderApi from "../../../../api/orderApi";
import DateTimePicker from "../../../../components/DateTimePicker";
import InputField from "../../../../components/InputField";
import Loading from "../../../../components/Loading";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface NotificationFormProps {
  type: number;
}

const NotificationForm: FC<NotificationFormProps> = ({ type }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [role, setRole]: any = useState(-1);

  const { data, isLoading, isFetching } = useQuery(
    ["notification_general", id],
    () => notificationApi.getDetailsGeneralNotification(id),
    {
      enabled: type === 2,
    }
  );
  const listUser = useQuery("listUser", () =>
    orderApi.getServices({ isGlobal: false })
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const mutationEdit = useMutation(notificationApi.editGeneralNotification, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("notification_general");
        navigate(appRoutes.NOTIFICATION.GENERAL.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(notificationApi.addGeneralNotification, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("notification_general");
        navigate(appRoutes.NOTIFICATION.GENERAL.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    if (type === 1) mutationAdd.mutate({ ...dt, roles: [role] });
    else mutationEdit.mutate({ id: id, data: { ...dt, roles: [role] } });
  };

  useEffect(() => {
    if (data) setRole(data?.data?.roles[0]);
  }, [data]);

  return (
    <PageWrap
      title="Thông báo hệ thống"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Thông báo hệ thống</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  <InputField
                    label="Tiêu đề"
                    name="title"
                    placeholder="Tiêu đề"
                    control={control}
                    defaultValue={data?.data?.title}
                    setValue={setValue}
                    errorMsg={errors?.title?.message}
                    rules={{
                      required: "Vui lòng nhập tiêu đề",
                    }}
                  />
                  <TextAreaField
                    label="Nội dung"
                    name="message"
                    placeholder="Nội dung"
                    control={control}
                    defaultValue={data?.data?.message}
                    errorMsg={errors?.message?.message}
                    rules={{
                      required: "Vui lòng nhập nội dung",
                    }}
                  />
                  <Controller
                    name="time"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        dateFormat="DD-MM-YYYY HH:mm"
                        defaultValue={moment(data?.data?.time ?? new Date(), "YYYY-MM-DD HH:mm:ss")}
                        props={{
                          ...field,
                          onBlur: (e: any) => {
                            if (e.target.value === "")
                              setValue("time", undefined);
                            else if (
                              moment(
                                e.target.value,
                                "DD-MM-YYYY HH:mm",
                                true
                              )?.isValid()
                            )
                              setValue(
                                "time",
                                moment(e.target.value, "DD-MM-YYYY HH:mm")
                              );
                            else setValue("time", moment());
                          },
                        }}
                        label="Thời gian thực hiện"
                        allowClear={true}
                      />
                    )}
                  />
                  <Form.Item label={<>&nbsp;</>} colon={false}>
                    <Radio.Group
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    >
                      <Radio value={-1}>Tất cả</Radio>
                      {listUser?.data?.data?.map((each: any) => (
                        <Radio key={each?.id} value={each?.id}>
                          {each?.title}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.NOTIFICATION.GENERAL.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default NotificationForm;

import { Tabs } from "antd";
import { useState } from "react";
import { useQueryClient } from "react-query";
import PageWrap from "../../layout/PageWrap";
import GuideContent from "./GuideContent";
import SeoContent from "./SeoContent";
import SettingContent from "./SettingContent";

const SystemSetting = () => {
  const queryClient = useQueryClient();
  const [idx, setIdx] = useState(0);
  const labels = [
    "Website setting",
    "Hướng dẫn chuyển khoản",
    "SEO Cơ hội đầu tư",
    "SEO Thư viện chỉ báo",
    "SEO Trading blog",
  ];

  const getLayout = (index: number) => {
    switch (index) {
      case 0:
        return <SettingContent index={idx} />;
      case 1:
        return <GuideContent index={idx} />;
      case 2:
        return <SeoContent index={idx} keySeo="CO_HOI_DAU_TU" />;
      case 3:
        return <SeoContent index={idx} keySeo="THU_VIEN_CHI_BAO" />;
      case 4:
        return <SeoContent index={idx} keySeo="TRADING_BLOG" />;
      default:
        break;
    }
  };

  return (
    <PageWrap
      title="Website setting"
      content={
        <div className="content">
          <Tabs
            type="card"
            onChange={(e: any) => {
              setIdx(e);
              queryClient.invalidateQueries([
                "setting",
                e === 0 ? "general" : e === 1 ? "guide" : "seo",
              ]);
            }}
            items={labels.map((item: any, i: any) => {
              return {
                label: item,
                key: i,
                children: getLayout(i),
              };
            })}
          />
        </div>
      }
    />
  );
};

export default SystemSetting;

import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import contentApi from "../../../../api/contentApi";
import ListContent from "../../../../components/ListContent";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const TradingThucChienList = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(contentApi.deleteTradingThucChien, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("trading_thuc_chien");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  return (
    <PageWrap
      title="Trading thực chiến"
      content={
        <ListContent
          headContent="Trading thực chiến"
          handleRemove={(e) => mutation.mutate(e)}
          action={useMutation(contentApi.editTradingThucChien, {
            onSuccess: (data: any) => {
              if (data.statusCode === 200) {
                message.success(messages.updateSuccess);
                queryClient.invalidateQueries("trading_thuc_chien");
              }
            },
            onError: (err: any) => {
              message.error(messages.error);
            },
          })}
          keyQuery="trading_thuc_chien"
          link="/noi-dung-bai-viet/trading-thuc-chien"
          query={contentApi.getTradingThucChien}
        />
      }
    />
  );
};

export default TradingThucChienList;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import telegramApi from "../../../../api/telegramApi";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const ListChannels = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching }: any = useQuery(
    ["telegram", "list-channel"],
    telegramApi.getChannel
  );
  const mutation = useMutation(telegramApi.deleteChannel, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(["telegram", "list-channel"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const handleRemove = (e: any) => {
    mutation.mutate(e);
  };

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: 50,
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Hình ảnh",
      dataIndex: "photo",
      key: "photo",
      width: "20%",
      className: "td-slider-picture",
      render: (record: string) => {
        return (
          <img
            src={`${process.env.REACT_APP_DOMAIN}${record}`}
            alt=""
            className="slider-picture"
          ></img>
        );
      },
    },
    {
      title: "Tiêu đề",
      dataIndex: "mota",
      key: "mota",
      render: (record: string) => (
        <div
          dangerouslySetInnerHTML={{
            __html: record?.split("\r\n").join("<br/>"),
          }}
        ></div>
      ),
    },
    {
      title: "Sửa",
      align: "center",
      width: 90,
      key: "sua",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.TELEGRAM.CHANNELS.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: 90,
      key: "xoa",
      render: (record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={() => handleRemove(record._id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <PageWrap
      title="Kênh"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Kênh</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(`${appRoutes.TELEGRAM.CHANNELS.ADD}`)
                    }
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content ">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data}
              rowKey="_id"
              bordered
              pagination={false}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </>
      }
    />
  );
};

export default ListChannels;

import { Button, Col, Form, message, Progress, Row } from "antd";
import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import imptApi from "../../../api/imptApi";
import InputField from "../../../components/InputField";
import TextAreaField from "../../../components/TextAreaField";
import { messages } from "../../../utils/messages";
import "./style.scss";

interface ModalImportAutoProps {
  data: any[];
}

const ModalImportProcess: FC<ModalImportAutoProps> = ({ data }) => {
  return (
    <>
      {(data ?? []).map((item: any, index: number) => (
        <div>
          <p>{item.file}</p>
          <Progress percent={item.progress} status="active" />
        </div>
      ))}
    </>
  );
};

export default ModalImportProcess;

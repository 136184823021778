import { callApi } from "./axiosClient";

const systemApi = {
  async getNoti() {
    const url = "system/notseen";
    return callApi("GET", url);
  },
};

export default systemApi;

import PageTopWrapper from "../../../components/page-top-wrapper";
import TableWrap from "../../../components/Table";
import { useQuery } from "react-query";
import { FC, useState, useEffect } from "react";
import registerApi from "../../../api/registerApi";
import { ColumnsType } from "antd/lib/table";

const ListHocVien = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(9);

  const { data, isLoading, isFetching, refetch }: any = useQuery(
    ["list_hoic_vien", currentPage, limit],
    () => registerApi.getAll(currentPage, limit)
  );
  const columns: ColumnsType<any> = [
    {
      title: "#",
      width: "3%",
      align: "center",
      dataIndex: "index",
      key: "index",
    },

    {
      title: "Họ tên",
      dataIndex: "ten",
      key: "ten",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienthoai",
      key: "dienthoai",
    },
    {
      title: "Lớp học",
      align: "center",
    },
    {
      title: "Khóa học",
      key: "membership",
    },
    {
      title: "Ghi chú nhu cầu",
      width: "20%",
    },
  ];

  return (
    <div className="div-status-chart">
      <PageTopWrapper leftContent={<h3>DANH SÁCH HỌC VIÊN</h3>} />

      <TableWrap
        loading={isLoading || isFetching}
        columns={columns}
        isShowTotal
        dataSource={data?.data?.source}
        rowKey="_id"
        bordered
        pagination={{
          pageSize: limit,
          current: currentPage,
          total: data?.data?.totalItems,
          onChange: (page: number, pageSize: number) => {
            setCurrentPage(page);
            setLimit(pageSize);
          },
          hideOnSinglePage: true, // ẩn phân trang khi chỉ có 1 trang
          showSizeChanger: false,
        }}
        className="hover table-slide"
        hasDefaultColumn={false}
      />
    </div>
  );
};

export default ListHocVien;

import { Button, Card, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import telegramApi from "../../../../api/telegramApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { loadFile } from "../../../../utils/functions/loadFile";
import { messages } from "../../../../utils/messages";

interface ChannelFormProps {
  type: number;
}

const ChannelForm: FC<ChannelFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [file, setFile]: any = useState();

  const { data, isLoading }: any = useQuery(
    ["telegram-channel", id],
    () => telegramApi.getChannelDetails(id),
    {
      enabled: !!id && type === 2,
    }
  );
  const listCate: any = useQuery(
    ["telegram", "list-cate"],
    telegramApi.getCate
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [image, setImage]: any = useState();
  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };
  const mutationEdit = useMutation(telegramApi.editChannel, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("telegram-channel");
        navigate(appRoutes.TELEGRAM.CHANNELS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(telegramApi.addChannel, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success("Tạo thành công.");
        await queryClient.invalidateQueries("telegram-channel");
        navigate(appRoutes.TELEGRAM.CHANNELS.LIST);
        window.scrollTo(0, 0);
      } else message.error("Lỗi. Vui lòng thử lại sau.");
    },
  });
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("hinhanh", file);
    formData.set("danhmuc", dt.danhmuc ?? "");
    formData.set("mota", dt.mota ?? "");
    formData.set("link", dt.link ?? "");
    formData.set("index", dt.index ?? "");
    if (type === 2) mutationEdit.mutate({ id: id, data: formData });
    if (type === 1) mutationAdd.mutate(formData);
  };

  return (
    <PageWrap
      title="Kênh"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Kênh</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || listCate?.isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  {(image || data?.data?.photo) && (
                    <div className="cash_img">
                      <img
                        src={
                          image ??
                          process.env.REACT_APP_DOMAIN + data?.data?.photo
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <InputField
                    isImage={true}
                    name="hinhanh"
                    control={control}
                    rules={
                      type === 1
                        ? {
                            required: "Vui lòng chọn hình ảnh",
                          }
                        : undefined
                    }
                    label="Hình ảnh"
                    errorMsg={errors?.hinhanh?.message}
                    onChange={(e: any) => {
                      handleUpload(e.target.files?.[0]);
                    }}
                  />
                  <SelectField
                    control={control}
                    label="Chuyên mục"
                    name="danhmuc"
                    options={listCate?.data?.data?.map((e: any) => ({
                      key: e._id,
                      value: e.ten_vi,
                    }))}
                    defaultValue={data?.data?.danhmuc}
                    errorMsg={errors?.danhmuc?.message}
                    rules={{
                      required: "Vui lòng chọn chuyên mục",
                    }}
                  />
                  <TextAreaField
                    label="Mô tả"
                    name="mota"
                    control={control}
                    defaultValue={data?.data?.mota}
                    errorMsg={errors?.mota?.message}
                    rules={{
                      required: "Vui lòng nhập mô tả",
                    }}
                  />
                  <InputField
                    label="Link"
                    name="link"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.link}
                  />

                  <InputNumberWrap
                    label="STT"
                    name="index"
                    control={control}
                    defaultValue={data?.data?.index ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("index", 1);
                    }}
                    min={1}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.TELEGRAM.CHANNELS.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default ChannelForm;

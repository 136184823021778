import PageWrap from "../../layout/PageWrap";
import { useEffect, useState } from "react";
import HomeMain from "./phanquyen";
import HomeEditor from "./phanquyen/editor";
const Home = () => {
  const [data, setData]: any = useState();

  useEffect(() => {
    let cache: any = sessionStorage.getItem("profile");
    if (cache) setData(JSON.parse(cache));
  }, [sessionStorage?.getItem("profile")]);

  return (
    <PageWrap
      title="Manage"
      content={
        (data?.role === 3 || data?.role === 6) ? <HomeMain></HomeMain> : <HomeEditor></HomeEditor>
      }
    />
  );
};

export default Home;

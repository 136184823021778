import { Avatar, Button, Col, Dropdown, Row, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { appRoutes } from "../../routes/routes";
import { FC, useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import "./style.scss";

interface HeaderProps {
  handleClick: () => void;
}

const HeaderLayout: FC<HeaderProps> = ({ handleClick }) => {
  const navigate = useNavigate();
  const [data, setData]: any = useState();

  useEffect(() => {
    let cache: any = sessionStorage.getItem("profile");
    if (cache) setData(JSON.parse(cache));
  }, [sessionStorage?.getItem("profile")]);

  const items: any = [
    { label: <Link to="/setting-profile">Cài đặt tài khoản</Link>, key: "1" },
    {
      label: (
        <div
          onClick={() => {
            sessionStorage.clear();
            navigate(appRoutes.AUTH.LOGIN);
          }}
        >
          Đăng xuất
        </div>
      ),
      key: "2",
    },
  ];

  return (
    <div className="header-wrap">
      <Col span={24} className="header-right">
        <div className="btnMenu visible-xs" onClick={handleClick}>
          <i className="fa fa-bars"></i>
        </div>
        <Row justify="end">
          <Typography.Text>{data?.ten}</Typography.Text>
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <div className="cursor_pointer">
              <Avatar icon={<UserOutlined />} size={45} />
            </div>
          </Dropdown>
        </Row>
      </Col>
    </div>
  );
};

export default HeaderLayout;

import { DatePicker, Form } from "antd";
import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { CalendarOutlined } from "@ant-design/icons";
import ErrorMessage from "../ErrorMessage";
import "./style.scss";
const { RangePicker } = DatePicker;

interface IDateTimePicker {
  label: string | any;
  name?: string;
  required?: boolean;
  message?: string;
  dateFormat?: string;
  defaultValue?: any;
  allowClear?: boolean;
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year" | undefined;
  className?: string;
  placeholder?: string;
  disable?: boolean;
  props?: any;
  isError?: boolean;
  disabledDate?: any;
  action?: () => void;
  onChange?: (value: any) => void
  isRange?: boolean;
  errorMsg?: any;
}

const DateTimePicker: FC<IDateTimePicker> = ({
  label,
  name,
  required,
  message,
  dateFormat = "DD/MM/YYYY",
  defaultValue,
  allowClear,
  picker,
  className,
  placeholder,
  disable = false,
  props,
  isError,
  disabledDate,
  isRange = false,
  action,
  onChange,
  errorMsg,
}) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [press, setPress] = useState<Boolean>(false);

  useEffect(() => {
    if (press && !!action) {
      action();
      setPress(false);
    }
  }, [press]);

  return (
    <Form.Item
      label={label}
      name={name}
      rules={isRange ? [] : [{ required, message }]}
      help={!isRange && <ErrorMessage message={errorMsg} />}
      colon={false}
      className={!isRange && errorMsg ? "error-field" : ""}
    >
      {isRange ? (
        <RangePicker
          {...props}
          defaultValue={
            defaultValue
              ? [
                  moment(defaultValue[0], dateFormat),
                  moment(defaultValue[1], dateFormat),
                ]
              : undefined
          }
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !!action) {
              setPress(true);
            }
          }}
          format={dateFormat}
          allowClear={allowClear}
          picker={picker}
          locale={locale}
          className={className}
          placeholder={placeholder}
          disabled={disable}
          onChange={onChange}

        />
      ) : (
        <DatePicker
          {...props}
          defaultValue={
            defaultValue ? moment(defaultValue, dateFormat) : undefined
          }
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !!action) {
              setPress(true);
            }
          }}
          format={dateFormat}
          allowClear={allowClear}
          picker={picker}
          locale={locale}
          className={className}
          placeholder={placeholder}
          disabled={disable}
          onOpenChange={(open: any) => {
            if (!open) setIsOpen(open);
          }}
          popupStyle={{ display: isOpen ? "block" : "none" }}
          suffixIcon={
            <CalendarOutlined
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            />
          }
        />
      )}
    </Form.Item>
  );
};

export default DateTimePicker;

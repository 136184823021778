import { FC } from "react";
import { Spin } from "antd";
import "./style.scss";

interface Props {
  hasContent?: boolean;
}

const Loading: FC<Props> = ({ hasContent = true }) => {
  return (
    <div className={hasContent ? "loading_full" : "loading"}>
      <Spin tip="Loading" />
    </div>
  );
};
export default Loading;

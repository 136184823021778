import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { labels } from "../../utils/constants";
import TableWrap from "../Table";
import TopContent from "../TopContent";
import CheckboxSlider from "./CheckboxSlider";
import "./style.scss";

interface SliderListProps {
  link: string;
  keyQuery: any;
  query: any;
  column: any;
  id?: string;
  handleRemove: (id: any) => void;
  headContent: string;
  visible?: boolean;
  action?: any;
  idx?: number;
  setIdx?: (e: number) => void;
  formdata?: boolean;
}
const SliderList: FC<SliderListProps> = ({
  link,
  keyQuery,
  query,
  column,
  handleRemove,
  headContent,
  visible = true,
  action,
  idx,
  setIdx,
  formdata,
}) => {
  const navigate = useNavigate();
  const { data, isLoading, isFetching }: any = useQuery(keyQuery, query);

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "stt",
      key: "stt",
    },
    ...column,
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      align: "center",
      width: "7%",
      key: "hienthi",
      render: (record: boolean, data: any) => {
        if (formdata)
          return (
            <CheckboxSlider
              _id={data?._id}
              check={record}
              action={action}
              formName="hienthi"
              en={idx === 1}
            />
          );
        else
          return (
            <CheckboxSlider
              _id={data?._id}
              check={record}
              action={action}
              name="hienthi"
              en={idx === 1}
            />
          );
      },
    },
    {
      title: "Sửa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${link}/${record?._id}`, { state: { en: idx === 1 } })
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              handleRemove(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className="slider_list">
      <TopContent
        content={
          <Row>
            <Col>
              <b>{headContent}</b>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  navigate(`${link}/add`, { state: { en: idx === 1 } })
                }
              >
                <PlusOutlined /> Add
              </Button>
            </Col>
          </Row>
        }
      />
      <div className="content">
        {setIdx ? (
          <Tabs
            type="card"
            onChange={(e: any) => {
              setIdx(e);
            }}
            items={labels.map((item: any, i: any) => {
              return {
                label: item,
                key: i,
                children: (
                  <TableWrap
                    loading={isLoading || isFetching}
                    columns={
                      visible
                        ? columns
                        : columns.filter((e) => e.key !== "hienthi")
                    }
                    isShowTotal
                    dataSource={data?.data}
                    rowKey="_id"
                    bordered
                    pagination={false}
                    className="hover table-slide"
                    hasDefaultColumn={false}
                  />
                ),
              };
            })}
          />
        ) : (
          <TableWrap
            loading={isLoading || isFetching}
            columns={
              visible ? columns : columns.filter((e) => e.key !== "hienthi")
            }
            isShowTotal
            dataSource={data?.data}
            rowKey="_id"
            bordered
            pagination={false}
            className="hover table-slide"
            hasDefaultColumn={false}
          />
        )}
      </div>
    </div>
  );
};

export default SliderList;

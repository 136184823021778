import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import notificationApi from "../../../../api/notificationApi";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const NotificationList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const { data, isLoading, isFetching }: any = useQuery(
    ["notification_general", currentPage, limit],
    () => notificationApi.getAllGeneralNotification(currentPage, limit)
  );
  const mutation = useMutation(notificationApi.deleteGeneralNotification, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("notification_general");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      width: "5%",
      align: "center",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Nội dung",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Loại user",
      dataIndex: "roles",
      key: "roles",
      render: (record: Array<String>) => (
        <>
          {record?.map((item: any, index: number) => (
            <div key={index}>
              {item}
              <br />
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Sửa",
      key: "detail",
      align: "center",
      width: "5%",
      render: (_, record) => (
        <EditOutlined
          onClick={() =>
            navigate(`${appRoutes.NOTIFICATION.GENERAL.LIST}/${record?._id}`)
          }
        />
      ),
    },
    {
      title: "Xóa",
      align: "center",
      width: "5%",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <PageWrap
      title="Thông báo hệ thống"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Thông báo hệ thống</b>
                </Col>
                <Col>
                  <Button
                    onClick={() => navigate(appRoutes.NOTIFICATION.GENERAL.ADD)}
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data?.source}
              rowKey="_id"
              bordered
              pagination={{
                pageSize: limit,
                current: currentPage,
                total: data?.data?.totalItems,
                onChange: (page: number, pageSize: number) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                },
              }}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </Card>
        </div>
      }
    />
  );
};

export default NotificationList;

import { message } from "antd";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import contentApi from "../../../../api/contentApi";
import ContentForm from "../../../../components/ContentForm";
import SeoOption from "../../../../components/SeoOption";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

interface TradingBlogFormProps {
  type: number;
}
const TradingBlogForm: FC<TradingBlogFormProps> = ({ type }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [seo, setSeo]: any = useState();
  const [values, setValues]: any = useState();
  const [file, setFile]: any = useState();

  const mutationEdit = useMutation(contentApi.editBlog, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("content_blog");
        navigate(appRoutes.CONTENT.TRADING_BLOG.LIST);
        window.scrollTo(0, 0);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(contentApi.addBlog, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("content_blog");
        navigate(appRoutes.CONTENT.TRADING_BLOG.LIST);
        window.scrollTo(0, 0);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const handleSubmit = (dt: any) => {
    if (values?.ten_vi && values?.slug_vi && (!id ? file : true)) {
      const formData = new FormData();
      if (file) formData.append("photo", file);
      formData.set("chuyenmuc", values?.chuyenmuc ?? "");
      formData.set("ten_vi", values?.ten_vi ?? "");
      formData.set("stt", values?.stt ?? "");
      formData.set("slug_vi", values?.slug_vi ?? "");
      formData.set("mota_vi", values?.mota_vi ?? "");
      if (values?.noidung_vi)
        formData.set("noidung_vi", values?.noidung_vi ?? "");
      if (values?.noidung1_vi)
        formData.set("noidung1_vi", values?.noidung1_vi ?? "");
      if (values?.noidung2_vi)
        formData.set("noidung2_vi", values?.noidung2_vi ?? "");
      if (values?.noidung3_vi)
        formData.set("noidung3_vi", values?.noidung3_vi ?? "");
      if (values?.noidung4_vi)
        formData.set("noidung4_vi", values?.noidung4_vi ?? "");
      if (values?.noidung5_vi)
        formData.set("noidung5_vi", values?.noidung5_vi ?? "");
      if (values?.noidung6_vi)
        formData.set("noidung6_vi", values?.noidung6_vi ?? "");
      if (values?.noidung7_vi)
        formData.set("noidung7_vi", values?.noidung7_vi ?? "");
      formData.set("tag", values?.tag ?? "");
      formData.set("title_vi", dt.title_vi ?? "");
      formData.set("keywords_vi", dt.keywords_vi ?? "");
      formData.set("description_vi", dt.description_vi ?? "");
      if (type === 1) {
        formData.set("hienthi", values?.hienthi ?? false);
        formData.set("noibat", values?.noibat ?? false);
      }
      if (values?.hienthi !== undefined && type === 2)
        formData.set("hienthi", values?.hienthi);
      if (values?.noibat !== undefined && type === 2)
        formData.set("noibat", values?.noibat);
      if (id) mutationEdit.mutate({ id: id, data: formData });
      else mutationAdd.mutate(formData);
    } else message.error("Vui lòng nhập đầy đủ thông tin.");
  };

  return (
    <PageWrap
      title="Trading Blog"
      content={
        <ContentForm
          seo={
            <SeoOption
              keyQuery={["content_blog", id ?? location.state?.id]}
              query={() => contentApi.getBlogDetails(id ?? location.state?.id)}
              link={appRoutes.CONTENT.TRADING_BLOG.LIST}
              onSubmit={handleSubmit}
              setSeo={(e: any) => setSeo(e)}
            />
          }
          conditions={type === 2 || !!location?.state?.id}
          type={type}
          headContent="Trading Blog"
          mutationEdit={mutationEdit}
          mutationAdd={mutationAdd}
          keyQuery={["content_blog", id ?? location.state?.id]}
          link={appRoutes.CONTENT.TRADING_BLOG.LIST}
          query={() => contentApi.getBlogDetails(id ?? location.state?.id)}
          seoVal={seo}
          file={file}
          setFile={(e: any) => setFile(e)}
          setValues={(e: any) => setValues(e)}
          danhmuc={undefined}
        />
      }
    />
  );
};

export default TradingBlogForm;

import { configs, configsEn, header } from "../config";
import { callApi } from "./axiosClient";

const banner = "banner";
const home = "home";
const cash = "dong-tien";
const guest = "khach-hang";
const guide = "huong-dan";
const feedback = "comment";
const visible = "hien-thi";
const toolDownload = "tool-download";
const whyAI = "why-ai";

const sliderApi = {
  async getHomeSlider() {
    const url = `${banner}/${home}`;
    return callApi("GET", url);
  },
  async getHomeSliderDetails(id: string | undefined) {
    const url = `${banner}/${home}/${id}`;
    return callApi("GET", url);
  },
  async addHomeSlider(data: any) {
    const url = `${banner}/${home}`;
    return callApi("POST", url, data, configs);
  },
  async editHomeSlider({ id, data }: { id: string | undefined; data: any }) {
    const url = `${banner}/${home}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteHomeSlider(id: string) {
    const url = `${banner}/${home}/${id}`;
    return callApi("DELETE", url);
  },
  async getCashSlider(en: boolean) {
    const url = `${banner}/${cash}`;
    return callApi("GET", url, null, en ? header : undefined);
  },
  async getCashSliderDetails({
    id,
    en,
  }: {
    id: string | undefined;
    en: boolean;
  }) {
    const url = `${banner}/${cash}/${id}`;
    return callApi("GET", url, null, en ? header : undefined);
  },
  async addCashSlider({ data, en }: { data: any; en: boolean }) {
    const url = `${banner}/${cash}`;
    return callApi("POST", url, data, en ? configsEn : configs);
  },
  async editCashSlider({
    id,
    data,
    en,
  }: {
    id: string | undefined;
    data: any;
    en: boolean;
  }) {
    const url = `${banner}/${cash}/${id}`;
    return callApi("PUT", url, data, en ? configsEn : configs);
  },
  async deleteCashSlider({ id, en }: { id: string; en: boolean }) {
    const url = `${banner}/${cash}/${id}`;
    return callApi("DELETE", url, en ? header : undefined);
  },
  async getGuestSlider() {
    const url = `${banner}/${guest}`;
    return callApi("GET", url);
  },
  async getGuestSliderDetails(id: string | undefined) {
    const url = `${banner}/${guest}/${id}`;
    return callApi("GET", url);
  },
  async addGuestSlider(data: any) {
    const url = `${banner}/${guest}`;
    return callApi("POST", url, data, configs);
  },
  async editGuestSlider({ id, data }: { id: string | undefined; data: any }) {
    const url = `${banner}/${guest}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteGuestSlider(id: string) {
    const url = `${banner}/${guest}/${id}`;
    return callApi("DELETE", url);
  },
  async getGuideSlider() {
    const url = `${banner}/${guide}`;
    return callApi("GET", url);
  },
  async getGuideSliderDetails(id: string | undefined) {
    const url = `${banner}/${guide}/${id}`;
    return callApi("GET", url);
  },
  async addGuideSlider(data: any) {
    const url = `${banner}/${guide}`;
    return callApi("POST", url, data, configs);
  },
  async editGuideSlider({ id, data }: { id: string | undefined; data: any }) {
    const url = `${banner}/${guide}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteGuideSlider(id: string) {
    const url = `${banner}/${guide}/${id}`;
    return callApi("DELETE", url);
  },
  async getFeedbackSlider() {
    const url = `${banner}/${feedback}`;
    return callApi("GET", url);
  },
  async getFeedbackSliderDetails(id: string | undefined) {
    const url = `${banner}/${feedback}/${id}`;
    return callApi("GET", url);
  },
  async addFeedbackSlider(data: any) {
    const url = `${banner}/${feedback}`;
    return callApi("POST", url, data, configs);
  },
  async editFeedbackSlider({
    id,
    data,
  }: {
    id: string | undefined;
    data: any;
  }) {
    const url = `${banner}/${feedback}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteFeedbackSlider(id: string) {
    const url = `${banner}/${feedback}/${id}`;
    return callApi("DELETE", url);
  },
  async updateVisibleHome({
    id,
    hienthi,
  }: {
    id: string | undefined;
    hienthi: boolean;
  }) {
    const url = `${banner}/${home}/${visible}/${id}`;
    return callApi("PUT", url, { hienthi: hienthi }, configs);
  },
  async updateVisibleCash({
    id,
    hienthi,
    en,
  }: {
    id: string | undefined;
    hienthi: boolean;
    en: boolean;
  }) {
    const url = `${banner}/${cash}/${visible}/${id}`;
    return callApi("PUT", url, { hienthi: hienthi }, en ? header : undefined);
  },
  async updateVisibleFeedback({
    id,
    hienthi,
  }: {
    id: string | undefined;
    hienthi: boolean;
  }) {
    const url = `${banner}/${feedback}/${visible}/${id}`;
    return callApi("PUT", url, { hienthi: hienthi });
  },
  async getToolDownloadSlider() {
    const url = `${banner}/${toolDownload}`;
    return callApi("GET", url);
  },
  async getToolDownloadSliderDetails(id: string | undefined) {
    const url = `${banner}/${toolDownload}/${id}`;
    return callApi("GET", url);
  },
  async addToolDownloadSlider(data: any) {
    const url = `${banner}/${toolDownload}`;
    return callApi("POST", url, data, configs);
  },
  async editToolDownloadSlider({
    id,
    data,
  }: {
    id: string | undefined;
    data: any;
  }) {
    const url = `${banner}/${toolDownload}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteToolDownloadSlider(id: string) {
    const url = `${banner}/${toolDownload}/${id}`;
    return callApi("DELETE", url);
  },
  async getWhyAISlider() {
    const url = `${banner}/${whyAI}`;
    return callApi("GET", url);
  },
  async getWhyAISliderDetails(id: string | undefined) {
    const url = `${banner}/${whyAI}/${id}`;
    return callApi("GET", url);
  },
  async addWhyAISlider(data: any) {
    const url = `${banner}/${whyAI}`;
    return callApi("POST", url, data, configs);
  },
  async editWhyAISlider({ id, data }: { id: string | undefined; data: any }) {
    const url = `${banner}/${whyAI}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteWhyAISlider(id: string) {
    const url = `${banner}/${whyAI}/${id}`;
    return callApi("DELETE", url);
  },
};

export default sliderApi;

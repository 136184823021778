import { callApi } from "./axiosClient";

const account = "tai-khoan/tai-khoan-thanh-vien";

const accountApi = {
  async getAll(
    page: number,
    page_size: number,
    email?: string,
    ten?: string,
    dienthoai?: string,
    membership?: number,
    role?: number
  ) {
    const url = `${account}?page=${page}&page_size=${page_size}${
      email ? "&email=" + email : ""
    }${ten ? "&ten=" + ten : ""}${dienthoai ? "&dienthoai=" + dienthoai : ""}${
      membership || membership === 0 ? "&membership=" + membership : ""
    }${role ? "&role=" + role : ""}`;
    return callApi("GET", url);
  },

  async DangKyMoi() {
    const url = `${account}/new`;
    return callApi("GET", url);
  },

  async expires(
    page: number,
    page_size: number,
    startDay?: string,
    endDay?: string
  ) {
    const url = `${account}/expires?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },

  async getDetails(id: string | undefined) {
    const url = `${account}/${id}`;
    return callApi("GET", url);
  },
  async edit({ id, data }: { id: string | undefined; data: any }) {
    const url = `${account}/${id}`;
    return callApi("PUT", url, data);
  },
  async delete(id: string | undefined) {
    const url = `${account}/${id}`;
    return callApi("DELETE", url);
  },
  async download() {
    const url = `${account}/download`;
    const headers: any = { responseType: "arraybuffer" };
    const response: any = await callApi("GET", url, null, headers);
    const result =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      Buffer.from(response, "binary").toString("base64");
    const aElement = document.createElement("a");
    aElement.href = result;
    aElement.download = "DanhSachThanhVien.xlsx";
    aElement.click();
    return result;
  },
};
export default accountApi;

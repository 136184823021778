import { Col, message, Row } from "antd";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import RegisterCommon from "../../../components/RegisterCommon";
import CheckboxSlider from "../../../components/SliderList/CheckboxSlider";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { messages } from "../../../utils/messages";
import listEmailApi from "../../../api/listEmailApi";

const MailsRegistes = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(listEmailApi.deleteById, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("emails_regiser");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Danh sách email đã đăng ký"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh sách email đã đăng ký</b>
                </Col>
              </Row>
            }
          />
          <RegisterCommon
            keyQuery="emails_regiser"
            filter={false}
            query={listEmailApi.getListEmail}
            link={appRoutes.MAIL_LIST.ROOT}
            action={mutation}
            download={listEmailApi.download}
            columnsParram={[
              {
                title: "#",
                align: "center",
                width: "5%",
                dataIndex: "index",
                key: "index",
              },
              {
                title: "Email",
                key: "email",
                align: "left",
                dataIndex: "email",
              },
              {
                title: "Họ tên",
                key: "name",
                align: "left",
                dataIndex: "name",
              },
              {
                title: "Số điện thoại",
                key: "numberPhone",
                align: "left",
                dataIndex: "numberPhone",
              },
              {
                title: "Công ty",
                key: "company",
                align: "left",
                dataIndex: "company",
              },
              {
                title: "Ngày đăng ký",
                dataIndex: "ngaydangky",
                key: "ngaydangky",
                render: (record: string) => (
                  <>{moment(record).format("DD/MM/YYYY")}</>
                ),
              },
              {
                title: "From landing",
                dataIndex: "isLanding",
                align: "center",
                key: "isLanding",
                width: "8%",
                render: (record: boolean, data: any) => {
                  return (
                    <CheckboxSlider
                      _id={data?.email}
                      check={record ?? false}
                      disable
                      dataName="isLanding"
                    />
                  );
                },
              },
              {
                title: "Trạng Thái",
                dataIndex: "trangthai",
                key: "trangthai",
              },
            ]}
          />
        </div>
      }
    />
  );
};

export default MailsRegistes;

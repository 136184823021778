import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import contentApi from "../../../../api/contentApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface QuestionFormProps {
  type: number;
}
const QuestionForm: FC<QuestionFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["content_question", id],
    () => contentApi.getQuestionDetails(id),
    {
      enabled: type === 2,
    }
  );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const mutationEdit = useMutation(contentApi.editQuestion, {
    onSuccess: async(data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("content_question");
        navigate(appRoutes.CONTENT.QUESTIONS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(contentApi.addQuestion, {
    onSuccess: async(data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("content_question");
        navigate(appRoutes.CONTENT.QUESTIONS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };

  return (
    <PageWrap
      title="Câu hỏi thường gặp"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Câu hỏi thường gặp</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  <InputField
                    label="Câu hỏi"
                    name="ten_vi"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.ten_vi}
                    errorMsg={errors?.ten_vi?.message}
                    rules={{
                      required: "Vui lòng nhập câu hỏi",
                    }}
                  />
                  <TextAreaField
                    label="Trả lời"
                    name="mota_vi"
                    control={control}
                    defaultValue={data?.data?.mota_vi}
                    errorMsg={errors?.mota_vi?.message}
                    rules={{
                      required: "Vui lòng nhập trả lời",
                    }}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="stt"
                    control={control}
                    defaultValue={data?.data?.stt ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("stt", 1);
                    }}
                    min={1}
                  />
                  <Form.Item className="checkbox">
                    <Row>
                      <Col xs={24} sm={20}>
                        <Checkbox
                          defaultChecked={data?.data?.hienthi}
                          name="hienthi"
                          onClick={(e: any) => {
                            setValue("hienthi", e.target.checked);
                          }}
                        />
                        &nbsp;Hiển thị
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.CONTENT.QUESTIONS.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default QuestionForm;

import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import PageWrap from "../../layout/PageWrap";
import "./style.scss";

const title = "Trang web không tồn tại";

export const NotFound = () => {
  return (
    <PageWrap
      title="Trang web không tồn tại"
      content={
        <div className="notfound_wrap">
          <Result
            status="404"
            title="404"
            subTitle={title}
            extra={
              <Link to={"/"}>
                <Button type="primary">Quay lại</Button>
              </Link>
            }
          />
        </div>
      }
    />
  );
};

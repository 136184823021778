import { message } from "antd";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import trainApi from "../../../../../api/trainApi";
import SeoOption from "../../../../../components/SeoOption";
import { appRoutes } from "../../../../../routes/routes";
import { messages } from "../../../../../utils/messages";

interface SeoPostProps {
  getValues: any;
  file?: any;
  setSeo: (e: any) => void;
}
const SeoPost: FC<SeoPostProps> = ({ getValues, file, setSeo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const mutation = useMutation(trainApi.editPost, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("training_post");
        navigate(appRoutes.TRAINING.POSTS.LIST);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(trainApi.addPost, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("training_post");
        navigate(appRoutes.TRAINING.POSTS.LIST);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      if (err?.status === 422) message.error(messages?.error ?? "Lỗi");
      else message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (
      getValues()?.chuyenmuc &&
      getValues()?.ten_vi &&
      getValues()?.slug_vi &&
      (!id ? file : true)
    ) {
      const formData = new FormData();
      if (file) formData.append("hinhanh", file);
      formData.set("chuyenmuc", getValues()?.chuyenmuc ?? "");
      formData.set("ten_vi", getValues()?.ten_vi ?? "");
      formData.set("index", getValues()?.index ?? "");
      formData.set("slug_vi", getValues()?.slug_vi ?? "");
      formData.set("mota_vi", getValues()?.mota_vi ?? "");
      if (getValues()?.noidung_vi)
        formData.set("noidung_vi", getValues()?.noidung_vi ?? "");
      if (getValues()?.noidung1_vi)
        formData.set("noidung1_vi", getValues()?.noidung1_vi ?? "");
      if (getValues()?.noidung2_vi)
        formData.set("noidung2_vi", getValues()?.noidung2_vi ?? "");
      if (getValues()?.noidung3_vi)
        formData.set("noidung3_vi", getValues()?.noidung3_vi ?? "");
      if (getValues()?.noidung4_vi)
        formData.set("noidung4_vi", getValues()?.noidung4_vi ?? "");
      if (getValues()?.noidung5_vi)
        formData.set("noidung5_vi", getValues()?.noidung5_vi ?? "");
      if (getValues()?.noidung6_vi)
        formData.set("noidung6_vi", getValues()?.noidung6_vi ?? "");
      if (getValues()?.noidung7_vi)
        formData.set("noidung7_vi", getValues()?.noidung7_vi ?? "");
      formData.set("tag", getValues()?.tag ?? "");
      formData.set("title_vi", dt.title_vi ?? "");
      formData.set("keywords_vi", dt.keywords_vi ?? "");
      formData.set("description_vi", dt.description_vi ?? "");
      if (getValues()?.hienthi !== undefined)
        formData.set("hienthi", getValues()?.hienthi);
      if (id) mutation.mutate({ id: id, data: formData });
      else mutationAdd.mutate(formData);
    } else message.error("Vui lòng nhập đầy đủ thông tin.");
  };

  return (
    <SeoOption
      keyQuery={["training_post", "post", id ?? location.state?.id]}
      query={() => trainApi.getPostDetails(id ?? location.state?.id)}
      link={appRoutes.TRAINING.POSTS.LIST}
      onSubmit={onSubmit}
      setSeo={(e: any) => setSeo(e)}
    />
  );
};

export default SeoPost;

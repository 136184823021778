import { Button, Col, Form, Input, message, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import imptApi from "../../../api/imptApi";
import ErrorMessage from "../../../components/ErrorMessage";
import SelectField from "../../../components/SelectField";
import { messages } from "../../../utils/messages";

interface ModalImportProps {
  options: any;
  open: (e: any) => void;
  show: (e: any) => void;
}
const ModalImport: FC<ModalImportProps> = ({ options, open, show }) => {
  const queryClient = useQueryClient();
  const [type, setType]: any = useState(1);
  const [file, setFile]: any = useState();
  const [fileExtension, setFileExtension]: any = useState(
    `.${options?.[0]?.fileExtension}`
  );

  const mutation = useMutation(imptApi.importFile, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.importSuccess);
        queryClient.invalidateQueries("import_history");
        show(false);
        reset();
      } else {
        show(false);
        open(true);
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      show(false);
      open(true);
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleUpload = async (file: any) => {
    setFile(file);
  };
  const onSubmit = () => {
    open(false);
    show(true);
    const formData = new FormData();
    formData.set("key", type);
    formData.append("file", file);
    mutation.mutate(formData);
  };
  useEffect(() => {
    let exist = options?.findIndex((i: any) => i.key === type);
    if (exist !== -1) setFileExtension(`.${options?.[exist]?.fileExtension}`);
  }, [type]);

  useEffect(() => {
    reset();
  }, []);

  return (
    <>
      <Form layout="vertical">
        <SelectField
          label="Loại import"
          placeholder="Loại import"
          options={options?.map((e: any) => {
            return {
              key: e?.key,
              value: e?.data,
            };
          })}
          defaultValue={options?.[0]?.key}
          control={control}
          name="key"
          allowClear={true}
          listHeight={224}
          onChange={(e: any) => {
            setType(e);
          }}
        />
        <Controller
          name="url"
          rules={{
            required: "Vui lòng chọn file",
          }}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Form.Item
              label="File"
              help={<ErrorMessage message={errors?.url?.message} />}
              className={errors?.url?.message ? "error-field" : ""}
              colon={false}
            >
              <Input
                {...field}
                type="file"
                defaultValue=""
                accept={fileExtension}
                multiple={false}
                onChange={(e) => {
                  field.onChange(e);
                  handleUpload(e.target.files?.[0]);
                }}
              />
            </Form.Item>
          )}
        />
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              danger
              onClick={handleSubmit(onSubmit)}
              className="import_auto_button"
            >
              Import
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ModalImport;

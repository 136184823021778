import { Button, Form, message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import InputField from "../../components/InputField";
import PageWrap from "../../layout/PageWrap";
import { appRoutes } from "../../routes/routes";
import { convertTime } from "../../utils/functions/convertTime";
import { messages } from "../../utils/messages";

const ValidateOtp = () => {
  const [time, setTime]: any = useState(new Date().getTime());
  const [enable, setEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const profile = useQuery("profile", authApi.getProfile, {
    enabled: enable,
  });

  const { handleSubmit, control } = useForm();

  const mutation = useMutation(authApi.login2F, {
    onSuccess: (data: any) => {
      setLoading(false);
      if (data.statusCode === 200) {
        setEnable(true);
        message.success("Đăng nhập thành công.");
        sessionStorage.removeItem("verifyCode");
        sessionStorage.setItem("token", data?.data);
      }
    },
    onError: (err: any) => {
      setLoading(false);
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    if (loading) return;
    setLoading(true);
    mutation.mutate({
      verifyCode: location?.state?.verifyCode,
      token2fa: dt.code,
    });
  };

  const storedItem = JSON.parse(String(sessionStorage.getItem("verifyCode")));
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().getTime());
    }, 1000);
    if (storedItem?.expiration && time >= storedItem.expiration) {
      sessionStorage.removeItem("verifyCode");
      navigate(appRoutes.AUTH.LOGIN);
    }
    return () => clearInterval(interval);
  }, [storedItem?.expiration - time]);

  useEffect(() => {
    if (profile?.data && enable) {
      sessionStorage?.setItem("profile", JSON.stringify(profile?.data?.data));
      navigate("/");
    }
  }, [profile?.data]);

  return (
    <PageWrap
      title="Login"
      content={
        <div className="login">
          <div className="login__wrap">
            <h1>Login</h1>
            <Form>
              <InputField
                name="code"
                control={control}
                placeholder="Mã xác thực 2FA"
                label={"Mã xác thực 2FA - Hết hạn sau: " + (storedItem?.expiration != null ? convertTime(storedItem?.expiration - time) : "")}
                rules={{
                  required: "Vui lòng nhập mã xác thực",
                }}
                onKeyDown={(e: any) =>
                  e.keyCode === 13 ? handleSubmit(onSubmit)() : undefined
                }
              />
              <Button
                htmlType="submit"
                onClick={handleSubmit(onSubmit)}
                type="primary"
              >
                {loading
                  ? "Đang kiểm tra..."
                  : storedItem?.expiration - time > 0
                  ? "Login"
                  : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      }
    />
  );
};

export default ValidateOtp;

import { message } from "antd";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import libraryApi from "../../../../../api/libraryApi";
import { messages } from "../../../../../utils/messages";
import "./style.scss";

interface RatingsProps {
  defaultStar: number;
  id: string;
  event: boolean | undefined;
}
const Ratings: FC<RatingsProps> = ({ defaultStar, id, event }) => {
  const queryClient = useQueryClient();

  const [star, setStar]: any = useState(
    Array.from(Array(defaultStar + 1).keys())
  );
  const mutation = useMutation(libraryApi.editIndicator, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("library_indicators");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <>
      {Array.from(Array(5).keys()).map((i) => (
        <i
          className={`fa fa-star cursor_pointer star_icon${
            star?.includes(i) ? " star_active" : ""
          }`}
          key={i}
          onMouseMove={() => setStar(Array.from(Array(i + 1).keys()))}
          onMouseLeave={() => {
            setStar(Array.from(Array(defaultStar + 1).keys()));
          }}
          onMouseDown={() => {
            if (event) {
              const formData = new FormData();
              formData.set("rading", star.length);

              mutation.mutate({
                id: id,
                data: formData,
              });
            }
          }}
        ></i>
      ))}
    </>
  );
};

export default Ratings;

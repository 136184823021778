import React, { FC } from 'react';
import './style.scss';

interface ErrorMessageProps {
  message: any;
}
const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return (
    <>
      <span className="error-message">{message}</span>
    </>
  );
};

export default ErrorMessage;

import { Column } from "@ant-design/charts";

import "./style.scss";
import PageTopWrapper from "../../../components/page-top-wrapper";
import trainApi from "../../../api/trainApi";
import { useQuery } from "react-query";

const RegisterDaoTao = () => {
  const { data, isLoading, isFetching, refetch }: any = useQuery(
    ["dang_ky_dao_tao_moi"],
    () => trainApi.DangKyMoi()
  );

  const config = {
    data: data && data?.data ? data?.data?.data : [],
    xField: "name",
    yField: "value",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    color: ({ name }: any) => {
      if (name === "Hôm nay") {
        return "#5B8FF9";
      }
      return "#F4664A";
    },

    meta: {
      value: { alias: "Lượt đăng ký" },
    },
    minColumnWidth: 10,
    maxColumnWidth: 30,
  };

  return (
    <div className="div-status-chart">
      <PageTopWrapper
        leftContent={<h3>ĐĂNG KÝ ĐÀO TẠO MỚI</h3>}
        today={data?.data?.today || 0}
        titleFromTo={"15 Ngày gần đây"}
        valueFromTo={data?.data?.count || 0}
      />

      <Column
        {...config}
        className="chart-status"
        loading={isLoading || isFetching}
      />
    </div>
  );
};

export default RegisterDaoTao;

import { callApi } from "./axiosClient";

const library = "thu-vien-chi-bao";

const libraryApi = {
  async getListCate() {
    const url = `${library}/danh-muc`;
    return callApi("GET", url);
  },
  async getCateDetails(id: string | undefined) {
    const url = `${library}/danh-muc/${id}`;
    return callApi("GET", url);
  },
  async addCate(data: any) {
    const url = `${library}/danh-muc`;
    return callApi("POST", url, data);
  },
  async editCate({ id, data }: { id: string | undefined; data: any }) {
    const url = `${library}/danh-muc/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteCate(id: string | undefined) {
    const url = `${library}/danh-muc/${id}`;
    return callApi("DELETE", url);
  },
  async getIndicators(page: number, page_size: number) {
    const url = `${library}/bai-viet?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getIndicatorDetails(id: string | undefined) {
    const url = `${library}/bai-viet/${id}`;
    return callApi("GET", url);
  },
  async addIndicator(data: any) {
    const url = `${library}/bai-viet`;
    return callApi("POST", url, data);
  },
  async editIndicator({ id, data }: { id: string | undefined; data: any }) {
    const url = `${library}/bai-viet/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteIndicator(id: string | undefined) {
    const url = `${library}/bai-viet/${id}`;
    return callApi("DELETE", url);
  },
};

export default libraryApi;

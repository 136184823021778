export async function loadFile(file: any) {
  const readFile = async () => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = (e: any) => {
        return resolve(e.target.result);
      };
    });
  };
  const urlImg = await readFile();
  file.urlImg = urlImg;
  return file;
}

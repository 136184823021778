import { Button, Card, Col, Form, Input, message, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import cashApi from "../../api/cashApi";
import Loading from "../../components/Loading";
import TopContent from "../../components/TopContent";
import PageWrap from "../../layout/PageWrap";
import { formItemLayout, labels } from "../../utils/constants";
import { loadFile } from "../../utils/functions/loadFile";
import { messages } from "../../utils/messages";
import CashForm from "./CashForm";
import "./style.scss";

const Cash = () => {
  const [idx, setIdx] = useState(0);
  const [file, setFile]: any = useState();
  const { data, isLoading, isFetching } = useQuery(
    ["cash", idx === 0 ? "vi" : "en"],
    () => cashApi.getCompass(idx === 1)
  );

  const mutation = useMutation(cashApi.editCompass, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const [updatedTime, setUpdatedTime]: any = useState();
  const [image, setImage]: any = useState();

  const handleSubmit = () => {
    const formData = new FormData();
    formData.set("tangtruong", file);
    formData.append("time_tang_truong_vi", updatedTime ?? "");
    mutation.mutate({ data: formData, en: idx === 1 });
  };

  useEffect(() => {
    setUpdatedTime(data?.data?.time_vi);
  }, [data]);

  return (
    <PageWrap
      title="Xác định dòng tiền"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Xác định dòng tiền</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <Tabs
              type="card"
              onChange={(e: any) => {
                setIdx(e);
              }}
              items={labels.map((item: any, i: any) => {
                return {
                  label: item,
                  key: i,
                  children: (
                    <CashForm
                      loading={isLoading || isFetching}
                      setFile={(e) => setFile(e)}
                      setImage={(e) => setImage(e)}
                      setUpdatedTime={(e) => setUpdatedTime(e)}
                      handleSubmit={handleSubmit}
                      time_vi={data?.data?.time_vi}
                      url={data?.data?.url}
                      image={image}
                    />
                  ),
                };
              })}
            />
          </div>
        </>
      }
    />
  );
};

export default Cash;

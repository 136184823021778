import { configs } from "../config";
import { callApi } from "./axiosClient";

const train = "dao-tao";
const post = "bai-viet";
const cate = "danh-muc";

const trainApi = {
  async getCate() {
    const url = `${train}/${cate}`;
    return callApi("GET", url);
  },
  async getCateDetails(id: string | undefined) {
    const url = `${train}/${cate}/${id}`;
    return callApi("GET", url);
  },
  async addCate(data: any) {
    const url = `${train}/${cate}`;
    return callApi("POST", url, data);
  },
  async editCate({ id, data }: { id: string | undefined; data: any }) {
    const url = `${train}/${cate}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deleteCate(id: string) {
    const url = `${train}/${cate}/${id}`;
    return callApi("DELETE", url);
  },
  async getAllPosts({ page, pageSize }: { page: number; pageSize: number }) {
    const url = `${train}/${post}?page=${page}&page_size=${pageSize}`;
    return callApi("GET", url);
  },
  async getPostDetails(id: string | undefined) {
    const url = `${train}/${post}/${id}`;
    return callApi("GET", url);
  },
  async addPost(data: any) {
    const url = `${train}/${post}`;
    return callApi("POST", url, data);
  },
  async editPost({ id, data }: { id: string | undefined; data: any }) {
    const url = `${train}/${post}/${id}`;
    return callApi("PUT", url, data, configs);
  },
  async deletePost(id: string) {
    const url = `${train}/${post}/${id}`;
    return callApi("DELETE", url);
  },

  async DangKyMoi() {
    const url = `${train}/dang-ky/overview`;
    return callApi("GET", url);
  },

  async ListHocVien(
    page: number,
    page_size: number,
    startDay?: string,
    endDay?: string
  ) {
    const url = `${train}/hocvien?startDay=${startDay}&endDay=${endDay}&page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
};

export default trainApi;

import PageTopWrapper from "../../../components/page-top-wrapper";
import TableWrap from "../../../components/Table";
import { useQuery } from "react-query";
import { FC, useState, useEffect } from "react";
import newApi from "../../../api/newApi";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

interface IProps {
  startDay?: string;
  endDay?: string;
}

const BaiVietMoi = () => {
  const { data, isLoading, isFetching, refetch }: any = useQuery(
    ["dang_ky_moi_bai_viet"],
    () => newApi.DangKyMoi()
  );

  const columns: ColumnsType<any> = [
    {
      title: "#",
      width: "3%",
      align: "center",
      dataIndex: "index",
      key: "index",
    },

    {
      title: "Mục",
      dataIndex: "danhmuc",
      key: "danhmuc",
      width: "200px",
    },
    {
      title: "Tên",
      dataIndex: "ten_vi",
      key: "ten_vi",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (item) => {
        return (
          <a target="_blank" href={item} rel="noreferrer">
            {item}
          </a>
        );
      },
    },
    {
      title: "Thời gian đăng",
      dataIndex: "ngaytao",
      align: "center",
      render: (item) => moment(item).format("HH:mm:ss"),
    },
    {
      title: "Lược view",
      dataIndex: "views",
      align: "center",
    },
  ];

  return (
    <div>
      <PageTopWrapper leftContent={<h3>BÀI VIẾT HÔM NAY</h3>} />

      <TableWrap
        loading={isLoading || isFetching}
        columns={columns}
        isShowTotal
        dataSource={data?.data ?? []}
        rowKey="_id"
        bordered
        className="hover table-slide"
        hasDefaultColumn={false}
      />
    </div>
  );
};

export default BaiVietMoi;

import { callApi } from "./axiosClient";

const notification = "thong-bao";
const generalNotification = "thong-bao-chung";
const sign = "tin-hieu";

const notificationApi = {
  async getChannels() {
    const url = `${notification}/${sign}/list-channels`;
    return callApi("GET", url);
  },
  async getAllGeneralNotification(page: number, page_size: number) {
    const url = `${notification}/${generalNotification}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getDetailsGeneralNotification(id: string | undefined) {
    const url = `${notification}/${generalNotification}/${id}`;
    return callApi("GET", url);
  },
  async addGeneralNotification(data: any) {
    const url = `${notification}/${generalNotification}`;
    return callApi("POST", url, data);
  },
  async editGeneralNotification({
    id,
    data,
  }: {
    id: string | undefined;
    data: any;
  }) {
    const url = `${notification}/${generalNotification}/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteGeneralNotification(id: string | undefined) {
    const url = `${notification}/${generalNotification}/${id}`;
    return callApi("DELETE", url);
  },
  async getAllSignNotification(page: number, page_size: number) {
    const url = `${notification}/${sign}?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async getDetailsSignNotification(id: string | undefined) {
    const url = `${notification}/${sign}/${id}`;
    return callApi("GET", url);
  },
  async addSignNotification(data: any) {
    const url = `${notification}/${sign}`;
    return callApi("POST", url, data);
  },
  async editSignNotification({
    id,
    data,
  }: {
    id: string | undefined;
    data: any;
  }) {
    const url = `${notification}/${sign}/${id}`;
    return callApi("PUT", url, data);
  },
  async deleteSignNotification(id: string | undefined) {
    const url = `${notification}/${sign}/${id}`;
    return callApi("DELETE", url);
  },
};
export default notificationApi;

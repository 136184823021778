import { Col, message, Row } from "antd";
import { useMutation, useQueryClient } from "react-query";
import mailApi from "../../../api/mailApi";
import signApi from "../../../api/signApi";
import RegisterCommon from "../../../components/RegisterCommon";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { messages } from "../../../utils/messages";

const Signs = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(signApi.delete, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("register_sign");
        queryClient.invalidateQueries("noti");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Đăng ký tín hiệu"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Đăng ký tín hiệu</b>
                </Col>
              </Row>
            }
          />
          <RegisterCommon
            keyQuery="register_sign"
            query={signApi.getAll}
            link={appRoutes.GET_SIGN.ROOT}
            action={mutation}
            download={mailApi.download}
          />
        </div>
      }
    />
  );
};

export default Signs;

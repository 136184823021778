import { Button, Card, Col, Form, Row } from "antd";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { formItemLayoutSm } from "../../utils/constants";
import InputField from "../InputField";
import Loading from "../Loading";
import TextAreaField from "../TextAreaField";

interface SeoOptionProps {
  keyQuery: any;
  query: any;
  link: string;
  onSubmit: (data: any) => void;
  setSeo: (e: any) => void;
}
const SeoOption: FC<SeoOptionProps> = ({
  keyQuery,
  query,
  link,
  onSubmit,
  setSeo,
}) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const { data, isLoading, isFetching }: any = useQuery([`${keyQuery}`], query);
  useEffect(() => {
    if (data)
      setSeo({
        title_vi: data?.data?.title_vi,
        keywords_vi: data?.data?.keywords_vi,
        description_vi: data?.data?.description_vi,
      });
  }, [data]);

  return (
    <>
      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <Card bordered={false}>
          <Form {...formItemLayoutSm}>
            <InputField
              label="Title"
              name="title_vi"
              control={control}
              defaultValue={data?.data?.title_vi}
              errorMsg={errors?.title_vi?.message}
              onChange={() => {
                setSeo(getValues());
              }}
            />
            <InputField
              label="Keywords"
              name="keywords_vi"
              control={control}
              defaultValue={data?.data?.keywords_vi}
              errorMsg={errors?.keywords_vi?.message}
              onChange={() => {
                setSeo(getValues());
              }}
            />
            <TextAreaField
              label="Description"
              name="description_vi"
              control={control}
              defaultValue={data?.data?.description_vi}
              errorMsg={errors?.description_vi?.message}
              onChange={() => {
                setSeo(getValues());
              }}
            />
            <Row justify="start" gutter={[10, 0]}>
              <Col>
                <Button type="primary" danger onClick={handleSubmit(onSubmit)}>
                  Lưu
                </Button>
              </Col>
              <Col>
                <Button onClick={() => navigate(link)}>Hủy</Button>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};

export default SeoOption;

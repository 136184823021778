import { Button, Card, Col, Form, Input, message, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import categoryApi from "../../../api/categoryApi";
import Loading from "../../../components/Loading";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { formItemLayoutSEO, options } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import "./style.scss";

const Fund = () => {
  const [dataUpdated, setDataUpdated]: any = useState();
  const { data, isLoading, isFetching } = useQuery(
    "create-category_fund",
    categoryApi.getFund
  );
  const mutation = useMutation(categoryApi.editFund, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = () => {
    mutation.mutate(dataUpdated);
  };
  const labels = [
    "Tích lũy giá trị",
    "Tích lũy gia tăng",
    "Chiến lược thị trường",
    "Thực chiến",
  ];
  useEffect(() => {
    setDataUpdated({ data: data?.data });
  }, [data]);

  return (
    <PageWrap
      title="Quản lý quỹ chủ động"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Quản lý quỹ chủ động</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSEO} colon={false} className="fund">
                  <Form.Item label="Đặc điểm">
                    <Row gutter={[20, 5]}>
                      {data?.data?.map((item: any, index: number) => (
                        <Col key={index} md={6} sm={24}>
                          <Form.Item
                            label={labels[index]}
                            className="form_item_custom"
                          >
                            <Input
                              name="dacdiem"
                              defaultValue={item?.dacdiem}
                              onBlur={(e: any) =>
                                setDataUpdated((prev: any) => {
                                  return {
                                    data: prev?.data?.map(
                                      (each: any, idx: number) => {
                                        if (idx === index)
                                          return {
                                            ...each,
                                            dacdiem: e.target.value,
                                          };
                                        return each;
                                      }
                                    ),
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Form.Item>
                  <Form.Item label="Rủi ro">
                    <Row gutter={[20, 5]}>
                      {data?.data?.map((item: any, index: number) => (
                        <Col key={index} md={6} sm={24}>
                          <Form.Item className="form_item_custom">
                            <Select
                              options={options}
                              defaultValue={item?.ruiro?.toString()}
                              onChange={(e: any) =>
                                setDataUpdated((prev: any) => {
                                  return {
                                    data: prev?.data?.map(
                                      (each: any, idx: number) => {
                                        if (idx === index)
                                          return { ...each, ruiro: Number(e) };
                                        return each;
                                      }
                                    ),
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Form.Item>
                  <Form.Item label="Thanh khoản">
                    <Row gutter={[20, 5]}>
                      {data?.data?.map((item: any, index: number) => (
                        <Col key={index} md={6} sm={24}>
                          <Form.Item className="form_item_custom">
                            <Select
                              options={options}
                              defaultValue={item?.thanhkhoan?.toString()}
                              onChange={(e: any) =>
                                setDataUpdated((prev: any) => {
                                  return {
                                    data: prev?.data?.map(
                                      (each: any, idx: number) => {
                                        if (idx === index)
                                          return {
                                            ...each,
                                            thanhkhoan: Number(e),
                                          };
                                        return each;
                                      }
                                    ),
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Form.Item>
                  <Form.Item label="Kỳ vọng lợi nhuận">
                    <Row gutter={[20, 5]}>
                      {data?.data?.map((item: any, index: number) => (
                        <Col key={index} md={6} sm={24}>
                          <Form.Item className="form_item_custom">
                            <Select
                              options={options}
                              defaultValue={item?.kyvong?.toString()}
                              onChange={(e: any) =>
                                setDataUpdated((prev: any) => {
                                  return {
                                    data: prev?.data?.map(
                                      (each: any, idx: number) => {
                                        if (idx === index)
                                          return { ...each, kyvong: Number(e) };
                                        return each;
                                      }
                                    ),
                                  };
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Form.Item>

                  <Row justify="start" gutter={[10, 0]}>
                    <Button type="primary" danger onClick={onSubmit}>
                      Lưu
                    </Button>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default Fund;

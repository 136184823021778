import React, { FC, ReactElement, memo } from "react";
import { Row, Col } from "antd";
import "./style.scss";

interface IProps {
  leftContent?: ReactElement;
  rightContent?: ReactElement;
  today?: Number;
  titleFromTo?: String;
  valueFromTo?: Number;
}
const PageTopWrapper: FC<IProps> = ({
  leftContent,
  today,
  titleFromTo,
  valueFromTo,
}) => (
  <>
    <Row justify="space-between" align="middle" className="page-top-wrapper">
      <Col>{leftContent}</Col>
      {today != undefined && <Col>
        <Row justify="end" style={{ alignItems: "center" }}>
          {
            <>
              <h2>Hôm nay: </h2>
              <h1 className="status_value">{today?.toString()}</h1>
              <h2>{titleFromTo}: </h2>
              <h1 className="status_value">{valueFromTo?.toString()}</h1>
            </>
          }
        </Row>
      </Col>}
    </Row>
  </>
);
export default memo(PageTopWrapper);

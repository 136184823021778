import { FC, useEffect } from "react";

interface PageWrapProps {
  title: string;
  content: React.ReactNode;
}
const PageWrap: FC<PageWrapProps> = ({ title, content }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return <>{content}</>;
};

export default PageWrap;

import { Button, Col, Form, Checkbox, message, Row, Select } from "antd";
import Card from "antd/lib/card/Card";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import mailApi from "../../../api/mailApi";
import Loading from "../../../components/Loading";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm, status } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import "./style.scss";

const MailDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [sts, setSts] = useState<number | undefined>();
  const [hasRegisterChuyengia, setHasRegisterChuyengia] = useState<
    boolean | undefined
  >();
  const { data, isLoading, isFetching } = useQuery(["mails", id], () =>
    mailApi.getDetails(id)
  );

  queryClient.invalidateQueries("noti");

  const mutation = useMutation(mailApi.updateStatus, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await Promise.all([
          queryClient.invalidateQueries("mails"),
          queryClient.invalidateQueries("noti"),
        ]);
        navigate(appRoutes.MAIL.ROOT);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Thư liên hệ"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Thư liên hệ</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false} className="register-form">
                <Form
                  {...formItemLayoutSm}
                  className="slider-form border_form"
                  colon={false}
                >
                  <Form.Item label=" ">
                    <Checkbox
                      defaultChecked={data?.data?.hasRegisterChuyengia ?? false}
                      value={data?.data?.hasRegisterChuyengia ?? false}
                      onChange={(e: any) => {
                        setHasRegisterChuyengia(e.target.checked);
                      }}
                    />
                    &nbsp;Đã đăng ký chuyên gia
                  </Form.Item>

                  <Form.Item label="Trạng thái">
                    <Select
                      options={status}
                      defaultValue={data?.data?.trangthai}
                      onChange={(e) => setSts(e)}
                    />
                  </Form.Item>
                  <Form.Item label="Họ / Tên" className="empty">
                    <div>{data?.data?.ten}</div>
                  </Form.Item>
                  <Form.Item label="Điện thoại" className="empty">
                    <div>{data?.data?.dienthoai}</div>
                  </Form.Item>
                  <Form.Item label="Email" className="empty">
                    <div>{data?.data?.email}</div>
                  </Form.Item>
                  <Form.Item label="Địa chỉ" className="empty">
                    <div>{data?.data?.diachi}</div>
                  </Form.Item>
                  <Form.Item label="Tiêu đề" className="empty">
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.data?.tieude }}
                    ></div>
                  </Form.Item>
                  <Form.Item label="Content" className="empty">
                    <div>{data?.data?.noidung}</div>
                  </Form.Item>
                  <Row
                    justify="start"
                    gutter={[10, 0]}
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={() =>
                          mutation.mutate({
                            id: id,
                            status: sts,
                            hasRegisterChuyengia: hasRegisterChuyengia,
                          })
                        }
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(appRoutes.MAIL.ROOT)}>
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default MailDetails;

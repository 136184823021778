import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import contentApi from "../../../../api/contentApi";
import ListContent from "../../../../components/ListContent";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const InvestmentList = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(contentApi.deleteInvestment, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("content_investment");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  return (
    <PageWrap
      title="Cơ hội đầu tư"
      content={
        <ListContent
          headContent="Cơ hội đầu tư"
          handleRemove={(e) => mutation.mutate(e)}
          action={useMutation(contentApi.editInvestment, {
            onSuccess: (data: any) => {
              if (data.statusCode === 200) {
                message.success(messages.updateSuccess);
                queryClient.invalidateQueries("content_investment");
              } else {
                message.error(data?.data?.message ?? messages.error);
              }
            },
            onError: (err: any) => {
              message.error(err?.data?.message ?? messages.error);
            },
          })}
          keyQuery="content_investment"
          link="/noi-dung-bai-viet/co-hoi-dau-tu"
          query={contentApi.getInvestments}
        />
      }
    />
  );
};

export default InvestmentList;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import telegramApi from "../../../../api/telegramApi";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const ListCate = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching }: any = useQuery(
    ["telegram", "list-cate"],
    telegramApi.getCate
  );
  const mutation = useMutation(telegramApi.deleteCate, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(["telegram", "list-cate"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const handleRemove = (e: any) => {
    mutation.mutate(e);
  };
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: 50,
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Tên",
      dataIndex: "ten_vi",
      key: "ten_vi",
    },
    {
      title: "Tiêu đề",
      dataIndex: "mota_vi",
      key: "mota_vi",
    },
    {
      title: "Sửa",
      align: "center",
      width: 90,
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.TELEGRAM.CATEGORIES.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: 90,
      render: (record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={() => handleRemove(record._id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <PageWrap
      title="Tín hiệu Telegram"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(`${appRoutes.TELEGRAM.CATEGORIES.ADD}`)
                    }
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data}
              rowKey="_id"
              bordered
              pagination={false}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </>
      }
    />
  );
};

export default ListCate;

import { Layout } from "antd";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { DoubleLeftIcon, DoubleRightIcon } from "../../assets/icon";
import logo from "../../assets/images/logo-tag.png";
import useWindowDimensions from "../../hook/WindowDimensions";
import HeaderLayout from "../Header";
import Navigation from "../Navigation";
import "./style.scss";

const MainLayout = () => {
  const { width, isMobile } = useWindowDimensions();
  const cache: any = sessionStorage.getItem("profile");
  const location = useLocation();
  const [isAdminTuVan, setAdminTuVan] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(
    !!width && width <= 765
  );

  const [showCollapsed, setShowCollapsed] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    if (width) {
      const slider = document.querySelector(".main_wrap");
      if (width <= 765) {
        slider?.classList.add("collapse");
        setIsCollapsed(true);
      } else {
        slider?.classList.remove("collapse");
        setIsCollapsed(false);
      }
    }
  }, [width]);

  useEffect(() => {
    if (cache) {
      setAdminTuVan(JSON.parse(cache).role == 4);
    }
  }, [cache]);

  useEffect(() => {
    setShowCollapsed(false);
  }, [location, location.pathname]);

  return (
    <div id="main" className="main_wrap">
      {((showCollapsed || !isMobile) && !isAdminTuVan) && (
        <Layout.Sider
          style={{
            overflow: "inherit",
            height: "100%",
            position: isMobile ? "absolute" : "inherit",
          }}
          collapsible
          width={220}
          onCollapse={() => {
            const slider = document.querySelector(".main_wrap");
            setIsCollapsed(!isCollapsed);
            if (!isCollapsed) slider?.classList.add("collapse");
            else slider?.classList.remove("collapse");
          }}
          trigger={isCollapsed ? <DoubleRightIcon /> : <DoubleLeftIcon />}
        >
          <Link to="/" className="logo">
            <img src={logo} alt="ADMIN" />
          </Link>
          <div
            className="mm-close mm-btn"
            style={{ cursor: "pointer" }}
            onClick={() => setShowCollapsed(false)}
          ></div>
          <div className="nav_wrap">
            <Navigation />
          </div>
        </Layout.Sider>
      )}
      <div className="right_side">
        <HeaderLayout handleClick={() => setShowCollapsed(true)} />
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;

import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import thongTinKhoaHocApi from "../../../../api/thongTinKhoaHoc";
import trainApi from "../../../../api/trainApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { loadFile } from "../../../../utils/functions/loadFile";
import { messages } from "../../../../utils/messages";

interface CateFormProps {
  type: number;
}

const CategoryForm: FC<CateFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [file, setFile]: any = useState();
  const { data, isLoading } = useQuery(
    ["training_cate", "category", id],
    () => trainApi.getCateDetails(id),
    {
      enabled: type === 2,
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [image, setImage]: any = useState();
  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };
  const mutationEdit = useMutation(trainApi.editCate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("training_cate");
        navigate(appRoutes.TRAINING.CATEGORIES.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(trainApi.addCate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("training_cate");
        navigate(appRoutes.TRAINING.CATEGORIES.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("photo", file);
    formData.set("ten_vi", dt.ten_vi ?? "");
    formData.set("demuc", dt.demuc ?? "");
    formData.set("mota_vi", dt.mota_vi ?? "");
    formData.set("stt", dt.stt ?? "");
    formData.set("giang_vien", dt.giang_vien);
    formData.set("hocvien", dt.hocvien);
    if (dt.hienthi !== undefined) formData.set("hienthi", dt.hienthi);
    if (type === 2) mutationEdit.mutate({ id: id, data: formData });
    if (type === 1) mutationAdd.mutate(formData);
  };

  const listGiangVien: any = useQuery(
    ["list_giang_vien"],
    thongTinKhoaHocApi.getListGiangVien
  );


  return (
    <PageWrap
      title="Danh mục"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục đào tạo</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  {(image || data?.data?.photo) && (
                    <div className="cash_img">
                      <img
                        src={
                          image ??
                          process.env.REACT_APP_DOMAIN + data?.data?.photo
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <InputField
                    isImage={true}
                    name="photo"
                    control={control}
                    rules={
                      type === 1
                        ? {
                            required: "Vui lòng chọn hình ảnh",
                          }
                        : undefined
                    }
                    label="Hình ảnh"
                    errorMsg={errors?.photo?.message}
                    onChange={(e: any) => {
                      handleUpload(e.target.files?.[0]);
                    }}
                  />
                  <InputField
                    label="Tên"
                    name="ten_vi"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.ten_vi}
                    errorMsg={errors?.ten_vi?.message}
                    rules={{
                      required: "Vui lòng nhập tên",
                    }}
                  />
                  <InputField
                    label="Đề mục"
                    name="demuc"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.demuc}
                    errorMsg={errors?.demuc?.message}
                    rules={{
                      required: "Vui lòng nhập đề mục",
                    }}
                  />
                  <TextAreaField
                    label="Mô tả"
                    name="mota_vi"
                    control={control}
                    defaultValue={data?.data?.mota_vi}
                    errorMsg={errors?.mota_vi?.message}
                    rules={{
                      required: "Vui lòng nhập mô tả",
                    }}
                  />
                   <SelectField
                    control={control}
                    label="Giảng viên"
                    name="giang_vien"
                    options={listGiangVien?.data?.data?.map((e: any) => ({
                      key: e._id,
                      value: e.ten_vi,
                    }))}
                    defaultValue={data?.data?.giang_vien}
                    errorMsg={errors?.giang_vien?.message}
                    rules={{
                      required: "Vui lòng chọn giảng viên",
                    }}
                  />
                  <InputNumberWrap
                    label="Số lượng học viên"
                    name="hocvien"
                    control={control}
                    defaultValue={data?.data?.hocvien ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("hocvien", 0);
                    }}
                    min={0}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="stt"
                    control={control}
                    defaultValue={data?.data?.stt ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("stt", 1);
                    }}
                    min={1}
                  />
                  <Form.Item className="checkbox">
                    <Row>
                      <Col xs={24} sm={20}>
                        <Checkbox
                          defaultChecked={data?.data?.hienthi}
                          name="hienthi"
                          onClick={(e: any) => {
                            setValue("hienthi", e.target.checked);
                          }}
                        />
                        &nbsp;Hiển thị
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.TRAINING.CATEGORIES.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default CategoryForm;

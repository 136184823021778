import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Popconfirm, Row, Form } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import contentApi from "../../../../api/contentApi";
import SelectField from "../../../../components/SelectField";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const CategoriesBaiViet = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [type, setType] = useState();

  const { handleSubmit, control } = useForm();

  const { data, isLoading, isFetching, refetch }: any = useQuery(
    ["create-category-bai-viet", type],
    () => contentApi.getDanhMucBaiViet({ type: type })
  );

  const listKeys: any = useQuery(
    "list-page-category",
    contentApi.getListDanhMucByPage
  );

  const optionsType = (listKeys?.data?.data ?? []).map((e: any) => {
    return {
      key: e?.type,
      value: e?.page,
    };
  });

  const mutation = useMutation(contentApi.deleteDanhMucBaiViet, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("create-category-bai-viet");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Tên danh mục",
      dataIndex: "ten_vi",
      key: "ten_vi",
    },
    {
      title: "Trang",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Sửa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(
                `${appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST}/${record?._id}`
              )
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const onFilter = (dt: any) => {
    if (dt.type) {
      setType(dt.type);
    } else {
      setType(undefined);
    }
  };

  return (
    <PageWrap
      title="Danh mục bài viết"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Danh mục bài viết</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.ADD)
                    }
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <>
              <Form className="register_common__form">
                <Row
                  style={{ marginBottom: "30px" }}
                  align="bottom"
                  gutter={[20, 10]}
                >
                  <Col lg={4} md={6} sm={24}>
                    <SelectField
                      label="Lọc theo page"
                      placeholder="Chọn page"
                      options={optionsType}
                      control={control}
                      name="type"
                      allowClear={true}
                      listHeight={224}
                    />
                  </Col>
                  <Col lg={20} md={6} sm={24}>
                    <Row justify="end">
                      <Button
                        htmlType="submit"
                        className="primary_btn"
                        onClick={handleSubmit(onFilter)}
                      >
                        Lọc
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TableWrap
                loading={isLoading || isFetching}
                columns={columns}
                isShowTotal
                dataSource={data?.data}
                rowKey="_id"
                bordered
                pagination={false}
                className="hover table-slide"
                hasDefaultColumn={false}
              />
            </>
          </Card>
        </div>
      }
    />
  );
};

export default CategoriesBaiViet;

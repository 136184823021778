import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import trainApi from "../../../../api/trainApi";
import { Duplicate } from "../../../../assets/icon/duplicate";
import CheckboxSlider from "../../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";
import "./style.scss";

const ListPosts = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [page_size, setPageSize] = useState(20);
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching }: any = useQuery(
    ["training_post", "list-posts", page, page_size],
    () => trainApi.getAllPosts({ page: page, pageSize: page_size })
  );
  const mutation = useMutation(trainApi.deletePost, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(["training_post"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const update = useMutation(trainApi.editPost, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries(["training_post"]);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const handleRemove = (e: any) => {
    mutation.mutate(e);
  };
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Chuyên mục",
      dataIndex: "danhmuc",
      key: "danhmuc",
    },
    {
      title: "Tên",
      dataIndex: "ten_vi",
      key: "ten_vi",
    },
    {
      title: "Xem",
      align: "center",
      width: "5%",
      key: "view",
      render: (record: boolean, data: any) => {
        let time = "00:00";
        if (data.timeRead && data.timeRead > 0) {
          const hours = Math.floor(data.timeRead / 3600);
          const minutes = Math.floor((data.timeRead % 3600) / 60);
          const seconds = data.timeRead % 60;

          if (hours > 0) {
            time = `${String(hours).padStart(2, "0")}:${String(
              minutes
            ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
          } else {
            time = `${String(minutes).padStart(2, "0")}:${String(
              seconds
            ).padStart(2, "0")}`;
          }
        }
        return (
          <div style={{ whiteSpace: "pre", textAlign: "left" }}>
            {`Lượt xem: ${data.views ?? 0}\nThời gian xem: ${time}`}
          </div>
        );
      },
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      align: "center",
      width: "7%",
      render: (record: boolean, data: any) => (
        <CheckboxSlider
          _id={data._id}
          check={record}
          dataName="premium"
          action={update}
        />
      ),
    },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      key: "hienthi",
      align: "center",
      width: "8%",
      render: (record: boolean, data: any) => (
        <CheckboxSlider
          _id={data._id}
          check={record}
          dataName="hienthi"
          action={update}
        />
      ),
    },
    {
      title: "Duplicate",
      align: "center",
      width: "8%",
      render: (_, record: any) => {
        return (
          <div
            className="copy_wrap"
            onClick={() =>
              navigate(`${appRoutes.TRAINING.POSTS.ADD}`, {
                state: { id: record._id },
              })
            }
          >
            <Duplicate />
          </div>
        );
      },
    },
    {
      title: "Sửa",
      align: "center",
      width: "5%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.TRAINING.POSTS.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "5%",
      render: (record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={() => handleRemove(record._id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <PageWrap
      title="Bài viết"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Bài viết</b>
                </Col>
                <Col>
                  <Button
                    onClick={() => navigate(`${appRoutes.TRAINING.POSTS.ADD}`)}
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data?.source}
              rowKey="_id"
              bordered
              pagination={{
                pageSize: page_size,
                current: page,
                total: data?.data?.totalItems,
                onChange: (page: number, pageSize: number) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </>
      }
    />
  );
};

export default ListPosts;

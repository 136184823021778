import { callApi } from "./axiosClient";

const newUrl = "bai-viet/overviewtoday";

const newApi = {
  async DangKyMoi() {
    const url = `${newUrl}`;
    return callApi("GET", url);
  },
};
export default newApi;

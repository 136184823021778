import { Button, Card, Checkbox, Col, Form, message, Row, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import trainApi from "../../../../api/trainApi";
import Ckeditor from "../../../../components/Ckeditor";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { loadFile } from "../../../../utils/functions/loadFile";
import { messages } from "../../../../utils/messages";
import SeoPost from "./SeoOption";
import { ConvertSlug } from "../../../../utils/functions/convertSlug";
interface PostFormProps {
  type: number;
}

const PostForm: FC<PostFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [file, setFile]: any = useState();
  const [seo, setSeo]: any = useState();

  const { data, isLoading, isFetching } = useQuery(
    ["training_post", "post", id ?? location.state?.id],
    () => trainApi.getPostDetails(id ?? location.state?.id),
    { enabled: type === 2 || !!location.state?.id }
  );
  const listCate: any = useQuery(
    ["training_post", "list-cate"],
    trainApi.getCate
  );
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [image, setImage]: any = useState();
  const [tagVal, setTagVal]: any = useState();
  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };
  const mutationEdit = useMutation(trainApi.editPost, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("training_post");
        navigate(appRoutes.TRAINING.POSTS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      if (err?.status === 422)
        message.error(err?.data?.message ?? err?.data?.message?.error ?? "Lỗi");
      else message.error(messages.error);
    },
  });
  const mutationAdd = useMutation(trainApi.addPost, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("training_post");
        navigate(appRoutes.TRAINING.POSTS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      if (err?.status === 422) message.error(messages?.error ?? "Lỗi");
      else message.error(err?.data?.message ?? messages.error);
    },
  });
  useEffect(() => {
    if (data?.data?.hienthi) setValue("hienthi", data?.data?.hienthi);
    if (data?.data?.premium) setValue("premium", data?.data?.premium);
    setTagVal(data?.data?.tag !== "" ? data?.data?.tag?.split(",") : undefined);
  }, [data]);
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("hinhanh", file);
    formData.set("chuyenmuc", dt.chuyenmuc ?? "");
    formData.set("ten_vi", dt.ten_vi ?? "");
    formData.set("index", dt.index ?? "");
    formData.set("slug_vi", dt.slug_vi ?? "");
    formData.set("mota_vi", dt.mota_vi ?? "");
    formData.set("noidung_vi", dt.noidung_vi ?? data?.data?.noidung_vi ?? "");
    formData.set(
      "noidung1_vi",
      dt.noidung1_vi ?? data?.data?.noidung1_vi ?? ""
    );
    formData.set(
      "noidung2_vi",
      dt.noidung2_vi ?? data?.data?.noidung2_vi ?? ""
    );
    formData.set(
      "noidung3_vi",
      dt.noidung3_vi ?? data?.data?.noidung3_vi ?? ""
    );
    formData.set(
      "noidung4_vi",
      dt.noidung4_vi ?? data?.data?.noidung4_vi ?? ""
    );
    formData.set(
      "noidung5_vi",
      dt.noidung5_vi ?? data?.data?.noidung5_vi ?? ""
    );
    formData.set(
      "noidung6_vi",
      dt.noidung6_vi ?? data?.data?.noidung6_vi ?? ""
    );
    formData.set(
      "noidung7_vi",
      dt.noidung7_vi ?? data?.data?.noidung7_vi ?? ""
    );
    formData.set("tag", dt.tag ?? data?.data?.tag ?? []);
    formData.set("title_vi", seo?.title_vi ?? data?.data?.title_vi ?? "");
    formData.set(
      "keywords_vi",
      seo?.keywords_vi ?? data?.data?.keywords_vi ?? ""
    );
    formData.set(
      "description_vi",
      seo?.description_vi ?? data?.data?.description_vi ?? ""
    );
    formData.set("hienthi", dt.hienthi ?? data?.data?.hienthi ?? false);
    formData.set("premium", dt.premium ?? data?.data?.premium ?? false);
    if (type === 2) mutationEdit.mutate({ id: id, data: formData });
    if (type === 1) mutationAdd.mutate(formData);
  };
  const labels = ["Tổng quan", "SEO option"];
  const getLayout = (index: number) => {
    if (index === 0)
      return (
        <>
          {isLoading || isFetching ? (
            <Loading />
          ) : (
            <Card bordered={false}>
              <Form {...formItemLayoutSm} className="ckeditor_form">
                {(image || (data?.data?.photo && type === 2)) && (
                  <div className="cash_img">
                    <img
                      src={
                        image ??
                        process.env.REACT_APP_DOMAIN + data?.data?.photo
                      }
                      alt=""
                    />
                  </div>
                )}
                <InputField
                  isImage={true}
                  name="hinhanh"
                  control={control}
                  rules={
                    type === 1
                      ? {
                          required: "Vui lòng chọn hình ảnh",
                        }
                      : undefined
                  }
                  label="Hình ảnh"
                  errorMsg={errors?.hinhanh?.message}
                  onChange={(e: any) => {
                    handleUpload(e.target.files?.[0]);
                  }}
                />
                <SelectField
                  placeholder="Vui lòng chọn"
                  control={control}
                  label="Chuyên mục"
                  name="chuyenmuc"
                  options={listCate?.data?.data?.map((e: any) => ({
                    key: e.id,
                    value: e.ten_vi,
                  }))}
                  defaultValue={data?.data?.id_item}
                  errorMsg={errors?.chuyenmuc?.message}
                  rules={{
                    required: "Vui lòng chọn chuyên mục",
                  }}
                />
                <InputField
                  label="Tiêu đề"
                  name="ten_vi"
                  control={control}
                  setValue={setValue}
                  defaultValue={data?.data?.ten_vi}
                  errorMsg={errors?.ten_vi?.message}
                  rules={{
                    required: "Vui lòng nhập tiêu đề",
                  }}
                  onChange={(e: any) => {
                    setValue("slug_vi", ConvertSlug(e.target.value));
                  }}
                />
                <InputField
                  label="Slug"
                  name="slug_vi"
                  setValue={setValue}
                  control={control}
                  defaultValue={data?.data?.slug_vi}
                  errorMsg={errors?.slug_vi?.message}
                  rules={{
                    required: "Vui lòng nhập slug",
                    minLength: {
                      value: 10,
                      message: "Vui lòng nhập trên 10 kí tự",
                    },
                  }}
                />
                <TextAreaField
                  label="Mô tả"
                  name="mota_vi"
                  control={control}
                  defaultValue={data?.data?.mota_vi}
                  errorMsg={errors?.mota_vi?.message}
                />
                <Form.Item className="ckeditor_form__item">
                  <Ckeditor
                    initData={data?.data?.noidung_vi}
                    onChange={(evt: any) =>
                      setValue("noidung_vi", evt.editor.getData())
                    }
                  />
                  <Row
                    gutter={[30, 23]}
                    style={{ marginTop: "23px", marginBottom: "23px" }}
                  >
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung1_vi}
                        onChange={(evt: any) =>
                          setValue("noidung1_vi", evt.editor.getData())
                        }
                      />
                    </Col>
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung2_vi}
                        onChange={(evt: any) =>
                          setValue("noidung2_vi", evt.editor.getData())
                        }
                      />
                    </Col>
                  </Row>
                  <Ckeditor
                    initData={data?.data?.noidung3_vi}
                    onChange={(evt: any) =>
                      setValue("noidung3_vi", evt.editor.getData())
                    }
                  />
                  <Row
                    gutter={[30, 23]}
                    style={{ marginTop: "23px", marginBottom: "23px" }}
                  >
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung4_vi}
                        onChange={(evt: any) =>
                          setValue("noidung4_vi", evt.editor.getData())
                        }
                      />
                    </Col>
                    <Col lg={12} md={24} style={{ width: "100%" }}>
                      <Ckeditor
                        initData={data?.data?.noidung5_vi}
                        onChange={(evt: any) =>
                          setValue("noidung5_vi", evt.editor.getData())
                        }
                      />
                    </Col>
                  </Row>
                  <div style={{ marginBottom: "23px" }}>
                    <Ckeditor
                      initData={data?.data?.noidung6_vi}
                      onChange={(evt: any) =>
                        setValue("noidung6_vi", evt.editor.getData())
                      }
                    />
                  </div>
                  <Ckeditor
                    initData={data?.data?.noidung7_vi}
                    onChange={(evt: any) =>
                      setValue("noidung7_vi", evt.editor.getData())
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Tag"
                  colon={false}
                  style={{ marginTop: "26px" }}
                >
                  <TagsInput
                    value={tagVal}
                    onBlur={() => {
                      if (getValues()?.tag) {
                        setTagVal(getValues()?.tag);
                      }
                    }}
                    onChange={(e: any) => {
                      setValue("tag", e);
                    }}
                    onKeyUp={(e: any) => {
                      if (e?.key === "Enter") {
                        e.target.value = "";
                      }
                    }}
                  />
                </Form.Item>
                <InputNumberWrap
                  label="STT"
                  name="index"
                  control={control}
                  defaultValue={data?.data?.index ?? 1}
                  onBlur={(e: any) => {
                    if (e.target.value === "") setValue("index", 1);
                  }}
                  min={1}
                />
                <Form.Item className="checkbox">
                  <Row gutter={[20, 5]}>
                    <Col>
                      <Checkbox
                        defaultChecked={data?.data?.premium}
                        name="premium"
                        onClick={(e: any) => {
                          setValue("premium", e.target.checked);
                        }}
                      />
                      &nbsp;Premium
                    </Col>
                    <Col>
                      <Checkbox
                        defaultChecked={data?.data?.hienthi}
                        name="hienthi"
                        onClick={(e: any) => {
                          setValue("hienthi", e.target.checked);
                        }}
                      />
                      &nbsp;Hiển thị
                    </Col>
                  </Row>
                </Form.Item>
                <Row justify="start" gutter={[10, 0]}>
                  <Col>
                    <Button
                      type="primary"
                      danger
                      onClick={handleSubmit(onSubmit)}
                    >
                      Lưu
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => navigate(appRoutes.TRAINING.POSTS.LIST)}
                    >
                      Hủy
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          )}
        </>
      );
    else
      return (
        <SeoPost
          file={file}
          setSeo={(e: any) => setSeo(e)}
          getValues={getValues}
        />
      );
  };
  return (
    <PageWrap
      title="Bài viết"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Bài viết</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <Tabs
              type="card"
              items={labels.map((item: any, i: any) => {
                return {
                  label: item,
                  key: i,
                  children: getLayout(i),
                };
              })}
            />
          </div>
        </>
      }
    />
  );
};

export default PostForm;

import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import thongTinKhoaHocApi from "../../../../api/thongTinKhoaHoc";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { loadFile } from "../../../../utils/functions/loadFile";
import { messages } from "../../../../utils/messages";

interface GiangVienProps {
  type: number;
}

const GiangVienForm: FC<GiangVienProps> = ({ type }) => {
  const navigate = useNavigate();
  const [file, setFile]: any = useState();
  const [image, setImage]: any = useState();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["chi_tiet_giang_vien", id],
    () => thongTinKhoaHocApi.getDetailGiangVien(id),
    {
      enabled: type === 2,
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(thongTinKhoaHocApi.editGiangVien, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("chi_tiet_giang_vien");
        navigate(appRoutes.TRAINING.GIANG_VIEN.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(thongTinKhoaHocApi.addGiangVien, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("chi_tiet_giang_vien");
        navigate(appRoutes.TRAINING.GIANG_VIEN.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("hinhanh", file);
    formData.set("ten_vi", dt.ten_vi ?? "");
    formData.set("vitri", dt.vitri ?? "");
    formData.set("mota_vi", dt.mota_vi ?? "");
    if (type === 2) mutationEdit.mutate({ id: id, data: formData });
    if (type === 1) mutationAdd.mutate({ data: formData });
  };

  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };

  return (
    <PageWrap
      title="Thông tin giảng viên"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Chi tiết giảng viên</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  {(image || data?.data?.photo) && (
                    <div className="cash_img">
                      <img
                        src={
                          image ??
                          process.env.REACT_APP_DOMAIN + data?.data?.photo
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <InputField
                    isImage={true}
                    name="photo"
                    control={control}
                    rules={
                      type == 1
                        ? {
                            required: "Vui lòng chọn hình ảnh",
                          }
                        : undefined
                    }
                    label="Avatar"
                    onChange={(e: any) => {
                      handleUpload(e.target.files?.[0]);
                    }}
                  />

                  <InputField
                    name="ten_vi"
                    control={control}
                    label="Tên giảng viên"
                    placeholder="Tên giảng viên"
                    defaultValue={data?.data?.ten_vi}
                  />

                  <InputField
                    name="vitri"
                    control={control}
                    label="Chức vụ"
                    placeholder="Chức vụ của giảng viên"
                    defaultValue={data?.data?.vitri}
                  />
                  <TextAreaField
                    name="mota_vi"
                    control={control}
                    rows={3}
                    defaultValue={data?.data?.mota_vi}
                    label="Mô tả về giảng viên"
                    placeholder="Mô tả về giảng viên"
                  />

                  <Row justify="end">
                    <Form.Item>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Cập nhật
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default GiangVienForm;

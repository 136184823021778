interface AppRoute {
  AUTH: {
    LOGIN: string;
    LOGIN_2F: string;
  };
  HOME: {
    ROOT: string;
  };
  PROFILE_SETTING: {
    ROOT: string;
  };
  SLIDERS: {
    ROOT: string;
    HOME: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    CASH_FLOW: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    TOOL_DOWNLOAD: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    WHY_AI: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    GUEST: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    GUIDE: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    FEEDBACK: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
  };
  MAIL_LIST: {
    ROOT: string;
    DETAILS: string;
  };
  SYSTEM_SETTING: {
    ROOT: string;
  };
  CASH: {
    ROOT: string;
  };
  TRAINING: {
    CATEGORIES: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    POSTS: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    GIANG_VIEN: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    DANH_GIA_KHOA_HOC: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
  };
  TELEGRAM: {
    CATEGORIES: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    CHANNELS: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
  };
  CREATE_CATEGORY: {
    NEWS_CATEGORY: {
      CATEGORY: {
        LIST: string;
        EDIT: string;
        ADD: string;
      };
      NEWS: {
        LIST: string;
        EDIT: string;
        ADD: string;
      };
    };
    EVENTS: {
      LIST: string;
      EDIT: string;
      ADD: string;
    };
    FUND: {
      ROOT: string;
    };
  };
  LIBRARY: {
    CATEGORIES: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
    INDICATOR: {
      LIST: string;
      DETAILS: string;
      ADD: string;
    };
  };
  CONTENT: {
    ABOUT: {
      ROOT: string;
    };
    INVESTMENT: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    SIGN: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    TRADING_BLOG: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    TRADING_THUC_CHIEN_V3: {
      LIST: string;
      EDIT: string;
      ADD: string;
    };
    REVIEW_BROKER_V3: {
      LIST: string;
      EDIT: string;
      ADD: string;
    };
    CATEGORY_BAI_VIET_V3: {
      LIST: string;
      EDIT: string;
      ADD: string;
    };
    QUESTIONS: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    GUIDE: {
      LIST: string;
      ADD: string;
      EDIT: string;
    };
    TERM: {
      ROOT: string;
    };
  };
  REGISTER: {
    ROOT: string;
    DETAILS: string;
  };
  GET_SIGN: {
    ROOT: string;
    DETAILS: string;
  };
  MAIL: {
    ROOT: string;
    DETAILS: string;
  };
  COUPON: {
    LIST: string;
    ADD: string;
    EDIT: string;
  };
  ORDER: {
    ROOT: string;
    DETAILS: string;
  };
  ORDER_GLOBAL: {
    ROOT: string;
    DETAILS: string;
  };
  TU_VAN_DANH_MUC: {
    ROOT: string;
    DETAILS: string;
  };
  ACCOUNT: {
    ROOT: string;
    DETAILS: string;
  };
  NOTIFICATION: {
    GENERAL: {
      LIST: string;
      ADD: string;
      DETAILS: string;
    };
    SIGN: {
      LIST: string;
      ADD: string;
      DETAILS: string;
    };
  };
  IMPORT: {
    ROOT: string;
  };
  OPEN_ACCOUNT: {
    LIST: string;
    DETAILS: string;
  };
  NOT_FOUND: string;
}

export const appRoutes: AppRoute = {
  AUTH: {
    LOGIN: "/login",
    LOGIN_2F: "/login/validateOtp"
  },
  HOME: {
    ROOT: "/",
  },
  PROFILE_SETTING: {
    ROOT: "/setting-profile",
  },
  SLIDERS: {
    ROOT: "/slider",
    HOME: {
      LIST: "/slider/home",
      DETAILS: "/slider/home/:id",
      ADD: "/slider/home/add",
    },
    CASH_FLOW: {
      LIST: "/slider/dong-tien",
      DETAILS: "/slider/dong-tien/:id",
      ADD: "/slider/dong-tien/add",
    },
    TOOL_DOWNLOAD: {
      LIST: "/slider/tool-download",
      ADD: "/slider/tool-download/add",
      EDIT: "/slider/tool-download/:id",
    },
    WHY_AI: {
      LIST: "/slider/why-ai",
      ADD: "/slider/why-ai/add",
      EDIT: "/slider/why-ai/:id",
    },
    GUEST: {
      LIST: "/slider/khach-hang",
      DETAILS: "/slider/khach-hang/:id",
      ADD: "/slider/khach-hang/add",
    },
    GUIDE: {
      LIST: "/slider/huong-dan",
      DETAILS: "/slider/huong-dan/:id",
      ADD: "/slider/huong-dan/add",
    },
    FEEDBACK: {
      LIST: "/slider/feedback",
      DETAILS: "/slider/feedback/:id",
      ADD: "/slider/feedback/add",
    },
  },
  MAIL_LIST: {
    ROOT: "/dsmail",
    DETAILS: "/dsmail/:id",
  },
  SYSTEM_SETTING: {
    ROOT: "/system-setting",
  },
  CASH: {
    ROOT: "/xac-dinh-dong-tien",
  },
  TRAINING: {
    CATEGORIES: {
      LIST: "/dao-tao/danh-muc",
      DETAILS: "/dao-tao/danh-muc/:id",
      ADD: "/dao-tao/danh-muc/add",
    },
    POSTS: {
      LIST: "/dao-tao/bai-viet",
      DETAILS: "/dao-tao/bai-viet/:id",
      ADD: "/dao-tao/bai-viet/add",
    },
    GIANG_VIEN: {
      LIST: "/dao-tao/giang-vien",
      DETAILS: "/dao-tao/giang-vien/:id",
      ADD: "/dao-tao/giang-vien/add",
    },
    DANH_GIA_KHOA_HOC: {
      LIST: "/dao-tao/danh-gia",
      DETAILS: "/dao-tao/danh-gia/:id",
      ADD: "/dao-tao/danh-gia/add",
    },
    
  },
  TELEGRAM: {
    CATEGORIES: {
      LIST: "/tin-hieu-telegram/danh-muc",
      DETAILS: "/tin-hieu-telegram/danh-muc/:id",
      ADD: "/tin-hieu-telegram/danh-muc/add",
    },
    CHANNELS: {
      LIST: "/tin-hieu-telegram/kenh",
      DETAILS: "/tin-hieu-telegram/kenh/:id",
      ADD: "/tin-hieu-telegram/kenh/add",
    },
  },
  CREATE_CATEGORY: {
    NEWS_CATEGORY: {
      CATEGORY: {
        LIST: "/tao-lap-danh-muc/tin-quan-trong/danh-muc",
        EDIT: "/tao-lap-danh-muc/tin-quan-trong/danh-muc/:id",
        ADD: "/tao-lap-danh-muc/tin-quan-trong/danh-muc/add",
      },
      NEWS: {
        LIST: "/tao-lap-danh-muc/tin-quan-trong/tin-tuc",
        EDIT: "/tao-lap-danh-muc/tin-quan-trong/tin-tuc/:id",
        ADD: "/tao-lap-danh-muc/tin-quan-trong/tin-tuc/add",
      },
    },
    EVENTS: {
      LIST: "/tao-lap-danh-muc/lich-su-kien",
      EDIT: "/tao-lap-danh-muc/lich-su-kien/:id",
      ADD: "/tao-lap-danh-muc/lich-su-kien/add",
    },
    FUND: {
      ROOT: "/tao-lap-danh-muc/quy-chu-dong",
    },
  },
  LIBRARY: {
    CATEGORIES: {
      LIST: "/thu-vien-chi-bao/danh-muc",
      DETAILS: "/thu-vien-chi-bao/danh-muc/:id",
      ADD: "/thu-vien-chi-bao/danh-muc/add",
    },
    INDICATOR: {
      LIST: "/thu-vien-chi-bao/chi-bao",
      DETAILS: "/thu-vien-chi-bao/chi-bao/:id",
      ADD: "/thu-vien-chi-bao/chi-bao/add",
    },
  },
  CONTENT: {
    ABOUT: {
      ROOT: "/noi-dung-bai-viet/ve-chung-toi",
    },
    INVESTMENT: {
      LIST: "/noi-dung-bai-viet/co-hoi-dau-tu",
      ADD: "/noi-dung-bai-viet/co-hoi-dau-tu/add",
      EDIT: "/noi-dung-bai-viet/co-hoi-dau-tu/:id",
    },
    SIGN: {
      LIST: "/noi-dung-bai-viet/tin-hieu",
      ADD: "/noi-dung-bai-viet/tin-hieu/add",
      EDIT: "/noi-dung-bai-viet/tin-hieu/:id",
    },
    TRADING_BLOG: {
      LIST: "/noi-dung-bai-viet/trading-blog",
      ADD: "/noi-dung-bai-viet/trading-blog/add",
      EDIT: "/noi-dung-bai-viet/trading-blog/:id",
    },
    TRADING_THUC_CHIEN_V3: {
      LIST: "/noi-dung-bai-viet/trading-thuc-chien",
      ADD: "/noi-dung-bai-viet/trading-thuc-chien/add",
      EDIT: "/noi-dung-bai-viet/trading-thuc-chien/:id",
    },
    REVIEW_BROKER_V3: {
      LIST: "/noi-dung-bai-viet/review-broker",
      ADD: "/noi-dung-bai-viet/review-broker/add",
      EDIT: "/noi-dung-bai-viet/review-broker/:id",
    },
    CATEGORY_BAI_VIET_V3: {
      LIST: "/noi-dung-bai-viet/category",
      ADD: "/noi-dung-bai-viet/category/add",
      EDIT: "/noi-dung-bai-viet/category/:id",
    },
    QUESTIONS: {
      LIST: "/noi-dung-bai-viet/cau-hoi-thuong-gap",
      ADD: "/noi-dung-bai-viet/cau-hoi-thuong-gap/add",
      EDIT: "/noi-dung-bai-viet/cau-hoi-thuong-gap/:id",
    },
    GUIDE: {
      LIST: "/noi-dung-bai-viet/huong-dan",
      ADD: "/noi-dung-bai-viet/huong-dan/add",
      EDIT: "/noi-dung-bai-viet/huong-dan/:id",
    },
    TERM: {
      ROOT: "/noi-dung-bai-viet/dieu-khoan",
    },
  },
  REGISTER: {
    ROOT: "/dang-ky-dao-tao",
    DETAILS: "/dang-ky-dao-tao/:id",
  },
  GET_SIGN: {
    ROOT: "/nhan-tin-hieu",
    DETAILS: "/nhan-tin-hieu/:id",
  },
  MAIL: {
    ROOT: "/thu-lien-he",
    DETAILS: "/thu-lien-he/:id",
  },
  COUPON: {
    LIST: "/ma-giam-gia",
    ADD: "/ma-giam-gia/add",
    EDIT: "/ma-giam-gia/:id",
  },
  ORDER: {
    ROOT: "/don-hang",
    DETAILS: "/don-hang/:id",
  },
  ORDER_GLOBAL: {
    ROOT: "/don-hang-global",
    DETAILS: "/don-hang-global/:id",
  },
  TU_VAN_DANH_MUC: {
    ROOT: "/tu-van-danh-muc",
    DETAILS: "/tu-van-danh-muc/:id",
  },
  ACCOUNT: {
    ROOT: "/tai-khoan-thanh-vien",
    DETAILS: "/tai-khoan-thanh-vien/:id",
  },
  NOTIFICATION: {
    GENERAL: {
      LIST: "/thong-bao/thong-bao-he-thong",
      DETAILS: "/thong-bao/thong-bao-he-thong/:id",
      ADD: "/thong-bao/thong-bao-he-thong/add",
    },
    SIGN: {
      LIST: "/thong-bao/thong-bao-tin-hieu",
      DETAILS: "/thong-bao/thong-bao-tin-hieu/:id",
      ADD: "/thong-bao/thong-bao-tin-hieu/add",
    },
  },
  IMPORT: {
    ROOT: "/import",
  },
  OPEN_ACCOUNT: {
    LIST: "/mo-tai-khoan",
    DETAILS: "/mo-tai-khoan/:id",
  },
  NOT_FOUND: "/404",
};

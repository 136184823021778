import { callApi } from "./axiosClient";

const listEmail = "danh-sach-emails";

const listEmailApi = {
  async getListEmail(page: number, page_size: number) {
    const url = `${listEmail}/danh-sach?page=${page}&page_size=${page_size}`;
    return callApi("GET", url);
  },
  async deleteListEmail(email: string) {
    const url = `${listEmail}/danh-sach`;
    return callApi("DELETE", url, {
      data: {
        email: email,
      },
    });
  },
  async getDetails(id: string | undefined) {
    const url = `${listEmail}/danh-sach/${id}`;
    return callApi("GET", url);
  },

  async deleteById(id: string | undefined) {
    const url = `${listEmail}/danh-sach/${id}`;
    return callApi("DELETE", url);
  },

  async download() {
    const url = `${listEmail}/download`;
    const headers: any = { responseType: "arraybuffer" };
    const response: any = await callApi("GET", url, null, headers);
    const result =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      Buffer.from(response, "binary").toString("base64");
    const aElement = document.createElement("a");
    aElement.href = result;
    aElement.download = "DanhSachMail.xlsx";
    aElement.click();
    return result;
  },

  async updateStatus({
    id,
    status
  }: {
    id: string | undefined;
    status: number | undefined;
  }) {
    const url = `${listEmail}/danh-sach/${id}`;
    return callApi("PUT", url, {
      trangthai: status
    });
  },
};

export default listEmailApi;

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { appRoutes } from "./routes";

function ProtectedRoute() {
  const location = useLocation();
  return sessionStorage.getItem("token") ? (
    <Outlet />
  ) : (
    <Navigate
      to={`${appRoutes.AUTH.LOGIN}`}
      state={{ from: location }}
      replace
    />
  );
}

export default ProtectedRoute;

export const configs = {
  "Content-Type": "multipart/form-data",
  type: "formData",
};
export const configsEn = {
  "Content-Type": "multipart/form-data",
  type: "formData",
  lang: "en",
};
export const header = {
  lang: "en",
};

import { Modal } from "antd";
import React, { FC } from "react";

interface PopupProps {
  title: string;
  content: React.ReactNode;
  handleClose: () => void;
  show: boolean;
  footer?: boolean;
  maskClosable?: boolean;
}
const Popup: FC<PopupProps> = ({
  title,
  content,
  handleClose,
  show,
  footer = false,
  maskClosable = false,
}) => {
  return (
    <Modal
      open={show}
      onCancel={handleClose}
      title={title}
      footer={footer}
      maskClosable={maskClosable}
    >
      {content}
    </Modal>
  );
};

export default Popup;

import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import moment from "moment";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import categoryApi from "../../../../api/categoryApi";
import DateTimePicker from "../../../../components/DateTimePicker";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface EventFormProps {
  type: number;
}

const EventForm: FC<EventFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { id } = useParams();
  const { data, isLoading, isFetching } = useQuery(
    ["create-category_events", id ?? location.state?.id],
    () => categoryApi.getEventDetails(id ?? location.state?.id),
    { enabled: type === 2 || !!location.state?.id }
  );

  const list: any = useQuery(["list_keys"], categoryApi.getListKeys);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(categoryApi.editEvent, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("create-category_events");
        navigate(appRoutes.CREATE_CATEGORY.EVENTS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(categoryApi.addEvent, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("create-category_events");
        navigate(appRoutes.CREATE_CATEGORY.EVENTS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (type === 2)
      mutationEdit.mutate({
        id: id,
        data: {
          ...dt,
          ngay_hieu_luc: moment(dt?.ngay_hieu_luc).format("DD/MM/YYYY"),
        },
      });
    if (type === 1)
      mutationAdd.mutate({
        ...dt,
        hienthi: dt.hienthi ?? data?.data?.hienthi,
        ngay_hieu_luc: moment(dt?.ngay_hieu_luc).format("DD/MM/YYYY"),
      });
  };
  useEffect(() => {
    if (data?.data?.hienthi) setValue("hienthi", data?.data?.hienthi);
  }, []);

  return (
    <PageWrap
      title="Lịch sự kiện"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Lịch sự kiện</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  <InputField
                    label="Mã"
                    name="ma_ck"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.ma_ck}
                    errorMsg={errors?.ma_ck?.message}
                    rules={{
                      required: "Vui lòng nhập mã",
                    }}
                  />
                  <SelectField
                    control={control}
                    label="Sàn"
                    name="category"
                    options={list?.data?.data?.map((e: any) => ({
                      key: e.id,
                      value: e.name,
                    }))}
                    defaultValue={
                      data?.data?.category ?? list?.data?.data?.[0]?.id
                    }
                    listHeight={160}
                  />
                  <Controller
                    name="ngay_hieu_luc"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        defaultValue={
                          data?.data?.ngay_hieu_luc
                            ? moment(data?.data?.ngay_hieu_luc, "DD/MM/YYYY")
                            : undefined
                        }
                        props={{
                          ...field,
                          onBlur: (e: any) => {
                            if (e.target.value === "")
                              setValue("ngay_hieu_luc", undefined);
                            else if (
                              moment(
                                e.target.value,
                                "DD/MM/YYYY",
                                true
                              )?.isValid()
                            )
                              setValue("ngay_hieu_luc", moment(e.target.value, "DD/MM/YYYY"));
                            // else setValue("ngay_hieu_luc", moment());
                          },
                        }}
                        label="Ngày hiệu lực"
                        allowClear={true}
                        errorMsg={errors?.ngay_hieu_luc?.message}
                      />
                    )}
                  />
                  <InputField
                    label="Loại sự kiện"
                    name="title"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.title}
                    errorMsg={errors?.title?.message}
                    rules={{
                      required: "Vui lòng nhập loại sự kiện",
                    }}
                  />
                  <TextAreaField
                    label="Chi tiết"
                    name="content"
                    control={control}
                    defaultValue={data?.data?.content}
                    errorMsg={errors?.content?.message}
                    rules={{
                      required: "Vui lòng nhập chi tiết",
                    }}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="index"
                    control={control}
                    defaultValue={data?.data?.index ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("index", 1);
                    }}
                    min={1}
                  />
                  <Form.Item className="checkbox">
                    <Row>
                      <Col xs={24} sm={20}>
                        <Checkbox
                          defaultChecked={data?.data?.hienthi}
                          name="hienthi"
                          onClick={(e: any) => {
                            setValue("hienthi", e.target.checked);
                          }}
                        />
                        &nbsp;Hiển thị
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.CREATE_CATEGORY.EVENTS.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default EventForm;

import { Modal } from "antd";
import { FC } from "react";
import "./style.scss";

interface WaitingProps {
  show: boolean;
  handleClose: () => void;
}
const Waiting: FC<WaitingProps> = ({ show, handleClose }) => {
  return (
    <Modal
      open={show}
      onCancel={handleClose}
      closable={false}
      footer={false}
      maskClosable={false}
    >
      <div className="waiting_content">
        <div className="load_wrap">
          <div className="load">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div>Đang import data...</div>
      </div>
    </Modal>
  );
};

export default Waiting;

import { Button, Card, Checkbox, Col, Form, message, Radio, Row } from "antd";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import accountApi from "../../../api/accountApi";
import orderApi from "../../../api/orderApi";
import DateTimePicker from "../../../components/DateTimePicker";
import InputField from "../../../components/InputField";
import Loading from "../../../components/Loading";
import SelectField from "../../../components/SelectField";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm, roles } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import InputNumberWrap from "../../../components/InputNumber";
import { useEffect, useState } from "react";
import TextAreaField from "../../../components/TextAreaField";
import tuvanApi from "../../../api/tuvanApi";
import "./style.scss";

const AccountDetails = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [tangGoi, setTangGoi] = useState(-1);
  const { data, isLoading, isFetching } = useQuery(["account", id], () =>
    accountApi.getDetails(id)
  );
  const listUser = useQuery("listUser", () =>
    orderApi.getServices({ isGlobal: false })
  );

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const valueNgayTang = watch(["ngay_tang"]);

  useEffect(() => {
    if (tangGoi > -1) {
      const currenGoi = (listUser?.data?.data ?? []).find(
        (x: any) => x.id === tangGoi
      );
      if (currenGoi) {
        setValue("ngay_tang", currenGoi.duration);
      }
    } else {
      setValue("ngay_tang", 0);
    }
  }, [tangGoi]);

  useEffect(() => {
    const value = parseInt(valueNgayTang.toString());
    let currentVip = data?.data?.vip;
    if (!currentVip) {
      currentVip = moment();
    } else {
      const diff = moment().diff(currentVip, "days");
      if (diff >= 0) {
        currentVip = moment();
      }
    }

    if (value > 0) {
      const newDate = moment(currentVip).add(value, "days");
      setValue("vip", newDate);
    } else if (value === 0) {
      setValue("vip", moment(data?.data?.vip));
    }
  }, [valueNgayTang, data]);

  const mutation = useMutation(accountApi.edit, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("account");
        navigate(appRoutes.ACCOUNT.ROOT);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    mutation.mutate({
      id: id,
      data: {
        ...dt,
        vip: moment(dt.vip).format("DD/MM/YYYY"),
        day_expires_plus: moment(dt.day_expires_plus).format("DD/MM/YYYY"),
      },
    });
  };

  const listChuyengia: any = useQuery(
    "list_chuyen_gia",
    tuvanApi.getListChuyenggia
  );

  return (
    <PageWrap
      title="Tài khoản thành viên"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Tài khoản thành viên</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="account_form">
                  <Row gutter={[40, 10]}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Row>
                        <Col lg={12} md={24} sm={24} xs={12} className="vip">
                          <Controller
                            name="vip"
                            control={control}
                            render={({ field }) => (
                              <DateTimePicker
                                defaultValue={
                                  data?.data?.vip
                                    ? moment(data?.data?.vip).format(
                                        "DD/MM/YYYY"
                                      )
                                    : undefined
                                }
                                props={{
                                  ...field,
                                  onBlur: (e: any) => {
                                    if (e.target.value === "")
                                      setValue("vip", undefined);
                                    else if (
                                      moment(
                                        e.target.value,
                                        "DD/MM/YYYY",
                                        true
                                      )?.isValid()
                                    )
                                      setValue(
                                        "vip",
                                        moment(e.target.value, "DD/MM/YYYY")
                                      );
                                  },
                                }}
                                label="Thời hạn"
                                allowClear={false}
                                errorMsg={errors?.vip?.message}
                              />
                            )}
                          />
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={12} className="vip">
                          <SelectField
                            label="Gói"
                            name="membership"
                            options={listUser?.data?.data?.map((each: any) => {
                              return {
                                key: each.id,
                                value: each.title,
                              };
                            })}
                            defaultValue={data?.data?.membership}
                            control={control}
                            listHeight={128}
                          />
                        </Col>
                      </Row>

                      <Controller
                        name="update_vip"
                        control={control}
                        render={({ field }) => (
                          <Form.Item
                            label={"Tăng thời hạn"}
                            colon={false}
                            className="update_user_item_radio"
                          >
                            <Radio.Group
                              className="update_vip_account"
                              onChange={(e) => {
                                if (
                                  tangGoi > -1 &&
                                  e.target.value === tangGoi
                                ) {
                                  setTangGoi(-1);
                                }
                                setTangGoi(e.target.value);
                              }}
                              value={tangGoi}
                            >
                              {listUser?.data?.data?.map((each: any) => (
                                <Radio key={each?.id} value={each?.id}>
                                  {each?.title + `(+${each.duration})`}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        )}
                      />

                      <Controller
                        name="ngay_tang"
                        control={control}
                        render={({ field }) => (
                          <InputNumberWrap
                            label="Số ngày tăng"
                            name="ngay_tang"
                            control={control}
                            onBlur={(e: any) => {
                              if (e.target.value === "")
                                setValue("ngay_tang", 0);
                            }}
                            min={0}
                          />
                        )}
                      />
                      <Row className="plus">
                        <Col xs={24} md={2}></Col>
                        <Col>
                          <Checkbox
                            defaultChecked={data?.data?.plus}
                            name="plus"
                            onClick={(e: any) => {
                              setValue("plus", e.target.checked);
                            }}
                          />
                          &nbsp;Plus
                        </Col>

                        <Col className="day_expires_plus">
                          <Controller
                            name="day_expires_plus"
                            control={control}
                            render={({ field }) => (
                              <DateTimePicker
                                defaultValue={
                                  data?.data?.day_expires_plus
                                    ? moment(
                                        data?.data?.day_expires_plus
                                      ).format("DD/MM/YYYY")
                                    : undefined
                                }
                                props={{
                                  ...field,
                                  onBlur: (e: any) => {
                                    if (e.target.value === "")
                                      setValue("day_expires_plus", undefined);
                                    else if (
                                      moment(
                                        e.target.value,
                                        "DD/MM/YYYY",
                                        true
                                      )?.isValid()
                                    )
                                      setValue(
                                        "day_expires_plus",
                                        moment(e.target.value, "DD/MM/YYYY")
                                      );
                                  },
                                }}
                                label="Thời hạn PLUS"
                                allowClear={false}
                                errorMsg={errors?.vip?.message}
                              />
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} md={2}></Col>
                        <Col xs={24} md={20}>
                          <Checkbox
                            defaultChecked={data?.data?.mailthanhtoan}
                            name="mailthanhtoan"
                            onClick={(e: any) => {
                              setValue("mailthanhtoan", e.target.checked);
                            }}
                          />
                          &nbsp;Gửi mail xác nhận thanh toán
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col xs={24} md={2}></Col>
                        <Col xs={24} md={20}>
                          <Row gutter={[10, 5]}>
                            <Col>
                              <Checkbox
                                defaultChecked={data?.data?.hienthi}
                                name="hienthi"
                                onClick={(e: any) => {
                                  setValue("hienthi", e.target.checked);
                                }}
                              />
                              &nbsp;Kích hoạt
                            </Col>
                            <Col>
                              <Checkbox
                                defaultChecked={data?.data?.blocked}
                                name="blocked"
                                onClick={(e: any) => {
                                  setValue("blocked", e.target.checked);
                                }}
                              />
                              &nbsp;Block
                            </Col>
                            <Col>
                              <Checkbox
                                defaultChecked={data?.data?.mailunlock}
                                name="mailunlock"
                                onClick={(e: any) => {
                                  setValue("mailunlock", e.target.checked);
                                }}
                              />
                              &nbsp;Gửi mail unblock
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <InputField
                        label="Email"
                        name="email"
                        placeholder="Email"
                        control={control}
                        defaultValue={data?.data?.email}
                      />
                      <InputField
                        label="Họ và tên"
                        name="ten"
                        placeholder="Họ và tên"
                        control={control}
                        defaultValue={data?.data?.ten}
                      />
                      <InputField
                        label="Điện thoại"
                        name="dienthoai"
                        placeholder="Điện thoại"
                        control={control}
                        defaultValue={data?.data?.dienthoai}
                      />
                      <SelectField
                        control={control}
                        options={roles}
                        name="role"
                        defaultValue={data?.data?.role}
                        label="Quyền"
                        listHeight={128}
                      />
                      <InputField
                        isPassword={true}
                        label="Mật khẩu"
                        name="password"
                        control={control}
                      />
                      <SelectField
                        control={control}
                        options={listChuyengia?.data?.data?.map((e: any) => ({
                          key: e.id,
                          value: e.name,
                        }))}
                        name="chuyengia"
                        allowClear={true}
                        listHeight={128}
                        label="Chuyên gia"
                        onChange={(e: any) => {
                          if (!e) {
                            setValue("chuyengia", null);
                          }
                        }}
                        defaultValue={data?.data?.chuyengia}
                      />
                      <TextAreaField
                        label="Ghi chú"
                        name="note"
                        control={control}
                        defaultValue={data?.data?.note}
                      />
                    </Col>
                  </Row>

                  <Row
                    justify="start"
                    gutter={[10, 0]}
                    style={{ marginTop: "30px" }}
                  >
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(appRoutes.ACCOUNT.ROOT)}>
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default AccountDetails;

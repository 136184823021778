import { Button, Col, Form, Checkbox, message, Row, Select } from "antd";
import Card from "antd/lib/card/Card";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import listEmailApi from "../../../api/listEmailApi";
import Loading from "../../../components/Loading";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm, status } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import "./style.scss";

const ListMailDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [sts, setSts] = useState<number | undefined>();
  const { data, isLoading, isFetching } = useQuery(["emails_regiser", id], () =>
  listEmailApi.getDetails(id)
  );

  const mutation = useMutation(listEmailApi.updateStatus, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await Promise.all([
          queryClient.invalidateQueries("emails_regiser"),
          queryClient.invalidateQueries("noti"),
        ]);
        navigate(appRoutes.MAIL_LIST.ROOT);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });



  return (
    <PageWrap
      title="Email đã đăng ký"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Email đã đăng ký</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false} className="register-form">
                <Form
                  {...formItemLayoutSm}
                  className="slider-form border_form"
                  colon={false}
                >
                  <Form.Item label="Trạng thái">
                    <Select
                      options={status}
                      defaultValue={data?.data?.trangthai}
                      onChange={(e) => setSts(e)}
                    />
                  </Form.Item>
                  <Form.Item label="Họ / Tên" className="empty">
                    <div>{data?.data?.name}</div>
                  </Form.Item>
                  <Form.Item label="Điện thoại" className="empty">
                    <div>{data?.data?.numberPhone}</div>
                  </Form.Item>
                  <Form.Item label="Email" className="empty">
                    <div>{data?.data?.email}</div>
                  </Form.Item>
                  <Form.Item label="Công ty" className="empty">
                    <div>{data?.data?.company}</div>
                  </Form.Item>
                  <Row
                    justify="start"
                    gutter={[10, 0]}
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={() =>
                          mutation.mutate({
                            id: id,
                            status: sts,
                          })
                        }
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(appRoutes.MAIL_LIST.ROOT)}>
                        Quay lại
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default ListMailDetails;

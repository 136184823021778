import { message } from "antd";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import libraryApi from "../../../../../api/libraryApi";
import SeoOption from "../../../../../components/SeoOption";
import { appRoutes } from "../../../../../routes/routes";
import { messages } from "../../../../../utils/messages";

interface SeoIndicatorProps {
  getValues: any;
  file?: any;
  downloadFile?: any;
  setSeo: (e: any) => void;
}
const SeoIndicator: FC<SeoIndicatorProps> = ({
  getValues,
  file,
  downloadFile,
  setSeo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const mutation = useMutation(libraryApi.editIndicator, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("library_indicators");
        navigate(appRoutes.LIBRARY.INDICATOR.LIST);
      } else message.error(data?.data?.message ?? messages.error);
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(libraryApi.addIndicator, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("home-slider");
        navigate(appRoutes.LIBRARY.INDICATOR.LIST);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (getValues().id_item && getValues().slug_vi && (!id ? file : true)) {
      const formData = new FormData();
      if (file) formData.append("photo", file);
      if (downloadFile) formData.append("thumb", downloadFile);
      formData.set("id_item", getValues()?.id_item);
      formData.set("ten_vi", getValues()?.ten_vi ?? "");
      formData.set("index", getValues()?.index ?? "");
      formData.set("slug_vi", getValues()?.slug_vi);
      formData.set("mota_vi", getValues()?.mota_vi ?? "");
      formData.set("gia", getValues()?.gia ?? "");
      if (getValues()?.noidung_vi)
        formData.set("noidung_vi", getValues()?.noidung_vi ?? "");
      if (getValues()?.noidung1_vi)
        formData.set("noidung1_vi", getValues()?.noidung1_vi ?? "");
      if (getValues()?.noidung2_vi)
        formData.set("noidung2_vi", getValues()?.noidung2_vi ?? "");
      if (getValues()?.noidung3_vi)
        formData.set("noidung3_vi", getValues()?.noidung3_vi ?? "");
      if (getValues()?.noidung4_vi)
        formData.set("noidung4_vi", getValues()?.noidung4_vi ?? "");
      if (getValues()?.noidung5_vi)
        formData.set("noidung5_vi", getValues()?.noidung5_vi ?? "");
      if (getValues()?.noidung6_vi)
        formData.set("noidung6_vi", getValues()?.noidung6_vi ?? "");
      if (getValues()?.noidung7_vi)
        formData.set("noidung7_vi", getValues()?.noidung7_vi ?? "");
      formData.set("tag", getValues()?.tag ?? "");
      formData.set("hienthi", getValues()?.hienthi ?? false);
      formData.set("noibat", getValues()?.noibat ?? false);
      formData.set("title_vi", dt.title_vi ?? "");
      formData.set("keywords_vi", dt.keywords_vi ?? "");
      formData.set("description_vi", dt.description_vi ?? "");
      if (id) mutation.mutate({ id: id, data: formData });
      else mutationAdd.mutate(formData);
    } else message.error("Vui lòng nhập đầy đủ thông tin.");
  };

  return (
    <SeoOption
      keyQuery={["library_indicators", id ?? location.state?.id]}
      query={() => libraryApi.getIndicatorDetails(id ?? location.state?.id)}
      link={appRoutes.LIBRARY.INDICATOR.LIST}
      onSubmit={onSubmit}
      setSeo={(e: any) => setSeo(e)}
    />
  );
};

export default SeoIndicator;

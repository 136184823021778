import { Button, Card, Col, Form, message, Row, Select } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import registerApi from "../../../api/registerApi";
import Loading from "../../../components/Loading";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm, status } from "../../../utils/constants";
import { messages } from "../../../utils/messages";

const RegisterDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [sts, setSts] = useState<number | undefined>();
  const { data, isLoading, isFetching } = useQuery(["register_train", id], () =>
    registerApi.getDetails(id)
  );

  queryClient.invalidateQueries("noti");

  const mutation = useMutation(registerApi.updateStatus, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  return (
    <PageWrap
      title="Đăng ký đào tạo"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Đăng ký đào tạo</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false} className="register-form">
                <Form
                  {...formItemLayoutSm}
                  className="slider-form border_form"
                  colon={false}
                >
                  <Form.Item label="Trạng thái">
                    <Select
                      options={status}
                      defaultValue={data?.data?.trangthai}
                      onChange={(e) => setSts(e)}
                    />
                  </Form.Item>
                  <Form.Item label="Họ / Tên" className="empty">
                    <div>{data?.data?.ten}</div>
                  </Form.Item>
                  <Form.Item label="Điện thoại" className="empty">
                    <div>{data?.data?.dienthoai}</div>
                  </Form.Item>
                  <Form.Item label="Email" className="empty">
                    <div>{data?.data?.email}</div>
                  </Form.Item>
                  <Row
                    justify="start"
                    gutter={[10, 0]}
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={() => mutation.mutate({ id: id, status: sts })}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(appRoutes.REGISTER.ROOT)}>
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default RegisterDetails;

import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import contentApi from "../../../../api/contentApi";
import ListContent from "../../../../components/ListContent";
import PageWrap from "../../../../layout/PageWrap";
import { messages } from "../../../../utils/messages";

const ReviewBrokerList = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(contentApi.deleteReviewBroker, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("review_broker");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  return (
    <PageWrap
      title="Review broker"
      content={
        <ListContent
          headContent="Review broker"
          handleRemove={(e) => mutation.mutate(e)}
          action={useMutation(contentApi.editReviewBroker, {
            onSuccess: (data: any) => {
              if (data.statusCode === 200) {
                message.success(messages.updateSuccess);
                queryClient.invalidateQueries("review_broker");
              }
            },
            onError: (err: any) => {
              message.error(messages.error);
            },
          })}
          keyQuery="review_broker"
          link="/noi-dung-bai-viet/review-broker"
          query={contentApi.getReviewBroker}
        />
      }
    />
  );
};

export default ReviewBrokerList;

import PageTopWrapper from "../../../components/page-top-wrapper";
import TableWrap from "../../../components/Table";
import { useQuery } from "react-query";
import { FC, useState, useEffect } from "react";
import accountApi from "../../../api/accountApi";
import { ColumnsType } from "antd/lib/table";

function AboutExpire() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(9);

  const { data, isLoading, isFetching, refetch }: any = useQuery(
    ["sap_het_han", currentPage, limit],
    () => accountApi.expires(currentPage, limit)
  );

  const columns: ColumnsType<any> = [
    {
      title: "#",
      width: "3%",
      align: "center",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Họ và tên",
      dataIndex: "ten",
      key: "ten",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienthoai",
      key: "dienthoai",
    },
    {
      title: "Còn lại",
      width: "10%",
      align: "center",
      dataIndex: "conlai",
      key: "conlai",
      render: (data: any) => `${data} ngày`,
    },
    {
      title: "Gói đăng ký",
      dataIndex: "membership",
      key: "membership",
      width: "10%",
    },
  ];

  return (
    <div className="div-status-chart">
      <PageTopWrapper leftContent={<h3>SẮP HẾT HẠN</h3>} />

      <TableWrap
        loading={isLoading || isFetching}
        columns={columns}
        isShowTotal
        dataSource={data?.data?.source}
        rowKey="_id"
        bordered
        pagination={{
          pageSize: limit,
          current: currentPage,
          total: data?.data?.totalItems,
          onChange: (page: number, pageSize: number) => {
            setCurrentPage(page);
            setLimit(pageSize);
          },
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        className="hover table-slide"
        hasDefaultColumn={false} />
    </div>
  );
}

export default AboutExpire;

import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import categoryApi from "../../../../api/categoryApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";
import { ConvertSlug } from "../../../../utils/functions/convertSlug";

interface NewsFormProps {
  type: number;
}

const NewsForm: FC<NewsFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { data, isLoading, isFetching } = useQuery(
    ["create-category_news", "post", id],
    () => categoryApi.getNewDetails(id),
    { enabled: type === 2 }
  );

  const listCate: any = useQuery(
    ["create-category_categories"],
    categoryApi.getCateNews
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(categoryApi.editNews, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("create-category_news");
        navigate(appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(categoryApi.addNews, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("create-category_news");
        navigate(appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };

  return (
    <PageWrap
      title="Tin tức"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Tin tức</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  <SelectField
                    control={control}
                    label="Chuyên mục"
                    name="category"
                    options={listCate?.data?.data?.map((e: any) => ({
                      key: e.id,
                      value: e.name,
                    }))}
                    defaultValue={
                      data?.data?.category ?? listCate?.data?.data?.[0]?.id
                    }
                  />
                  <InputField
                    label="Tiêu đề"
                    name="ten_vi"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.ten_vi}
                    errorMsg={errors?.ten_vi?.message}
                    rules={{
                      required: "Vui lòng nhập tiêu đề",
                    }}
                    onChange={(e: any) => {
                      setValue("slug_vi", ConvertSlug(e.target.value));
                    }}
                  />
                  <InputField
                    label="Slug"
                    name="slug_vi"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.slug_vi}
                    errorMsg={errors?.slug_vi?.message}
                    rules={{
                      required: "Vui lòng nhập slug",
                      minLength: {
                        value: 10,
                        message: "Vui lòng nhập trên 10 kí tự",
                      },
                    }}
                  />
                  <TextAreaField
                    label="Mô tả"
                    name="mota_vi"
                    control={control}
                    defaultValue={data?.data?.mota_vi}
                    errorMsg={errors?.mota_vi?.message}
                    rules={{
                      required: "Vui lòng nhập mô tả",
                    }}
                  />
                  <InputField
                    label="Link"
                    name="link"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.link}
                    errorMsg={errors?.link?.message}
                    rules={{
                      required: "Vui lòng nhập link",
                    }}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="index"
                    control={control}
                    defaultValue={data?.data?.index ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("index", 1);
                    }}
                    min={1}
                  />
                  <Form.Item className="checkbox">
                    <Row gutter={[20, 5]}>
                      <Col>
                        <Checkbox
                          defaultChecked={data?.data?.premium}
                          name="premium"
                          onClick={(e: any) => {
                            setValue("premium", e.target.checked);
                          }}
                        />
                        &nbsp;Premium
                      </Col>
                      <Col xs={24} sm={20}>
                        <Checkbox
                          defaultChecked={data?.data?.hienthi}
                          name="hienthi"
                          onClick={(e: any) => {
                            setValue("hienthi", e.target.checked);
                          }}
                        />
                        &nbsp;Hiển thị
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(
                            appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST
                          )
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default NewsForm;

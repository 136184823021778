import { Button, Card, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../../components/InputField";
import Loading from "../../../components/Loading";
import SelectField from "../../../components/SelectField";
import TextAreaField from "../../../components/TextAreaField";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import tuvanApi from "../../../api/tuvanApi";
import moment from "moment";
import Ckeditor from "../../../components/Ckeditor";
import "./style.scss";

interface TuVanDetailsProps {
  title: string;
  link: string;
  isGlobal: Boolean;
}
const TuVanDetails: FC<TuVanDetailsProps> = ({ title, link }) => {
  const { id } = useParams();
  const [editor, setEditor]: any = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery(
    ["detail_tu_van", id],
    () => tuvanApi.getDetails(id),
    {
      onSuccess: (data: any) => {
        if (data?.status === 422) navigate(appRoutes.NOT_FOUND);
      },
    }
  );

  const onBeforeLoad = (e: any) => {
    setEditor(e.editor);
  };

  const { handleSubmit, setValue, control, getValues } = useForm();

  const mutation = useMutation(tuvanApi.edit, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await Promise.all([
          queryClient.invalidateQueries(),
          queryClient.invalidateQueries("noti"),
        ]);
        navigate(link);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const mutationTemplate = useMutation(tuvanApi.saveTemplate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success("Đã lưu nội dung vào template");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const mutationLoadTemplate = useMutation(tuvanApi.getTemplate, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        setValue("result", data?.data);
        if (editor) {
          editor.setData(data?.data);
        }
        message.success("Đã load nội dung từ template");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    mutation.mutate({ id: id, data: dt });
  };

  const onSaveTemplate = () => {
    mutationTemplate.mutate(getValues("result"));
  };

  const loadTemplate = () => {
    const content = getValues("result");
    if (content && content.trim().length > 0) {
      var result = window.confirm("Nội dung hiện tại sẽ bị xóa bỏ");
      if (result) {
        mutationLoadTemplate.mutate();
      }
    } else {
      mutationLoadTemplate.mutate();
    }
  };

  queryClient.invalidateQueries("noti");

  const listStatus: any = useQuery("list_status", tuvanApi.getListStatus);

  const formItemLayoutSm = {
    labelCol: {
      sm: { span: 24 },
      md: { span: 4 },
    },
    wrapperCol: {
      sm: { span: 24 },
      md: { span: 20 },
    },
  };

  return (
    <PageWrap
      title={title}
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>{title}</b>
                </Col>
              </Row>
            }
          />
          <div className="content tuvan">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <div className="iframe_tu_van">
                  <iframe
                    src={`${process.env.REACT_APP_DOMAIN_V3}/danh-muc-khach-hang?key=${data?.data?.key_view}&dm=${data?.data?.danhmuc?._id}`}
                    frameBorder="0"
                    aria-hidden="false"
                    width={"100%"}
                    height={"100%"}
                    id="ifarm"
                  ></iframe>
                </div>

                <Form {...formItemLayoutSm}>
                  <Form.Item
                    label="Ghi chú của khách hàng:"
                    colon={false}
                    className="ant-form-item-with-help"
                  >
                    <TextAreaField
                      name=""
                      label=""
                      readOnly
                      defaultValue={data?.data?.note ?? ""}
                      control={control}
                    />
                  </Form.Item>
                  <Form.Item label="Nội dung tư vấn" colon={false}>
                    <Ckeditor
                      initData={data?.data?.result}
                      onChange={(evt: any) =>
                        setValue("result", evt.editor.getData())
                      }
                      onLoaded={onBeforeLoad}
                    ></Ckeditor>
                  </Form.Item>
                  <SelectField
                    name="status"
                    label="Trạng thái"
                    options={listStatus?.data?.data?.map((e: any) => {
                      return {
                        key: e.status,
                        value: e.name,
                      };
                    })}
                    defaultValue={data?.data?.status.status}
                    control={control}
                    listHeight={160}
                  />

                  <InputField
                    control={control}
                    name="email"
                    readOnly
                    label="Email khách hàng"
                    placeholder="Email"
                    defaultValue={data?.data?.user?.email}
                  />
                  <InputField
                    control={control}
                    name="time"
                    readOnly
                    label="Thời gian yêu cầu"
                    placeholder="Thời gian yêu cầu"
                    defaultValue={moment(data?.data?.createdAt).format(
                      "HH:m:ss DD/MM/YYYY"
                    )}
                  />

                  <Row
                    justify="start"
                    gutter={[10, 0]}
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(link)}>Hủy</Button>
                    </Col>

                    <Col>
                      <Button type="ghost" onClick={onSaveTemplate}>
                        Lưu template
                      </Button>
                    </Col>
                    <Col>
                      <Button type="ghost" onClick={loadTemplate}>
                        Load template
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default TuVanDetails;

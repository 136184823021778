import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import contentApi from "../../../../api/contentApi";
import Ckeditor from "../../../../components/Ckeditor";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { loadFile } from "../../../../utils/functions/loadFile";
import { messages } from "../../../../utils/messages";
import "./style.scss";
interface GuideFormProps {
  type: number;
}

const GuideForm: FC<GuideFormProps> = ({ type }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile]: any = useState();
  const [icon, setIcon]: any = useState();
  const { data, isLoading, isFetching } = useQuery(
    ["content_guide", id],
    () => contentApi.getGuideDetails(id),
    { enabled: type === 2 }
  );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [image, setImage]: any = useState();
  const [imageIcon, setImageIcon]: any = useState();
  const handleUpload = async (file: any, isIcon: boolean) => {
    if (file) {
      if (isIcon) setIcon(file);
      else setFile(file);
      await loadFile(file);
      if (isIcon) setImageIcon(file.urlImg);
      else setImage(file.urlImg);
    }
  };
  const mutationEdit = useMutation(contentApi.editGuide, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("content_guide");
        navigate(appRoutes.CONTENT.GUIDE.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      if (err?.status === 422)
        message.error(err?.data?.message ?? err?.data?.message?.error ?? "Lỗi");
      else message.error(messages.error);
    },
  });
  const mutationAdd = useMutation(contentApi.addGuide, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("content_guide");
        navigate(appRoutes.CONTENT.GUIDE.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      if (err?.status === 422)
        message.error(err?.data?.message?.error ?? "Lỗi");
      else message.error(messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("photo", file);
    if (icon) formData.append("icon", icon);
    formData.set("ten_vi", dt.ten_vi ?? "");
    formData.set("stt", dt.stt ?? "");
    formData.set("youtube", dt.youtube ?? "");
    formData.set("mota_vi", dt.mota_vi ?? "");
    formData.set("noidung_vi", dt.noidung_vi ?? data?.data?.noidung_vi ?? "");
    if (type === 1) formData.set("hienthi", dt.hienthi ?? false);
    if (dt.hienthi !== undefined && type === 2)
      formData.set("hienthi", dt.hienthi);
    if (type === 2)
      mutationEdit.mutate({ id: id, data: formData, en: location?.state?.en });
    if (type === 1)
      mutationAdd.mutate({ data: formData, en: location?.state?.en });
  };

  return (
    <PageWrap
      title="Hướng dẫn"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Hướng dẫn</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <>
              {isLoading || isFetching ? (
                <Loading />
              ) : (
                <Card bordered={false}>
                  <Form {...formItemLayoutSm} colon={false}>
                    {(imageIcon || data?.data?.icon) && (
                      <Form.Item label="Icon">
                        <img
                          src={
                            imageIcon ??
                            process.env.REACT_APP_DOMAIN + data?.data?.icon
                          }
                          alt=""
                        />
                      </Form.Item>
                    )}
                    <InputField
                      isImage={true}
                      name="icon"
                      control={control}
                      rules={
                        type === 1
                          ? {
                              required: "Vui lòng chọn hình ảnh",
                            }
                          : undefined
                      }
                      label="Icon"
                      errorMsg={errors?.icon?.message}
                      onChange={(e: any) => {
                        handleUpload(e.target.files?.[0], true);
                      }}
                    />
                    <InputField
                      label="Tiêu đề"
                      name="ten_vi"
                      control={control}
                      setValue={setValue}
                      defaultValue={data?.data?.ten_vi}
                      errorMsg={errors?.ten_vi?.message}
                      rules={{
                        required: "Vui lòng nhập tiêu đề",
                      }}
                    />
                    <TextAreaField
                      label="Mô tả"
                      name="mota_vi"
                      control={control}
                      defaultValue={data?.data?.mota_vi}
                      errorMsg={errors?.mota_vi?.message}
                      rules={{
                        required: "Vui lòng nhập mô tả",
                      }}
                    />
                    <Form.Item label="Nội dung">
                      <Ckeditor
                        initData={data?.data?.noidung_vi}
                        onChange={(evt: any) =>
                          setValue("noidung_vi", evt.editor.getData())
                        }
                      />
                    </Form.Item>
                    <InputField
                      label="Clip youtube"
                      name="youtube"
                      control={control}
                      defaultValue={data?.data?.youtube}
                      errorMsg={errors?.youtube?.message}
                    />
                    <Form.Item label="Hình ảnh">
                      {(image || data?.data?.photo) && (
                        <div className="guide_img">
                          <img
                            src={
                              image ??
                              process.env.REACT_APP_DOMAIN + data?.data?.photo
                            }
                            alt=""
                          />
                        </div>
                      )}
                    </Form.Item>
                    <InputField
                      isImage={true}
                      name="photo"
                      control={control}
                      rules={
                        type === 1
                          ? {
                              required: "Vui lòng chọn hình ảnh",
                            }
                          : undefined
                      }
                      label="Hình ảnh"
                      errorMsg={errors?.photo?.message}
                      onChange={(e: any) => {
                        handleUpload(e.target.files?.[0], false);
                      }}
                    />
                    <InputNumberWrap
                      label="STT"
                      name="stt"
                      control={control}
                      defaultValue={data?.data?.stt ?? 1}
                      onBlur={(e: any) => {
                        if (e.target.value === "") setValue("stt", 1);
                      }}
                      min={1}
                    />
                    <Form.Item className="checkbox">
                      <Row>
                        <Col xs={24} sm={20}>
                          <Checkbox
                            defaultChecked={data?.data?.hienthi}
                            name="hienthi"
                            onClick={(e: any) => {
                              setValue("hienthi", e.target.checked);
                            }}
                          />
                          &nbsp;Hiển thị
                        </Col>
                      </Row>
                    </Form.Item>
                    <Row justify="start" gutter={[10, 0]}>
                      <Col>
                        <Button
                          type="primary"
                          danger
                          onClick={handleSubmit(onSubmit)}
                        >
                          Lưu
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => navigate(appRoutes.CONTENT.GUIDE.LIST)}
                        >
                          Hủy
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              )}
            </>
          </div>
        </>
      }
    />
  );
};

export default GuideForm;

import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { appRoutes } from "./routes";

interface IProps {}

const OpenSiteRoutes: FC<IProps> = (props) => {
  const location = useLocation();

  return sessionStorage.getItem("token") ? (
    <Navigate to={"/"} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default OpenSiteRoutes;

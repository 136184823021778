import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Button, Form, message } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import InputField from "../../components/InputField";
import PageWrap from "../../layout/PageWrap";
import { appRoutes } from "../../routes/routes";
import { saveDataToSessionStorage } from "../../utils/functions/saveDataToSessionStorage";
import { messages } from "../../utils/messages";
import "./style.scss";

const Login = () => {
  const [enable, setEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const profile = useQuery("profile", authApi.getProfile, {
    enabled: enable,
  });

  useEffect(() => {
    if (profile?.data && enable) {
      sessionStorage?.setItem("profile", JSON.stringify(profile?.data?.data));
      setEnable(false);
      navigate(profile?.data?.data?.role == 4  ? "/tu-van-danh-muc" : "/");
    }
  }, [profile?.data]);

  const mutation = useMutation(authApi.login, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        setLoading(false);
        if (data?.data?.verify2Fa) {
          navigate(appRoutes.AUTH.LOGIN_2F, {
            state: { verifyCode: data?.data?.verifyCode },
          });
          saveDataToSessionStorage("verifyCode", "", 10);
          setEnable(false);
        } else {
          setLoading(false);
          setEnable(data.role == 4 ? false : true);
          message.success("Đăng nhập thành công.");
          sessionStorage.setItem("token", data?.data);
        }
      } else {
        setLoading(false);
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      setLoading(false);
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    if (loading) return;
    setLoading(true);
    mutation.mutate(dt);
  };

  return (
    <PageWrap
      title="Login"
      content={
        <div className="login">
          <div className="login__wrap">
            <h1>Login</h1>
            <Form>
              <InputField
                name="email"
                control={control}
                placeholder="Email"
                label="Email"
                rules={{
                  required: "Vui lòng nhập email",
                }}
                errorMsg={errors?.email?.message}
                onKeyDown={(e: any) =>
                  e.keyCode === 13 ? handleSubmit(onSubmit)() : undefined
                }
              />
              <InputField
                name="password"
                isPassword={true}
                control={control}
                placeholder="Password"
                label="Password"
                rules={{
                  required: "Vui lòng nhập mật khẩu",
                }}
                onKeyDown={(e: any) =>
                  e.keyCode === 13 ? handleSubmit(onSubmit)() : undefined
                }
                errorMsg={errors?.password?.message}
              />
              <Button
                htmlType="submit"
                onClick={handleSubmit(onSubmit)}
                type="primary"
              >
                {loading ? "Đang kiểm tra..." : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      }
    />
  );
};

export default Login;

import { Form, Select } from "antd";
import { FC } from "react";
import { Controller } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";

const { Option } = Select;

interface IOption {
  key: string | number | boolean;
  value: string | any;
}

interface ISelectFieldProps {
  label?: string | any;
  name: string;
  required?: boolean;
  message?: string;
  options: Array<IOption>;
  placeholder?: string;
  allowClear?: boolean;
  disable?: boolean;
  defaultValue?: string;
  help?: React.ReactNode;
  error?: string;
  className?: string;
  isSearch?: boolean;
  listHeight?: number;
  loading?: boolean;
  disableOption?: any;
  autoFocus?: boolean;
  isElementValue?: boolean;
  control?: any;
  rules?: any;
  errorMsg?: any;
  onChange?: any;
  style?: any;
}

const SelectField: FC<ISelectFieldProps> = ({
  label,
  name,
  required,
  message,
  placeholder,
  options,
  allowClear,
  disable,
  defaultValue,
  help,
  className,
  isSearch,
  listHeight,
  loading,
  disableOption,
  autoFocus,
  isElementValue = false,
  control,
  rules,
  errorMsg,
  onChange,
  style
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Form.Item
          label={label}
          help={<ErrorMessage message={errorMsg} />}
          colon={false}
          className={errorMsg ? "error-field" : ""}
        >
          <Select
            style={style}
            placeholder={placeholder}
            allowClear={allowClear}
            disabled={disable}
            {...field}
            showSearch={isSearch ?? undefined}
            filterOption={
              isSearch
                ? (input, option: any) => {
                    return (
                      isElementValue
                        ? (option!.children?.props?.title as unknown as string)
                        : (option!.children as unknown as string)
                    )
                      .toLowerCase()
                      .includes(input?.toLowerCase());
                  }
                : undefined
            }
            listHeight={listHeight ?? 100}
            loading={loading}
            autoFocus={autoFocus ?? undefined}
            onChange={(e: any) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
          >
            {options?.map(({ key, value }, index) => {
              return (
                <Option
                  key={index}
                  value={key}
                  disabled={
                    disableOption ? disableOption.includes(key) : undefined
                  }
                >
                  {value}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    />
  );
};

export default SelectField;

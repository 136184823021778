import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { FC } from "react";
import Loading from "../../../components/Loading";
import { formItemLayout } from "../../../utils/constants";
import { loadFile } from "../../../utils/functions/loadFile";

interface CashFormProps {
  loading: boolean;
  image?: any;
  time_vi: string | undefined;
  url: string;
  setFile: (e: any) => void;
  setImage: (e: any) => void;
  setUpdatedTime: (e: any) => void;
  handleSubmit: () => void;
}
const CashForm: FC<CashFormProps> = ({
  loading,
  image,
  time_vi,
  url,
  setFile,
  setImage,
  setUpdatedTime,
  handleSubmit,
}) => {
  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card bordered={false}>
          <div className="cash_form">
            <Form {...formItemLayout}>
              <div className="cash_img">
                <img src={image ?? process.env.REACT_APP_DOMAIN + url} alt="" />
              </div>
              <div className="cash-form-item">
                <span>Bản đồ tăng trưởng</span>
                <Form.Item>
                  <Input
                    name="url"
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => {
                      handleUpload(e.target.files?.[0]);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="cash-form-item">
                <span>Thời gian update</span>
                <Form.Item>
                  <Input
                    name="time_vi"
                    placeholder="Thời gian update"
                    onChange={(e) => {
                      setUpdatedTime(e.target.value);
                    }}
                    defaultValue={time_vi}
                  />
                </Form.Item>
              </div>
              <Row justify="start" gutter={[10, 0]}>
                <Col>
                  <Button type="primary" danger onClick={handleSubmit}>
                    Lưu
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      )}
    </>
  );
};

export default CashForm;

import { Button, Card, Col, Form, message, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import tuvanApi from "../../../api/tuvanApi";

import SelectField from "../../../components/SelectField";
import TableWrap from "../../../components/Table";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";

interface ListTuVanProps {
  title: string;
  link: string;
}
const ListTuVan: FC<ListTuVanProps> = ({ title, link }) => {
  let cache: any = sessionStorage.getItem("profile");

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const isAdminTuVan = JSON.parse(cache).role == 4;

  const [trangthai, setTrangthai] = useState<number | undefined>();
  const [chuyengia, setChuyenGia] = useState<number | undefined>();

  const { handleSubmit, control, setValue } = useForm();

  const { data, isLoading, isFetching }: any = useQuery(
    [currentPage, limit, trangthai, chuyengia],
    () => tuvanApi.getAll(currentPage, limit, trangthai, chuyengia)
  );

  const listStatus: any = useQuery("list_status", tuvanApi.getListStatus);

  const listChuyengia: any = useQuery(
    "list_chuyen_gia",
    tuvanApi.getListChuyenggia
  );

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "index",
      align: "center",
      key: "index",
      width: "3%",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "user",
      key: "user",
      render: (record: any) => {
        if (record?.length === 0) return <></>;
        else return <>{record.ten}</>;
      },
    },
    {
      title: "Email khách hàng",
      dataIndex: "user",
      key: "user",
      render: (record: any) => {
        if (record?.length === 0) return <></>;
        else return <>{record.email}</>;
      },
    },
    {
      title: "Tên danh mục",
      dataIndex: "danhmuc",
      key: "danhmuc",
      render: (record: any) => {
        if (record?.length === 0) return <></>;
        else return <>{record.ten_danh_muc}</>;
      },
    },
    {
      title: "Chuyên gia tư vấn",
      dataIndex: "chuyengia",
      key: "chuyengia",
      render: (record: any) => {
        if (record?.length === 0) return <></>;
        else return <>{record.name}</>;
      },
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record: string) => {
        if (record?.length === 0) return <></>;
        else return <>{moment(record).format("HH:mm DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Trạng Thái",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        if (record?.length === 0) return <></>;
        else return <>{record.name}</>;
      },
    },
  ];

  const onFilter = (dt: any) => {
    setTrangthai(dt.status);
    setChuyenGia(dt.chuyengia);
    setCurrentPage(1);
  };

  return (
    <PageWrap
      title={title}
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>{title}</b>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <>
              <Form className="register_common__form">
                <Row
                  style={{ marginBottom: "30px" }}
                  align="bottom"
                  gutter={[20, 10]}
                >
                  <Col md={19} sm={24} xs={24}>
                    <Row gutter={[20, 10]}>
                      {!isAdminTuVan && (
                        <Col md={4} sm={24} xs={12}>
                          <SelectField
                            label="Chuyên gia"
                            placeholder="Chuyên gia"
                            options={listChuyengia?.data?.data?.map(
                              (e: any) => ({
                                key: e.id,
                                value: e.name,
                              })
                            )}
                            control={control}
                            name="chuyengia"
                            allowClear={true}
                            listHeight={128}
                          />
                        </Col>
                      )}
                      <Col md={4} sm={24} xs={12}>
                        <SelectField
                          label="Trạng thái tư vấn"
                          placeholder="Trạng thái tư vấn"
                          options={listStatus?.data?.data?.map((e: any) => {
                            return {
                              key: e.status,
                              value: e.name,
                            };
                          })}
                          control={control}
                          name="status"
                          allowClear={true}
                          listHeight={200}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5} sm={24} xs={24}>
                    <Row gutter={[20, 10]} justify="end">
                      <Col>
                        <Button
                          htmlType="submit"
                          className="primary_btn"
                          onClick={handleSubmit(onFilter)}
                        >
                          Lọc
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TableWrap
                rowClassName="cursor_pointer"
                loading={isLoading || isFetching}
                columns={columns}
                isShowTotal
                dataSource={data?.data?.source}
                onRowClick={(dt: any) => navigate(`${link}/${dt?._id}`)}
                rowKey="_id"
                bordered
                pagination={{
                  pageSize: limit,
                  current: currentPage,
                  total: data?.data?.totalItems,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page);
                    setLimit(pageSize);
                  },
                }}
                className="hover table-slide"
                hasDefaultColumn={false}
              />
            </>
          </Card>
        </div>
      }
    />
  );
};

export default ListTuVan;

export const saveDataToSessionStorage = (key: string, data: any, expirationTime: number) => {
  const now = new Date().getTime();
  const expirationMS = expirationTime * 60 * 1000; // Convert minutes to milliseconds

  const item = {
    data: data,
    expiration: now + expirationMS, // Calculate expiration time
  };

  sessionStorage.setItem(key, JSON.stringify(item));
};

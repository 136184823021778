import { Button, Card, Checkbox, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import telegramApi from "../../../../api/telegramApi";
import thongTinKhoaHocApi from "../../../../api/thongTinKhoaHoc";
import trainApi from "../../../../api/trainApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import SelectField from "../../../../components/SelectField";
import TextAreaField from "../../../../components/TextAreaField";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { messages } from "../../../../utils/messages";

interface DanhGiaProps {
  type: number;
}

const DanhGiaForm: FC<DanhGiaProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["chi_tiet_danh_gia", "category", id],
    () => thongTinKhoaHocApi.getDetailPhanHoi(id),
    {
      enabled: type === 2,
    }
  );

  const listCate: any = useQuery(
    ["training_post", "list-cate"],
    trainApi.getCate
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const mutationEdit = useMutation(thongTinKhoaHocApi.editPhanHoi, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("chi_tiet_danh_gia");
        navigate(appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(thongTinKhoaHocApi.addPhanHoi, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("training_danh_gia_khoa_hoc");
        navigate(appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    if (type === 2) mutationEdit.mutate({ id: id, data: dt });
    if (type === 1) mutationAdd.mutate(dt);
  };

  return (
    <PageWrap
      title="Đánh giá khóa học"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Đánh giá khóa học</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  <InputField
                    label="Người đánh giá"
                    name="name"
                    control={control}
                    setValue={setValue}
                    defaultValue={data?.data?.name}
                    errorMsg={errors?.name?.message}
                    rules={{
                      required: "Vui lòng nhập tên",
                    }}
                  />
                  <SelectField
                    control={control}
                    label="Chuyên mục"
                    name="danh_muc"
                    options={listCate?.data?.data?.map((e: any) => ({
                      key: e._id,
                      value: e.ten_vi,
                    }))}
                    defaultValue={data?.data?.danh_muc}
                    errorMsg={errors?.danh_muc?.message}
                    rules={{
                      required: "Vui lòng chọn chuyên mục",
                    }}
                  />
                  <TextAreaField
                    label="Nội dung đánh giá"
                    name="noidung"
                    control={control}
                    defaultValue={data?.data?.noidung}
                    errorMsg={errors?.noidung?.message}
                  />
                  <InputNumberWrap
                    label="Star"
                    name="star"
                    control={control}
                    defaultValue={data?.data?.star ?? 5}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("star", 5);
                    }}
                    min={1}
                    max={5}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="stt"
                    control={control}
                    defaultValue={data?.data?.stt ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("stt", 1);
                    }}
                    min={1}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST)
                        }
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default DanhGiaForm;

import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import authApi from "../../api/authApi";
import InputField from "../../components/InputField";
import Loading from "../../components/Loading";
import Popup from "../../components/Popup";
import TopContent from "../../components/TopContent";
import TwoFactorAuth from "../../components/TwoFactorAuth";
import PageWrap from "../../layout/PageWrap";
import { formItemLayoutSm } from "../../utils/constants";
import { messages } from "../../utils/messages";

const Profile = () => {
  const [info2F, setInfo2F]: any = useState();
  const [open, setOpen]: any = useState();
  const [enable, setEnable]: any = useState();
  const [data, setData]: any = useState();
  const [saveDt, setSaveDt]: any = useState();
  const { handleSubmit, control } = useForm();
  const check = useQuery("check", authApi.checkRegister2F);
  const data2F: any = useQuery("info2F", authApi.getInfo2F, {
    enabled: enable,
  });

  const mutation = useMutation(authApi.updateProfile, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        const cache: any = sessionStorage.getItem("profile");
        sessionStorage.setItem(
          "profile",
          JSON.stringify({
            ...JSON.parse(cache),
            ten: saveDt?.ten,
            dienthoai: saveDt?.dienthoai,
          })
        );
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = async (dt: any) => {
    await setSaveDt(dt);
    mutation.mutate(dt);
  };

  useEffect(() => {
    let cache: any = sessionStorage.getItem("profile");
    if (cache) setData(JSON.parse(cache));
  }, [sessionStorage?.getItem("profile")]);

  const handleCheck = async () => {
    if (check?.data?.data) {
      const response: any = await authApi.disable2F();
      if (response?.statusCode == 200) {
        window.location.reload();
        message.success("Huỷ đăng ký xác thực thành công");
      } else {
        message.error("Đã có lỗi");
        check.refetch();
      }
    } else {
      setInfo2F(data2F?.data?.data);
      setOpen(true);
      setEnable(true);
    }
  };

  return (
    <PageWrap
      title="Cài đặt tài khoản"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Cài đặt tài khoản</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {!data ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm}>
                  <Row gutter={[40, 0]}>
                    <Col md={12} sm={24}>
                      <Form.Item label="Email">
                        <Input
                          disabled
                          placeholder="Email"
                          defaultValue={data?.email}
                        />
                      </Form.Item>
                      <InputField
                        isPassword={true}
                        label="Mật khẩu"
                        name="password"
                        placeholder="********"
                        control={control}
                      />
                    </Col>
                    <Col md={12} sm={24}>
                      <InputField
                        label="Họ và tên"
                        name="ten"
                        placeholder="Họ và tên"
                        control={control}
                        defaultValue={data?.ten}
                      />
                      <InputField
                        label="Điện thoại"
                        name="dienthoai"
                        placeholder="Điện thoại"
                        control={control}
                        defaultValue={data?.dienthoai}
                      />
                    </Col>
                  </Row>
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" onClick={handleCheck}>
                        {check?.data?.data
                          ? "Huỷ đăng ký xác thực"
                          : "Đăng ký xác thực"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
          {open && (
            <Popup
              content={
                <TwoFactorAuth
                  base32={info2F?.base32}
                  otpauth_url={info2F?.otpauth_url}
                  handleClose={() => setOpen(false)}
                />
              }
              show={open}
              handleClose={() => setOpen(false)}
              title="Đăng ký xác thực"
              maskClosable={true}
            />
          )}
        </>
      }
    />
  );
};

export default Profile;

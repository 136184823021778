import { Button, Card, Col, Form, message, Row } from "antd";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import sliderApi from "../../../../api/sliderApi";
import InputField from "../../../../components/InputField";
import InputNumberWrap from "../../../../components/InputNumber";
import Loading from "../../../../components/Loading";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { formItemLayoutSm } from "../../../../utils/constants";
import { loadFile } from "../../../../utils/functions/loadFile";
import { messages } from "../../../../utils/messages";

interface SliderGuideFormProps {
  type: number;
}

const SliderGuideForm: FC<SliderGuideFormProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [file, setFile]: any = useState();
  const { data, isLoading }: any = useQuery(
    ["guide-slider", id],
    () => sliderApi.getGuideSliderDetails(id),
    {
      enabled: !!id && type === 2,
      onSuccess: (dt: any) => {
        if (dt?.statusCode !== 200) navigate("/404");
      },
    }
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [image, setImage]: any = useState();
  const handleUpload = async (file: any) => {
    if (file) {
      setFile(file);
      await loadFile(file);
      setImage(file.urlImg);
    }
  };
  const mutationEdit = useMutation(sliderApi.editGuideSlider, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await queryClient.invalidateQueries("guide-slider");
        navigate(appRoutes.SLIDERS.GUIDE.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationAdd = useMutation(sliderApi.addGuideSlider, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.addSuccess);
        await queryClient.invalidateQueries("guide-slider");
        navigate(appRoutes.SLIDERS.GUIDE.LIST);
        window.scrollTo(0, 0);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const onSubmit = (dt: any) => {
    const formData = new FormData();
    if (file) formData.append("banner", file);
    formData.set("url", dt.url ?? "");
    formData.set("index", dt.index ?? "");
    if (type === 2) mutationEdit.mutate({ id: id, data: formData });
    if (type === 1) mutationAdd.mutate(formData);
  };

  return (
    <PageWrap
      title="Slider hướng dẫn"
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Slider hướng dẫn</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  {(image || data?.data?.photo) && (
                    <div className="cash_img">
                      <img
                        src={
                          image ??
                          process.env.REACT_APP_DOMAIN + data?.data?.photo
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <InputField
                    isImage={true}
                    name="banner"
                    control={control}
                    rules={
                      type === 1
                        ? {
                            required: "Vui lòng chọn hình ảnh",
                          }
                        : undefined
                    }
                    label="Hình ảnh"
                    errorMsg={errors?.banner?.message}
                    onChange={(e: any) => {
                      handleUpload(e.target.files?.[0]);
                    }}
                  />
                  <InputField
                    label="Link"
                    name="url"
                    setValue={setValue}
                    control={control}
                    defaultValue={data?.data?.url}
                  />
                  <InputNumberWrap
                    label="STT"
                    name="index"
                    control={control}
                    defaultValue={data?.data?.index ?? 1}
                    onBlur={(e: any) => {
                      if (e.target.value === "") setValue("index", 1);
                    }}
                    min={1}
                  />
                  <Row justify="start" gutter={[10, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => navigate(appRoutes.SLIDERS.GUIDE.LIST)}
                      >
                        Hủy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default SliderGuideForm;

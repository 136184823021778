import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import categoryApi from "../../../../api/categoryApi";
import CheckboxSlider from "../../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";

const ListNews = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(40);

  const { data, isLoading, isFetching }: any = useQuery(
    ["create-category_news", currentPage, limit],
    () => categoryApi.getListNews(currentPage, limit)
  );

  const mutation = useMutation(categoryApi.deletePost, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("create-category_news");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });
  const mutationEdit = useMutation(categoryApi.editNews, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("create-category_news");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Danh mục",
      dataIndex: "danhmuc",
      key: "danhmuc",
      width: "12%",
    },
    {
      title: "Tiêu đề",
      dataIndex: "ten_vi",
      key: "ten_vi",
    },
    {
      title: "Mô tả",
      dataIndex: "mota_vi",
      key: "mota_vi",
    },
    {
      title: "Ngày đăng",
      dataIndex: "ngaydang",
      key: "ngaydang",
      width: "8%",
      render: (record: string) => {
        return <>{moment(record).format("HH:mm DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      align: "center",
      width: "7%",
      render: (record: boolean, data: any) => (
        <CheckboxSlider
          _id={data._id}
          check={record}
          dataName="premium"
          action={mutationEdit}
        />
      ),
    },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      align: "center",
      width: "7%",
      render: (record: boolean, data: any) => (
        <CheckboxSlider
          _id={data._id}
          check={record}
          dataName="hienthi"
          action={mutationEdit}
        />
      ),
    },
    {
      title: "Sửa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(
                `${appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST}/${record?._id}`
              )
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <PageWrap
      title="Tin tức"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Tin tức</b>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      navigate(appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.ADD)
                    }
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data?.source}
              rowKey="_id"
              bordered
              pagination={{
                pageSize: limit,
                current: currentPage,
                total: data?.data?.totalItems,
                onChange: (page: number, pageSize: number) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                },
              }}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </div>
      }
    />
  );
};

export default ListNews;

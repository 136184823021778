export const options = [
  { value: "0", label: "Thấp" },
  { value: "1", label: "Trung bình thấp" },
  { value: "2", label: "Trung bình" },
  { value: "3", label: "Trung bình cao" },
  { value: "4", label: "Cao" },
];
export const stts = [
  {
    key: 1,
    value: "Chưa xem",
  },
  {
    key: 2,
    value: "Đã xem",
  },
  {
    key: 3,
    value: "Đã thanh toán",
  },
  {
    key: 4,
    value: "Đã hủy",
  },
  {
    key: 5,
    value: "Đã hồi âm",
  },
];

export const status = [
  {
    value: 1,
    label: "Chưa xem",
  },
  {
    value: 2,
    label: "Đã xem",
  },
  {
    value: 3,
    label: "Đã thanh toán",
  },
  {
    value: 4,
    label: "Đã hủy",
  },
  {
    value: 5,
    label: "Đã hồi âm",
  },
];

export const memberType = [
  {
    key: 0,
    value: "Free",
  },
  {
    key: 1,
    value: "Standard",
  },
  {
    key: 2,
    value: "Premium",
  },
  {
    key: 3,
    value: "VIP",
  },
];

export const roles = [
  {
    key: 1,
    value: "User",
  },
  {
    key: 2,
    value: "Editor",
  },
  {
    key: 3,
    value: "Admin",
  },
  {
    key: 6,
    value: "Sales",
  },
];

export const dk_chuyen_gia_option = [
  {
    key: 1,
    value: "Đã đăng ký chuyên gia",
  },
  {
    key: 2,
    value: "Chưa đăng ký chuyên gia",
  },
];

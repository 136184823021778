import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import orderApi from "../../../api/orderApi";
import DateTimePicker from "../../../components/DateTimePicker";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import TableWrap from "../../../components/Table";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { stts } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import useWindowDimensions from "../../../hook/WindowDimensions";

interface ListOrderProps {
  title: string;
  link: string;
  isGlobal: Boolean;
}
const ListOrder: FC<ListOrderProps> = ({ title, link, isGlobal }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [code, setCode] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [hoten, setHoten] = useState<string | undefined>();
  const [dienthoai, setDienthoai] = useState<string | undefined>();
  const [ngaytao, setNgaytao] = useState<string | undefined>();
  const [trangthai, setTrangthai] = useState<number | undefined>();
  const [exprt, setExport] = useState(false);
  const { isMobile } = useWindowDimensions();

  const exportData = useQuery(
    isGlobal ? "order_global_download" : "order_download",
    () => orderApi.download(isGlobal),
    {
      enabled: exprt,
    }
  );

  const { handleSubmit, control, setValue } = useForm();

  const { data, isLoading, isFetching }: any = useQuery(
    [
      isGlobal ? "order_global" : "order",
      currentPage,
      limit,
      code,
      email,
      hoten,
      dienthoai,
      ngaytao,
      trangthai,
    ],
    () =>
      orderApi.getAll(
        isGlobal,
        currentPage,
        limit,
        code,
        email,
        hoten,
        dienthoai,
        ngaytao,
        trangthai
      )
  );
  const mutation = useMutation(orderApi.delete, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries(isGlobal ? "order_global" : "order");
        queryClient.invalidateQueries("noti");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = isMobile
    ? [
        {
          title: "STT",
          dataIndex: "index",
          align: "center",
          key: "index",
          width: "3%",
        },
        {
          title: "CODE",
          dataIndex: "code",
          key: "code",
          width: "8%",
        },
        {
          title: "Họ tên",
          dataIndex: "hoten",
          key: "hoten",
          width: "10%",
        },
        {
          title: "Điện thoại",
          dataIndex: "dienthoai",
          key: "dienthoai",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: "10%",
        },
        {
          title: "Gói",
          dataIndex: "goi",
          key: "goi",
        },

        {
          title: "Tổng",
          align: "right",
          dataIndex: "tonggia",
          key: "tonggia",
        },
        {
          title: "Ngày đặt",
          dataIndex: "ngaytao",
          key: "ngaytao",
          render: (record: string) => {
            if (record?.length === 0) return <></>;
            else return <>{moment(record).format("HH:mm DD/MM")}</>;
          },
        },
        {
          title: "HTTT",
          dataIndex: "httt",
          key: "httt",
        },
        {
          title: "Trạng Thái",
          dataIndex: "trangthai",
          key: "trangthai",
        },
        {
          title: "Xóa",
          align: "center",
          width: "4%",
          render: (_, record) => {
            return (
              <Popconfirm
                title="Xác nhận xóa mục này"
                style={{ width: "100px" }}
                onConfirm={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  mutation.mutate({ id: record?._id, isGlobal: isGlobal });
                }}
              >
                <DeleteOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  style={{ color: "red" }}
                />
              </Popconfirm>
            );
          },
        },
      ]
    : [
        {
          title: "STT",
          dataIndex: "index",
          align: "center",
          key: "index",
          width: "3%",
        },
        {
          title: "CODE",
          dataIndex: "code",
          key: "code",
          width: "8%",
        },
        {
          title: "Họ tên",
          dataIndex: "hoten",
          key: "hoten",
          width: "10%",
        },
        {
          title: "Điện thoại",
          dataIndex: "dienthoai",
          key: "dienthoai",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: "10%",
        },
        {
          title: "Gói",
          dataIndex: "goi",
          key: "goi",
        },
        {
          title: "Mã KM",
          dataIndex: "magiamgia",
          key: "magiamgia",
          width: "6%",
        },
        {
          title: "Discount",
          align: "right",
          dataIndex: "discount",
          key: "discount",
          render: (record: string) => <>{record ? record : "0"}%</>,
        },
        {
          title: "Tổng",
          align: "right",
          dataIndex: "tonggia",
          key: "tonggia",
        },
        {
          title: "Ngày đặt",
          dataIndex: "ngaytao",
          key: "ngaytao",
          render: (record: string) => {
            if (record?.length === 0) return <></>;
            else return <>{moment(record).format("HH:mm DD/MM/YYYY")}</>;
          },
        },
        {
          title: "HTTT",
          dataIndex: "httt",
          key: "httt",
        },
        {
          title: "Trạng Thái",
          dataIndex: "trangthai",
          key: "trangthai",
        },
        {
          title: "Ghi chú",
          dataIndex: "ghichu",
          key: "ghichu",
          width: "8%",
        },
        {
          title: "Xóa",
          align: "center",
          width: "4%",
          render: (_, record) => {
            return (
              <Popconfirm
                title="Xác nhận xóa mục này"
                style={{ width: "100px" }}
                onConfirm={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  mutation.mutate({ id: record?._id, isGlobal: isGlobal });
                }}
              >
                <DeleteOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  style={{ color: "red" }}
                />
              </Popconfirm>
            );
          },
        },
      ];

  const onFilter = (dt: any) => {
    setCode(dt.code);
    setEmail(dt.email);
    setDienthoai(dt.dienthoai);
    setHoten(dt.hoten);
    setNgaytao(dt.ngaytao ? dt.ngaytao.format("DD/MM/YYYY") : undefined);
    setTrangthai(dt.trangthai);
    setCurrentPage(1);
  };

  useEffect(() => {
    setExport(false);
  }, [exportData]);

  return (
    <PageWrap
      title={title}
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>{title}</b>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <>
              <Form className="register_common__form">
                <Row
                  style={{ marginBottom: "30px" }}
                  align="bottom"
                  gutter={[20, 10]}
                >
                  <Col md={19} sm={24} xs={24}>
                    <Row gutter={[20, 10]}>
                      <Col md={4} sm={24} xs={12}>
                        <InputField
                          label="Mã đơn hàng"
                          placeholder="Mã đơn hàng"
                          name="code"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <InputField
                          label="Email"
                          placeholder="Email khách hàng"
                          name="email"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <InputField
                          label="Tên"
                          placeholder="Tên khách hàng"
                          name="hoten"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <InputField
                          label="Điện thoại"
                          placeholder="Điện thoại"
                          name="dienthoai"
                          control={control}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <Controller
                          name="ngaytao"
                          control={control}
                          render={({ field }) => (
                            <DateTimePicker
                              defaultValue={undefined}
                              props={{
                                ...field,
                                onBlur: (e: any) => {
                                  if (e.target.value === "")
                                    setValue("ngaytao", undefined);
                                  else if (
                                    moment(
                                      e.target.value,
                                      "DD/MM/YYYY",
                                      true
                                    )?.isValid()
                                  )
                                    setValue(
                                      "ngaytao",
                                      moment(e.target.value, "DD/MM/YYYY")
                                    );
                                  else setValue("ngaytao", moment());
                                },
                              }}
                              label="Ngày đặt"
                              allowClear={true}
                              action={handleSubmit(onFilter)}
                            />
                          )}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <SelectField
                          label="Trạng thái đơn hàng"
                          placeholder="Trạng thái đơn hàng"
                          options={stts}
                          control={control}
                          name="trangthai"
                          allowClear={true}
                          listHeight={128}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5} sm={24} xs={24}>
                    <Row gutter={[20, 10]} justify="end">
                      <Col>
                        <Button
                          htmlType="submit"
                          className="primary_btn"
                          onClick={handleSubmit(onFilter)}
                        >
                          Lọc
                        </Button>
                      </Col>

                      <Col>
                        <Button
                          className="export_btn"
                          onClick={() => setExport(true)}
                          loading={
                            exportData?.isLoading || exportData?.isFetching
                          }
                        >
                          Export Excel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TableWrap
                rowClassName="cursor_pointer"
                loading={isLoading || isFetching}
                columns={columns}
                isShowTotal
                dataSource={data?.data?.source}
                onRowClick={(dt: any) => navigate(`${link}/${dt?._id}`)}
                rowKey="_id"
                bordered
                pagination={{
                  pageSize: limit,
                  current: currentPage,
                  total: data?.data?.totalItems,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page);
                    setLimit(pageSize);
                  },
                }}
                className="hover table-slide"
                hasDefaultColumn={false}
              />
            </>
          </Card>
        </div>
      }
    />
  );
};

export default ListOrder;

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import libraryApi from "../../../../api/libraryApi";
import { Duplicate } from "../../../../assets/icon/duplicate";
import CheckboxSlider from "../../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../../components/Table";
import TopContent from "../../../../components/TopContent";
import PageWrap from "../../../../layout/PageWrap";
import { appRoutes } from "../../../../routes/routes";
import { messages } from "../../../../utils/messages";
import Ratings from "./Ratings";

const ListIndicators = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(40);

  const { data, isLoading, isFetching }: any = useQuery(
    ["library_indicators", currentPage, limit],
    () => libraryApi.getIndicators(currentPage, limit)
  );

  const mutation = useMutation(libraryApi.deleteIndicator, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("library_indicators");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const update = useMutation(libraryApi.editIndicator, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("library_indicators");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      align: "center",
      width: "5%",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Chuyên mục",
      dataIndex: "chuyenmuc",
      key: "chuyenmuc",
      width: "10%",
    },
    {
      title: "Tên",
      dataIndex: "ten_vi",
      key: "ten_vi",
      width: "20%",
    },
    {
      title: "Phí hỗ trợ",
      dataIndex: "gia",
      key: "gia",
      width: "10%",
    },
    {
      title: "Hình ảnh",
      dataIndex: "photo",
      width: "20%",
      key: "photo",
      className: "td-slider-picture",
      render: (record: string) => {
        return (
          <img
            src={`${process.env.REACT_APP_DOMAIN}${record}`}
            alt=""
            className="slider-picture"
          ></img>
        );
      },
    },
    {
      title: "File download",
      dataIndex: "thumb",
      key: "thumb",
      width: "10%",
      render: (record: string) => (
        <a
          href={`${process.env.REACT_APP_DOMAIN}${record}`}
          download={record?.split("/").reverse()[0]}
        >
          {record?.split("/").reverse()[0]}
        </a>
      ),
    },
    {
      title: "Rating",
      dataIndex: "rading",
      key: "rading",
      width: "15%",
      render: (record: any, dt: any) => (
        <Ratings defaultStar={record - 1} id={dt._id} event={true} />
      ),
    },
    {
      title: "Nổi bật",
      dataIndex: "noibat",
      align: "center",
      width: "8%",
      key: "noibat",
      render: (record: boolean, data: any) => {
        return (
          <CheckboxSlider
            _id={data?._id}
            check={record}
            action={update}
            formName="noibat"
          />
        );
      },
    },
    {
      title: "Hiển thị",
      dataIndex: "hienthi",
      align: "center",
      width: "8%",
      key: "hienthi",
      render: (record: boolean, data: any) => {
        return (
          <CheckboxSlider
            _id={data?._id}
            check={record}
            action={update}
            formName="hienthi"
          />
        );
      },
    },
    {
      title: "Duplicate",
      align: "center",
      width: "8%",
      render: (_, record: any) => {
        return (
          <div
            className="copy_wrap"
            onClick={() =>
              navigate(`${appRoutes.LIBRARY.INDICATOR.ADD}`, {
                state: { id: record._id },
              })
            }
          >
            <Duplicate />
          </div>
        );
      },
    },
    {
      title: "Sửa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <EditOutlined
            onClick={() =>
              navigate(`${appRoutes.LIBRARY.INDICATOR.LIST}/${record?._id}`)
            }
          />
        );
      },
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record: any) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <PageWrap
      title="Các chỉ báo"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Các chỉ báo</b>
                </Col>
                <Col>
                  <Button
                    onClick={() => navigate(appRoutes.LIBRARY.INDICATOR.ADD)}
                  >
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <TableWrap
              loading={isLoading || isFetching}
              columns={columns}
              isShowTotal
              dataSource={data?.data?.source}
              rowKey="_id"
              bordered
              pagination={{
                pageSize: limit,
                current: currentPage,
                total: data?.data?.totalItems,
                onChange: (page: number, pageSize: number) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                },
              }}
              className="hover table-slide"
              hasDefaultColumn={false}
            />
          </div>
        </div>
      }
    />
  );
};

export default ListIndicators;

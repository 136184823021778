import { FC } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.less";
import "./App.scss";
import MainLayout from "./layout/Main";
import Cash from "./pages/Cash";
import FormCategory from "./pages/CreateCategory/Category/CategoryForm";
import Categories from "./pages/CreateCategory/Category/ListCategories";
import EventForm from "./pages/CreateCategory/Event/EventForm";
import ListEvents from "./pages/CreateCategory/Event/ListEvents";
import Fund from "./pages/CreateCategory/Fund";
import ListNews from "./pages/CreateCategory/News/ListNews";
import NewsForm from "./pages/CreateCategory/News/NewsForm";
import Home from "./pages/Home";
import CategoryLibraryForm from "./pages/Library/CategoryLibrary/CategoryLibraryForm";
import CategoryLibrary from "./pages/Library/CategoryLibrary/ListCategoriesLibrary";
import IndicatorForm from "./pages/Library/Indicator/IndicatorForm";
import ListIndicators from "./pages/Library/Indicator/ListIndicators";
import ListMails from "./pages/ListMails";
import Login from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import Profile from "./pages/Profile";
import SliderCash from "./pages/Sliders/SliderCash/ListSliderCash";
import SliderCashForm from "./pages/Sliders/SliderCash/SliderCashForm";
import SliderFeedback from "./pages/Sliders/SliderFeedback/ListSliderFeeback";
import SliderFeedbackForm from "./pages/Sliders/SliderFeedback/SliderFeedbackForm";
import SliderGuest from "./pages/Sliders/SliderGuest/ListSliderGuest";
import SliderGuestForm from "./pages/Sliders/SliderGuest/SliderGuestForm";
import SliderGuide from "./pages/Sliders/SliderGuide/ListSliderGuide";
import SliderGuideForm from "./pages/Sliders/SliderGuide/SlideGuideForm";
import ListSlideHome from "./pages/Sliders/SliderHome/ListSliderHome";
import SliderHomeForm from "./pages/Sliders/SliderHome/SliderHomeForm";
import SystemSetting from "./pages/SystemSetting";
import CateForm from "./pages/Telegram/CategoriesTelegram/CateForm";
import ListCate from "./pages/Telegram/CategoriesTelegram/ListCate";
import ChannelForm from "./pages/Telegram/Channel/ChannelForm";
import ListChannels from "./pages/Telegram/Channel/ListChannels";
import CategoryForm from "./pages/Training/CategoriesTraining/CategoryForm";
import ListCategories from "./pages/Training/CategoriesTraining/ListCategories";
import ListPosts from "./pages/Training/Post/ListPosts";
import PostForm from "./pages/Training/Post/PostForm";
import OpenSiteRoutes from "./routes/openSiteRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import { appRoutes } from "./routes/routes";
import About from "./pages/Contents/About";
import InvestmentList from "./pages/Contents/Investment/InvestmentList";
import InvestmentForm from "./pages/Contents/Investment/InvestmentForm";
import SignList from "./pages/Contents/Sign/SignList";
import SignForm from "./pages/Contents/Sign/SignForm";
import TradingBlogForm from "./pages/Contents/TradingBlog/TradingBlogForm";
import TradingBlogList from "./pages/Contents/TradingBlog/TradingBlogList";
import GuideList from "./pages/Contents/Guide/GuideList";
import GuideForm from "./pages/Contents/Guide/GuideForm";
import ListQuestions from "./pages/Contents/Questions/ListQuestions";
import QuestionForm from "./pages/Contents/Questions/QuestionForm";
import Term from "./pages/Contents/Term";
import "font-awesome/css/font-awesome.min.css";
import RegisterList from "./pages/Register/RegisterList";
import RegisterDetails from "./pages/Register/RegisterDetails";
import Signs from "./pages/Sign/Signs";
import SignDetails from "./pages/Sign/SignDetails";
import Mails from "./pages/Mail/Mails";
import MailDetails from "./pages/Mail/MailDetails";
import ListOrder from "./pages/Order/ListOrder";
import OrderDetails from "./pages/Order/OrderDetails";
import ListCoupon from "./pages/Coupon/ListCoupon";
import CouponForm from "./pages/Coupon/CouponForm";
import AccountList from "./pages/Account/AccountList";
import AccountDetails from "./pages/Account/AccountDetails";
import NotificationList from "./pages/Notification/General/NotificationList";
import NotificationSignList from "./pages/Notification/Sign/NotificationSignList";
import NotificationSignDetails from "./pages/Notification/Sign/NotificationSignDetails";
import NotificationForm from "./pages/Notification/General/NotificationDetails";
import Import from "./pages/Import";
import OpenAccount from "./pages/OpenAccount";
import OpenAccountForm from "./pages/OpenAccount/OpenAccountForm";
import SliderToolDownload from "./pages/Sliders/SliderToolDownload/ListSliderToolDownload";
import SliderToolDownloadForm from "./pages/Sliders/SliderToolDownload/SliderToolDownloadForm";
import SliderWhyAI from "./pages/Sliders/SliderWhyAI/ListSliderWhyAI";
import SliderWhyAIForm from "./pages/Sliders/SliderWhyAI/SliderWhyAIForm";
import ValidateOtp from "./pages/Login/validateOtp";
import CategoriesBaiViet from "./pages/Contents/CategoryContents/ListCategories";
import FormCategoryBaiViet from "./pages/Contents/CategoryContents/CategoryForm";
import TradingThucChienList from "./pages/Contents/TradingThucChien/TradingThucChienList";
import TradingThucChienForm from "./pages/Contents/TradingThucChien/TradingThucChienForm";
import ReviewBrokerForm from "./pages/Contents/ReviewBrokor/ReviewBrokerForm";
import ReviewBrokerList from "./pages/Contents/ReviewBrokor/ReviewBrokerList";
import ListDanhGiaKhoaHoc from "./pages/Training/DanhGiaKhoaHoc/ListDanhGiaKhocHoc";
import DanhGiaForm from "./pages/Training/DanhGiaKhoaHoc/DanhGiaForm";
import ListGiangVien from "./pages/Training/GiangVien/ListGiangVien";
import GiangVienForm from "./pages/Training/GiangVien/GiangVienForm";
import TuVanDetails from "./pages/TuVanDanhMuc/TuVanDetails";
import ListTuVan from "./pages/TuVanDanhMuc/ListTuVan";
import ListMailDetails from "./pages/ListMails/ListMailDetails";

interface IProps {}

const App: FC<IProps> = (props) => {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<OpenSiteRoutes />}>
            <Route path={`${appRoutes.AUTH.LOGIN}`} element={<Login />} />
            <Route
              path={`${appRoutes.AUTH.LOGIN_2F}`}
              element={<ValidateOtp />}
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route
                path={appRoutes.SYSTEM_SETTING.ROOT}
                element={<SystemSetting />}
              />
              <Route
                path={appRoutes.PROFILE_SETTING.ROOT}
                element={<Profile />}
              />
              <Route path={appRoutes.MAIL_LIST.ROOT} element={<ListMails />} />
              <Route path={appRoutes.MAIL_LIST.DETAILS} element={<ListMailDetails />} />
              <Route
                path={appRoutes.SLIDERS.HOME.LIST}
                element={<ListSlideHome />}
              />
              <Route
                path={appRoutes.SLIDERS.HOME.ADD}
                element={<SliderHomeForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.HOME.DETAILS}
                element={<SliderHomeForm type={2} />}
              />
              <Route
                path={appRoutes.SLIDERS.CASH_FLOW.LIST}
                element={<SliderCash />}
              />
              <Route
                path={appRoutes.SLIDERS.CASH_FLOW.ADD}
                element={<SliderCashForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.CASH_FLOW.DETAILS}
                element={<SliderCashForm type={2} />}
              />
              <Route
                path={appRoutes.SLIDERS.TOOL_DOWNLOAD.LIST}
                element={<SliderToolDownload />}
              />
              <Route
                path={appRoutes.SLIDERS.TOOL_DOWNLOAD.ADD}
                element={<SliderToolDownloadForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.TOOL_DOWNLOAD.EDIT}
                element={<SliderToolDownloadForm type={2} />}
              />
              <Route
                path={appRoutes.SLIDERS.WHY_AI.LIST}
                element={<SliderWhyAI />}
              />
              <Route
                path={appRoutes.SLIDERS.WHY_AI.ADD}
                element={<SliderWhyAIForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.WHY_AI.EDIT}
                element={<SliderWhyAIForm type={2} />}
              />
              <Route
                path={appRoutes.SLIDERS.GUEST.LIST}
                element={<SliderGuest />}
              />
              <Route
                path={appRoutes.SLIDERS.GUEST.ADD}
                element={<SliderGuestForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.GUEST.DETAILS}
                element={<SliderGuestForm type={2} />}
              />
              <Route
                path={appRoutes.SLIDERS.GUIDE.LIST}
                element={<SliderGuide />}
              />
              <Route
                path={appRoutes.SLIDERS.GUIDE.ADD}
                element={<SliderGuideForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.GUIDE.DETAILS}
                element={<SliderGuideForm type={2} />}
              />
              <Route
                path={appRoutes.SLIDERS.FEEDBACK.LIST}
                element={<SliderFeedback />}
              />
              <Route
                path={appRoutes.SLIDERS.FEEDBACK.ADD}
                element={<SliderFeedbackForm type={1} />}
              />
              <Route
                path={appRoutes.SLIDERS.FEEDBACK.DETAILS}
                element={<SliderFeedbackForm type={2} />}
              />
              <Route path={appRoutes.CASH.ROOT} element={<Cash />} />
              <Route
                path={appRoutes.TRAINING.CATEGORIES.LIST}
                element={<ListCategories />}
              />
              <Route
                path={appRoutes.TRAINING.CATEGORIES.ADD}
                element={<CategoryForm type={1} />}
              />
              <Route
                path={appRoutes.TRAINING.CATEGORIES.DETAILS}
                element={<CategoryForm type={2} />}
              />
              <Route
                path={appRoutes.TRAINING.POSTS.LIST}
                element={<ListPosts />}
              />
              <Route
                path={appRoutes.TRAINING.POSTS.ADD}
                element={<PostForm type={1} />}
              />
              <Route
                path={appRoutes.TRAINING.POSTS.DETAILS}
                element={<PostForm type={2} />}
              />

              <Route
                path={appRoutes.TRAINING.GIANG_VIEN.LIST}
                element={<ListGiangVien />}
              />
              <Route
                path={appRoutes.TRAINING.GIANG_VIEN.ADD}
                element={<GiangVienForm type={1} />}
              />
              <Route
                path={appRoutes.TRAINING.GIANG_VIEN.DETAILS}
                element={<GiangVienForm type={2} />}
              />
              <Route
                path={appRoutes.TRAINING.DANH_GIA_KHOA_HOC.LIST}
                element={<ListDanhGiaKhoaHoc />}
              />
              <Route
                path={appRoutes.TRAINING.DANH_GIA_KHOA_HOC.ADD}
                element={<DanhGiaForm type={1} />}
              />
              <Route
                path={appRoutes.TRAINING.DANH_GIA_KHOA_HOC.DETAILS}
                element={<DanhGiaForm type={2} />}
              />
              <Route
                path={appRoutes.TELEGRAM.CATEGORIES.LIST}
                element={<ListCate />}
              />
              <Route
                path={appRoutes.TELEGRAM.CATEGORIES.ADD}
                element={<CateForm type={1} />}
              />
              <Route
                path={appRoutes.TELEGRAM.CATEGORIES.DETAILS}
                element={<CateForm type={2} />}
              />
              <Route
                path={appRoutes.TELEGRAM.CHANNELS.LIST}
                element={<ListChannels />}
              />
              <Route
                path={appRoutes.TELEGRAM.CHANNELS.ADD}
                element={<ChannelForm type={1} />}
              />
              <Route
                path={appRoutes.TELEGRAM.CHANNELS.DETAILS}
                element={<ChannelForm type={2} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.LIST}
                element={<Categories />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.ADD}
                element={<FormCategory type={1} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.CATEGORY.EDIT}
                element={<FormCategory type={2} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.LIST}
                element={<ListNews />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.ADD}
                element={<NewsForm type={1} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.NEWS_CATEGORY.NEWS.EDIT}
                element={<NewsForm type={2} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.EVENTS.LIST}
                element={<ListEvents />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.EVENTS.ADD}
                element={<EventForm type={1} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.EVENTS.EDIT}
                element={<EventForm type={2} />}
              />
              <Route
                path={appRoutes.CREATE_CATEGORY.FUND.ROOT}
                element={<Fund />}
              />
              <Route
                path={appRoutes.LIBRARY.CATEGORIES.LIST}
                element={<CategoryLibrary />}
              />
              <Route
                path={appRoutes.LIBRARY.CATEGORIES.ADD}
                element={<CategoryLibraryForm type={1} />}
              />
              <Route
                path={appRoutes.LIBRARY.CATEGORIES.DETAILS}
                element={<CategoryLibraryForm type={2} />}
              />
              <Route
                path={appRoutes.LIBRARY.INDICATOR.LIST}
                element={<ListIndicators />}
              />
              <Route
                path={appRoutes.LIBRARY.INDICATOR.ADD}
                element={<IndicatorForm type={1} />}
              />
              <Route
                path={appRoutes.LIBRARY.INDICATOR.DETAILS}
                element={<IndicatorForm type={2} />}
              />
              <Route path={appRoutes.CONTENT.ABOUT.ROOT} element={<About />} />
              <Route
                path={appRoutes.CONTENT.INVESTMENT.LIST}
                element={<InvestmentList />}
              />
              <Route
                path={appRoutes.CONTENT.INVESTMENT.ADD}
                element={<InvestmentForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.INVESTMENT.EDIT}
                element={<InvestmentForm type={2} />}
              />
              <Route
                path={appRoutes.CONTENT.SIGN.LIST}
                element={<SignList />}
              />
              <Route
                path={appRoutes.CONTENT.SIGN.ADD}
                element={<SignForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.SIGN.EDIT}
                element={<SignForm type={2} />}
              />
              <Route
                path={appRoutes.CONTENT.TRADING_BLOG.LIST}
                element={<TradingBlogList />}
              />
              <Route
                path={appRoutes.CONTENT.TRADING_BLOG.ADD}
                element={<TradingBlogForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.TRADING_BLOG.EDIT}
                element={<TradingBlogForm type={2} />}
              />
              <Route
                path={appRoutes.CONTENT.TRADING_THUC_CHIEN_V3.LIST}
                element={<TradingThucChienList />}
              />
              <Route
                path={appRoutes.CONTENT.TRADING_THUC_CHIEN_V3.ADD}
                element={<TradingThucChienForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.TRADING_THUC_CHIEN_V3.EDIT}
                element={<TradingThucChienForm type={2} />}
              />

              <Route
                path={appRoutes.CONTENT.REVIEW_BROKER_V3.LIST}
                element={<ReviewBrokerList />}
              />
              <Route
                path={appRoutes.CONTENT.REVIEW_BROKER_V3.ADD}
                element={<ReviewBrokerForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.REVIEW_BROKER_V3.EDIT}
                element={<ReviewBrokerForm type={2} />}
              />

              <Route
                path={appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.LIST}
                element={<CategoriesBaiViet />}
              />
              <Route
                path={appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.ADD}
                element={<FormCategoryBaiViet type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.CATEGORY_BAI_VIET_V3.EDIT}
                element={<FormCategoryBaiViet type={2} />}
              />

              <Route
                path={appRoutes.CONTENT.QUESTIONS.LIST}
                element={<ListQuestions />}
              />
              <Route
                path={appRoutes.CONTENT.QUESTIONS.ADD}
                element={<QuestionForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.QUESTIONS.EDIT}
                element={<QuestionForm type={2} />}
              />
              <Route
                path={appRoutes.CONTENT.GUIDE.LIST}
                element={<GuideList />}
              />
              <Route
                path={appRoutes.CONTENT.GUIDE.ADD}
                element={<GuideForm type={1} />}
              />
              <Route
                path={appRoutes.CONTENT.GUIDE.EDIT}
                element={<GuideForm type={2} />}
              />
              <Route path={appRoutes.CONTENT.TERM.ROOT} element={<Term />} />
              <Route
                path={appRoutes.REGISTER.ROOT}
                element={<RegisterList />}
              />
              <Route
                path={appRoutes.REGISTER.DETAILS}
                element={<RegisterDetails />}
              />
              <Route path={appRoutes.GET_SIGN.ROOT} element={<Signs />} />
              <Route
                path={appRoutes.GET_SIGN.DETAILS}
                element={<SignDetails />}
              />
              <Route path={appRoutes.MAIL.ROOT} element={<Mails />} />
              <Route path={appRoutes.MAIL.DETAILS} element={<MailDetails />} />
              <Route path={appRoutes.COUPON.LIST} element={<ListCoupon />} />
              <Route
                path={appRoutes.COUPON.ADD}
                element={<CouponForm type={1} />}
              />
              <Route
                path={appRoutes.COUPON.EDIT}
                element={<CouponForm type={2} />}
              />
              <Route
                path={appRoutes.ORDER.ROOT}
                element={
                  <ListOrder
                    title="Quản lý đơn hàng"
                    link={appRoutes.ORDER.ROOT}
                    isGlobal={false}
                  />
                }
              />
              <Route
                path={appRoutes.ORDER.DETAILS}
                element={
                  <OrderDetails
                    title="Quản lý đơn hàng"
                    link={appRoutes.ORDER.ROOT}
                    isGlobal={false}
                  />
                }
              />
              <Route
                path={appRoutes.ORDER_GLOBAL.ROOT}
                element={
                  <ListOrder
                    title="Quản lý đơn hàng Global"
                    link={appRoutes.ORDER_GLOBAL.ROOT}
                    isGlobal={true}
                  />
                }
              />

              <Route
                path={appRoutes.ORDER_GLOBAL.DETAILS}
                element={
                  <OrderDetails
                    title="Quản lý đơn hàng Global"
                    link={appRoutes.ORDER_GLOBAL.ROOT}
                    isGlobal={true}
                  />
                }
              />
              <Route
                path={appRoutes.TU_VAN_DANH_MUC.ROOT}
                element={
                  <ListTuVan
                    title="Tư vấn danh mục"
                    link={appRoutes.TU_VAN_DANH_MUC.ROOT}
                  />
                }
              />
              <Route
                path={appRoutes.TU_VAN_DANH_MUC.DETAILS}
                element={
                  <TuVanDetails
                    title="Tư vấn danh mục"
                    link={appRoutes.TU_VAN_DANH_MUC.ROOT}
                    isGlobal={false}
                  />
                }
              />
              <Route path={appRoutes.ACCOUNT.ROOT} element={<AccountList />} />
              <Route
                path={appRoutes.ACCOUNT.DETAILS}
                element={<AccountDetails />}
              />
              <Route
                path={appRoutes.NOTIFICATION.GENERAL.LIST}
                element={<NotificationList />}
              />
              <Route
                path={appRoutes.NOTIFICATION.GENERAL.DETAILS}
                element={<NotificationForm type={2} />}
              />
              <Route
                path={appRoutes.NOTIFICATION.GENERAL.ADD}
                element={<NotificationForm type={1} />}
              />
              <Route
                path={appRoutes.NOTIFICATION.SIGN.LIST}
                element={<NotificationSignList />}
              />
              <Route
                path={appRoutes.NOTIFICATION.SIGN.ADD}
                element={<NotificationSignDetails type={1} />}
              />
              <Route
                path={appRoutes.NOTIFICATION.SIGN.DETAILS}
                element={<NotificationSignDetails type={2} />}
              />
              <Route
                path={appRoutes.OPEN_ACCOUNT.LIST}
                element={<OpenAccount />}
              />
              <Route
                path={appRoutes.OPEN_ACCOUNT.DETAILS}
                element={<OpenAccountForm />}
              />
              <Route path={appRoutes.IMPORT.ROOT} element={<Import />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

import { Button, Card, Col, Form, message, Row } from "antd";
import { useForm } from "react-hook-form";
import InputField from "../../../components/InputField";
import TextAreaField from "../../../components/TextAreaField";
import { messages } from "../../../utils/messages";
import { regex } from "../../../utils/regex";
import { useMutation, useQuery } from "react-query";
import systemSettingApi from "../../../api/systemSettingApi";
import Loading from "../../../components/Loading";
import { formItemLayout } from "../../../utils/constants";
import { FC, useEffect } from "react";
import "./style.scss";

interface SettingContentProps {
  index: number;
}

const SettingContent: FC<SettingContentProps> = ({ index }) => {
  const { data, isLoading, isFetching }: any = useQuery(
    ["setting", "general"],
    systemSettingApi.getSystemSetting
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(systemSettingApi.editSystemSetting, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };
  useEffect(() => {
    reset();
  }, [index]);

  return (
    <>
      {isLoading || isFetching ? (
        <div className="setting-loading">
          <Loading />
        </div>
      ) : (
        <Card bordered={false}>
          <Form {...formItemLayout}>
            <Row gutter={[16, 10]}>
              <Col lg={12} md={24}>
                <InputField
                  name="ten_vi"
                  // rules={{
                  //   required: "Vui lòng nhập tên",
                  // }}
                  control={control}
                  // errorMsg={errors?.ten_vi?.message}
                  label="Tên"
                  placeholder="Tên"
                  defaultValue={data?.data?.ten_vi}
                />
                <InputField
                  name="title_vi"
                  control={control}
                  label="Title vi"
                  placeholder="Title vi"
                  defaultValue={data?.data?.title_vi}
                />
                <InputField
                  name="title_en"
                  control={control}
                  label="Title en"
                  placeholder="Title en"
                  defaultValue={data?.data?.title_en}
                />
                <TextAreaField
                  name="keyword"
                  control={control}
                  defaultValue={data?.data?.keyword}
                  label="Keywords vi"
                  placeholder="Keywords vi"
                  rows={3}
                />
                <TextAreaField
                  name="keyword_en"
                  control={control}
                  defaultValue={data?.data?.keyword_en}
                  label="Keywords en"
                  placeholder="Keywords en"
                  rows={3}
                />
                <TextAreaField
                  name="description"
                  control={control}
                  defaultValue={data?.data?.description}
                  label="Mô tả vi"
                  placeholder="Mô tả vi"
                />
                <TextAreaField
                  name="description_en"
                  control={control}
                  defaultValue={data?.data?.description_en}
                  label="Mô tả en"
                  placeholder="Mô tả en"
                />
              </Col>
              <Col lg={12} md={24}>
                <TextAreaField
                  name="map"
                  control={control}
                  defaultValue={data?.data?.map}
                  label="Bản đồ"
                  placeholder="Bản đồ"
                />
                <InputField
                  name="hotline"
                  control={control}
                  label="Hotline"
                  placeholder="Hotline"
                  defaultValue={data?.data?.hotline}
                />
                <InputField
                  name="zalo"
                  control={control}
                  label="Zalo"
                  placeholder="Zalo"
                  defaultValue={data?.data?.zalo}
                />
                <InputField
                  name="email"
                  errorMsg={errors?.email?.message}
                  control={control}
                  rules={{
                    // required: "Vui lòng nhập email",
                    validate: (value: string) => {
                      // if (value?.trim() == "") {
                      //   return messages.requiredEmail;
                      // }
                      if (regex.validateEmail.test(value?.trim()) === false) {
                        return messages.validateEmail;
                      }
                    },
                  }}
                  label="Email"
                  placeholder="Email"
                  defaultValue={data?.data?.email}
                />

                <InputField
                  name="diachi_vi"
                  control={control}
                  label="Địa chỉ"
                  placeholder="Địa chỉ"
                  defaultValue={data?.data?.diachi_vi}
                />
                <InputField
                  name="youtube"
                  control={control}
                  label="Youtube"
                  placeholder="Youtube"
                  defaultValue={data?.data?.youtube}
                />
                <InputField
                  name="facebook"
                  control={control}
                  label="Facebook"
                  placeholder="Facebook"
                  defaultValue={data?.data?.facebook}
                />
                <InputField
                  name="twitter"
                  control={control}
                  label="Twitter"
                  placeholder="Twitter"
                  defaultValue={data?.data?.twitter}
                />
                <InputField
                  name="website"
                  control={control}
                  label="Website"
                  placeholder="Website"
                  defaultValue={data?.data?.website}
                />
                <InputField
                  name="yotube_pr"
                  control={control}
                  label="Video PR"
                  placeholder="Video PR"
                  defaultValue={data?.data?.yotube_pr}
                />
                <InputField
                  type="number"
                  name="tumblr"
                  control={control}
                  label="Khuyến nghị thành công"
                  placeholder="Khuyến nghị thành công (%)"
                  defaultValue={data?.data?.tumblr}
                  suffix={"%"}
                  className="field_custom"
                />
                <InputField
                  type="number"
                  name="kyvong"
                  control={control}
                  label="Điểm kỳ vọng thị trường"
                  placeholder="Điểm kỳ vọng thị trường"
                  defaultValue={data?.data?.kyvong}
                  className="field_custom"
                />
              </Col>
            </Row>
            <Row justify="end">
              <Form.Item>
                <Button type="primary" danger onClick={handleSubmit(onSubmit)}>
                  Cập nhật
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Card>
      )}
    </>
  );
};

export default SettingContent;

import { configs } from "../config";
import { callApi } from "./axiosClient";

const train = "dao-tao";
const ThongTinKhoaHoc = "thong-tin-khoa-hoc";

const thongTinKhoaHocApi = {
  async getThongTinKhoaHoc() {
    const url = `${train}/${ThongTinKhoaHoc}`;
    return callApi("GET", url);
  },

  async editVeKhoaHoc({ data }: { data: any }) {
    const url = `${train}/${ThongTinKhoaHoc}/ve-khoa-hoc`;
    return callApi("PUT", url, data, configs);
  },


  async editGiangVien({ id, data }: { id: string | undefined; data: any }) {
    const url = `${train}/giang-vien/${id}`;
    return callApi("PUT", url, data, configs);
  },


  async addGiangVien({ data }: { data: any }) {
    const url = `${train}/giang-vien`;
    return callApi("POST", url, data, configs);
  },

  async deleteGiangVien(id: string | undefined) {
    const url = `${train}/giang-vien/${id}`;
    return callApi("DELETE", url);
  },

  async getDetailGiangVien(id: string | undefined) {
    const url = `${train}/giang-vien/${id}`;
    return callApi("GET", url);
  },

  async getListGiangVien() {
    const url = `${train}/giang-vien`;
    return callApi("GET", url);
  },


  async getListDanhGia() {
    const url = `${train}/phan-hoi`;
    return callApi("GET", url);
  },

  async getDetailPhanHoi(id: string | undefined) {
    const url = `${train}/phan-hoi/${id}`;
    return callApi("GET", url);
  },

  async addPhanHoi(data: any) {
    const url = `${train}/phan-hoi`;
    return callApi("POST", url, data);
  },

  async editPhanHoi({ id, data }: { id: string | undefined; data: any }) {
    const url = `${train}/phan-hoi/${id}`;
    return callApi("PUT", url, data);
  },
  async deletePhanHoi(id: string | undefined) {
    const url = `${train}/phan-hoi/${id}`;
    return callApi("DELETE", url);
  },
};

export default thongTinKhoaHocApi;

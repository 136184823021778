import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import couponApi from "../../../api/couponApi";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import CheckboxSlider from "../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../components/Table";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { messages } from "../../../utils/messages";

const activeOption = [
  {
    key: "0",
    value: "Tất cả",
  },
  {
    key: true,
    value: "Active",
  },
  {
    key: false,
    value: "Un-active",
  },
];

const hethanOption = [
  {
    key: "0",
    value: "Tất cả",
  },
  {
    key: true,
    value: "Hết hạn",
  },
  {
    key: false,
    value: "Chưa hết hạn",
  },
];
const hetnguoidungOption = [
  {
    key: "0",
    value: "Tất cả",
  },
  {
    key: true,
    value: "Hết người dùng",
  },
  {
    key: false,
    value: "Còn người dùng",
  },
];

const ListCoupon = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [code, setCode] = useState<string | undefined>();
  const [discountNumber, setDiscountNumber] = useState<number | undefined>();
  const [active, setActive] = useState<boolean | undefined>();
  const [hethan, setHetHan] = useState<boolean | undefined>();
  const [hetnguoidung, setHetNguoiDung] = useState<boolean | undefined>();
  const [exprt, setExport] = useState(false);

  const exportData = useQuery("coupon_download", couponApi.download, {
    enabled: exprt,
  });

  const { handleSubmit, control, setValue } = useForm();

  const { data, isLoading, isFetching }: any = useQuery(
    [
      "coupon",
      currentPage,
      limit,
      code,
      discountNumber,
      active,
      hethan,
      hetnguoidung,
    ],
    () =>
      couponApi.getAll(
        currentPage,
        limit,
        code,
        discountNumber,
        active,
        hethan,
        hetnguoidung
      )
  );
  const mutation = useMutation(couponApi.delete, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("coupon");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const mutationEdit = useMutation(couponApi.edit, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("coupon");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "#",
      width: "5%",
      align: "center",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (record: string) => <>{record ? record : "0"}%</>,
    },
    {
      title: "Số người dùng",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Đã dùng",
      dataIndex: "used",
      key: "used",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "ngaytao",
      key: "ngaytao",
      render: (record: string) => {
        if (record?.length === 0) return <></>;
        else return <>{moment(record).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "expire",
      key: "expire",
      render: (record: string) => {
        if (record === null) return "Vô thời hạn";
        else return <>{moment(record).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      align: "center",
      key: "active",
      width: "8%",
      render: (record: boolean, data: any) => {
        return (
          <CheckboxSlider
            _id={data?._id}
            check={record ?? false}
            action={mutationEdit}
            dataName="active"
          />
        );
      },
    },
    {
      title: "Sửa",
      key: "detail",
      align: "center",
      render: (_, record) => (
        <EditOutlined
          onClick={() => navigate(`${appRoutes.COUPON.LIST}/${record?._id}`)}
        />
      ),
    },
    {
      title: "Xóa",
      align: "center",
      width: "4%",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Xác nhận xóa mục này"
            style={{ width: "100px" }}
            onConfirm={(e: any) => {
              e.preventDefault();
              mutation.mutate(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const onFilter = (dt: any) => {
    setCode(dt.code);
    setActive(dt.active);
    setDiscountNumber(dt.discount_number);
    setHetHan(dt.hethan);
    setHetNguoiDung(dt.hetnguoidung);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (exportData !== undefined) setExport(false);
  }, [exportData]);

  return (
    <PageWrap
      title="Mã giảm giá"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Mã giảm giá</b>
                </Col>
                <Col>
                  <Button onClick={() => navigate(appRoutes.COUPON.ADD)}>
                    <PlusOutlined /> Add
                  </Button>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <>
              <Form className="register_common__form">
                <Row
                  style={{ marginBottom: "30px" }}
                  align="bottom"
                  gutter={[20, 10]}
                >
                  <Col lg={19} md={24} xs={24}>
                    <Row gutter={[20, 10]} align="bottom">
                      <Col md={4} sm={24} xs={12}>
                        <InputField
                          label="Mã giảm giá"
                          placeholder="Mã giảm giá"
                          name="code"
                          control={control}
                          setValue={setValue}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <InputField
                          label="% giảm"
                          type="number"
                          min={0}
                          placeholder="% giảm"
                          name="discount_number"
                          control={control}
                          setValue={setValue}
                          onBlur={(e: any) => {
                            if (e.target.value === "")
                              setValue("discount_number", 0);
                          }}
                        />
                      </Col>
                      <Col md={4} sm={24} xs={0}></Col>
                      {/* <Col md={4} sm={24}>
                        <SelectField
                          label="Active"
                          placeholder="Active"
                          options={activeOption}
                          defaultValue={"0"}
                          control={control}
                          name="trangthai"
                        />
                      </Col> */}
                      <Col md={4} sm={24} xs={12}>
                        <SelectField
                          label="Hết hạn"
                          placeholder="Hết hạn"
                          options={hethanOption}
                          defaultValue={"0"}
                          control={control}
                          name="hethan"
                        />
                      </Col>
                      <Col md={4} sm={24} xs={12}>
                        <SelectField
                          label="Hết người dùng"
                          placeholder="Hết người dùng"
                          options={hetnguoidungOption}
                          defaultValue={"0"}
                          control={control}
                          name="hetnguoidung"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} md={24} xs={24}>
                    <Row gutter={[20, 10]} justify="end">
                      <Col>
                        <Button
                          htmlType="submit"
                          className="primary_btn"
                          onClick={handleSubmit(onFilter)}
                        >
                          Lọc
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="export_btn"
                          onClick={() => setExport(true)}
                          loading={
                            exportData?.isLoading || exportData?.isFetching
                          }
                        >
                          Export Excel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TableWrap
                loading={isLoading || isFetching}
                columns={columns}
                isShowTotal
                dataSource={data?.data?.source}
                rowKey="_id"
                bordered
                pagination={{
                  pageSize: limit,
                  current: currentPage,
                  total: data?.data?.totalItems,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page);
                    setLimit(pageSize);
                  },
                }}
                className="hover table-slide"
                hasDefaultColumn={false}
              />
            </>
          </Card>
        </div>
      }
    />
  );
};

export default ListCoupon;

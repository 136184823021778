import React, { FC, useEffect, useState } from "react";
import QRCode from "qrcode";
import "./style.scss";
import InputField from "../InputField";
import { useForm } from "react-hook-form";
import { Button, Form, message } from "antd";
import { useMutation } from "react-query";
import authApi from "../../api/authApi";
import { messages } from "../../utils/messages";

interface TwoFactorAuthProps {
  otpauth_url: string;
  base32: string;
  handleClose: () => void;
}

const TwoFactorAuth: FC<TwoFactorAuthProps> = ({
  otpauth_url,
  base32,
  handleClose,
}) => {
  const [qrcodeUrl, setqrCodeUrl] = useState("");

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    QRCode.toDataURL(otpauth_url).then(setqrCodeUrl);
  }, []);

  const mutation = useMutation(authApi.register2F, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data?.code);
  };

  return (
    <Form className="two_factor">
      <div className="two_factor__item">
        <h4>Cấu hình Google Authenticator hoặc Authy</h4>
        <li>
          Cài đặt Google Authenticator (IOS - Android) hoặc Authy (IOS -
          Android).
        </li>
        <li>Trong ứng dụng xác thực, hãy chọn biểu tượng "+".</li>
        <li>
          Chọn "Quét mã vạch (hoặc mã QR)" và sử dụng camera của điện thoại để
          quét mã vạch này.
        </li>
      </div>
      <div className="two_factor__item">
        <h4>Quét mã QR</h4>
        <div className="qr_wrap">
          <img src={qrcodeUrl} alt="qrcode url" />
        </div>
      </div>
      <div className="two_factor__item">
        <h4>Hoặc nhập mã vào app</h4>
        {base32 ? "Secret Key: " + base32 : ""}
      </div>
      <div className="two_factor__item">
        <h4>Mã xác thực</h4>
        <InputField
          name="code"
          control={control}
          className="qr_input"
          placeholder="Mã xác thực"
        />
      </div>
      <div className="two_factor__btn">
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Kích hoạt
        </Button>
      </div>
    </Form>
  );
};

export default TwoFactorAuth;

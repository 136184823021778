import { Button, Card, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import orderApi from "../../../api/orderApi";
import InputField from "../../../components/InputField";
import Loading from "../../../components/Loading";
import SelectField from "../../../components/SelectField";
import TextAreaField from "../../../components/TextAreaField";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { formItemLayoutSm, stts } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
interface OrderDetailsProps {
  title: string;
  link: string;
  isGlobal: Boolean,
}
const OrderDetails: FC<OrderDetailsProps> = ({ title, link, isGlobal }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery(
    [isGlobal ? "order-global ": "order", id],
    () => orderApi.getDetails(id, isGlobal),
    {
      onSuccess: (data: any) => {
        if (data?.status === 422) navigate(appRoutes.NOT_FOUND);
      },
    }
  );
  const listUser = useQuery("listUser", () => orderApi.getServices({isGlobal}));

  const { handleSubmit, control } = useForm();

  const mutation = useMutation(orderApi.edit, {
    onSuccess: async (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        await Promise.all([
          queryClient.invalidateQueries(isGlobal ? "order-global ": "order"),
          queryClient.invalidateQueries("noti"),
        ]);
        navigate(link);
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const onSubmit = (dt: any) => {
    mutation.mutate({ id: id, data: dt, isGlobal });
  };

  queryClient.invalidateQueries("noti");

  return (
    <PageWrap
      title={title}
      content={
        <>
          <TopContent
            content={
              <Row>
                <Col>
                  <b>{title}</b>
                </Col>
              </Row>
            }
          />
          <div className="content">
            {isLoading || isFetching ? (
              <Loading />
            ) : (
              <Card bordered={false}>
                <Form {...formItemLayoutSm} className="slider-form">
                  <SelectField
                    name="trangthai"
                    label="Trạng thái"
                    options={stts}
                    defaultValue={data?.data?.trangthai}
                    control={control}
                    listHeight={160}
                  />
                  <SelectField
                    name="goi"
                    label="Gói dịch vụ"
                    options={listUser?.data?.data?.map((each: any) => {
                      return {
                        key: each.id,
                        value: each.title,
                      };
                    })}
                    defaultValue={data?.data?.goi}
                    control={control}
                    listHeight={128}
                  />
                  <InputField
                    defaultValue={data?.data?.hoten}
                    control={control}
                    name="hoten"
                    label="Họ / Tên"
                    placeholder="Họ / Tên"
                  />
                  <InputField
                    defaultValue={data?.data?.dienthoai}
                    control={control}
                    name="dienthoai"
                    label="Điện thoại"
                    placeholder="Điện thoại"
                  />
                  <InputField
                    defaultValue={data?.data?.email}
                    control={control}
                    name="email"
                    label="Email"
                    placeholder="Email"
                  />
                  <InputField
                    defaultValue={data?.data?.diachi}
                    control={control}
                    name="diachi"
                    label="Địa chỉ"
                    placeholder="Địa chỉ"
                  />
                  <InputField
                    defaultValue={data?.data?.nguoigioithieu}
                    control={control}
                    name="nguoigioithieu"
                    label="Người giới thiệu"
                    placeholder="Người giới thiệu"
                  />
                  <TextAreaField
                    defaultValue={data?.data?.ghichu}
                    control={control}
                    name="ghichu"
                    label="Ghi chú"
                    placeholder="Ghi chú"
                  />
                  <Row
                    justify="start"
                    gutter={[10, 0]}
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button
                        type="primary"
                        danger
                        onClick={handleSubmit(onSubmit)}
                      >
                        Lưu
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => navigate(link)}>Hủy</Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
          </div>
        </>
      }
    />
  );
};

export default OrderDetails;

import { DeleteOutlined, EditOutlined, LockFilled } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import accountApi from "../../../api/accountApi";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import CheckboxSlider from "../../../components/SliderList/CheckboxSlider";
import TableWrap from "../../../components/Table";
import TopContent from "../../../components/TopContent";
import PageWrap from "../../../layout/PageWrap";
import { appRoutes } from "../../../routes/routes";
import { memberType, roles } from "../../../utils/constants";
import { messages } from "../../../utils/messages";
import useWindowDimensions from "../../../hook/WindowDimensions";
import "./style.scss";

const AccountList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [email, setEmail] = useState<string | undefined>();
  const [ten, setTen] = useState<string | undefined>();
  const [dienthoai, setDienthoai] = useState<string | undefined>();
  const [membership, setMembership] = useState<number | undefined>();
  const [role, setRole] = useState<number | undefined>();
  const { isMobile } = useWindowDimensions();

  const { handleSubmit, control } = useForm();

  const { data, isLoading, isFetching }: any = useQuery(
    ["account", currentPage, limit, email, ten, dienthoai, membership, role],
    () =>
      accountApi.getAll(
        currentPage,
        limit,
        email,
        ten,
        dienthoai,
        membership,
        role
      )
  );
  const mutation = useMutation(accountApi.delete, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.deleteSuccess);
        queryClient.invalidateQueries("account");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const update = useMutation(accountApi.edit, {
    onSuccess: (data: any) => {
      if (data.statusCode === 200) {
        message.success(messages.updateSuccess);
        queryClient.invalidateQueries("account");
      } else {
        message.error(data?.data?.message ?? messages.error);
      }
    },
    onError: (err: any) => {
      message.error(err?.data?.message ?? messages.error);
    },
  });

  const columns: ColumnsType<any> = isMobile
    ? [
        {
          title: "#",
          width: "3%",
          align: "center",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Họ và tên",
          dataIndex: "ten",
          key: "ten",
          width: "10%",
        },
        {
          title: "Điện thoại",
          dataIndex: "dienthoai",
          key: "dienthoai",
        },
        {
          title: "Thời hạn VIP",
          dataIndex: "day_expires",
          key: "day_expires",
          width: "12%",
          render: (record: string, data: any) => {
            if (record)
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={!data?.expires ? "success_flag" : "warning_flag"}
                  >
                    {!data?.expires ? "Còn hạn" : "Hết hạn"}
                  </div>
                  {record}
                </div>
              );
          },
        },
        {
          title: "Gói đăng ký",
          dataIndex: "membership",
          key: "membership",
          width: "6%",
        },
        {
          title: "Ngày đăng ký",
          dataIndex: "ngaydangky",
          key: "ngaydangky",
          width: "7%",
          render: (record: string) => {
            if (record?.length === 0) return <></>;
            else return <>{moment(record).format("DD/MM/YYYY")}</>;
          },
        },
        {
          title: "Active",
          dataIndex: "hienthi",
          align: "center",
          key: "hienthi",
          render: (record: boolean, data: any) => {
            return (
              <CheckboxSlider
                _id={data?._id}
                check={record}
                action={update}
                dataName="hienthi"
              />
            );
          },
        },
        {
          title: "Block",
          dataIndex: "blocked",
          align: "center",
          key: "blocked",
          render: (record: boolean, data: any) => {
            if (record)
              return (
                <div className="block_item">
                  Blocked&nbsp;
                  <LockFilled />
                </div>
              );
          },
        },
        {
          title: "Edit",
          key: "detail",
          align: "center",
          render: (_, record) => (
            <EditOutlined
              onClick={() =>
                navigate(`${appRoutes.ACCOUNT.ROOT}/${record?._id}`)
              }
            />
          ),
        },
        {
          title: "Soft Delete",
          dataIndex: "deleted",
          align: "center",
          key: "deleted",
          width: "6%",
          render: (record: boolean, data: any) => {
            return (
              <CheckboxSlider
                _id={data?._id}
                check={record}
                action={update}
                dataName="deleted"
              />
            );
          },
        },
        {
          title: "Delete",
          align: "center",
          width: "4%",
          render: (_, record) => {
            return (
              <Popconfirm
                title="Xác nhận xóa mục này"
                style={{ width: "100px" }}
                onConfirm={(e: any) => {
                  e.preventDefault();
                  mutation.mutate(record?._id);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            );
          },
        },
      ]
    : [
        {
          title: "#",
          width: "3%",
          align: "center",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        // {
        //   title: "Username",
        //   dataIndex: "username",
        //   key: "username",
        // },
        {
          title: "Họ và tên",
          dataIndex: "ten",
          key: "ten",
          width: "10%",
        },
        {
          title: "Điện thoại",
          dataIndex: "dienthoai",
          key: "dienthoai",
        },
        {
          title: "Địa chỉ",
          dataIndex: "diachi",
          key: "diachi",
        },
        {
          title: "Thời hạn VIP",
          dataIndex: "day_expires",
          key: "day_expires",
          width: "12%",
          render: (record: string, data: any) => {
            if (record)
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={!data?.expires ? "success_flag" : "warning_flag"}
                  >
                    {!data?.expires ? "Còn hạn" : "Hết hạn"}
                  </div>
                  {record}
                </div>
              );
          },
        },
        {
          title: "Gói đăng ký",
          dataIndex: "membership",
          key: "membership",
          width: "6%",
        },
        {
          title: "Loại user",
          dataIndex: "role",
          key: "role",
          width: "5%",
        },
        {
          title: "Ngày đăng ký",
          dataIndex: "ngaydangky",
          key: "ngaydangky",
          width: "7%",
          render: (record: string) => {
            if (record?.length === 0) return <></>;
            else return <>{moment(record).format("DD/MM/YYYY")}</>;
          },
        },
        {
          title: "Chuyên gia",
          dataIndex: "chuyengia",
          key: "chuyengia",
          width: "7%",
          render: (record: any) => {
            return <>{record?.name}</>;
          },
        },
        {
          title: "Active",
          dataIndex: "hienthi",
          align: "center",
          key: "hienthi",
          render: (record: boolean, data: any) => {
            return (
              <CheckboxSlider
                _id={data?._id}
                check={record}
                action={update}
                dataName="hienthi"
              />
            );
          },
        },
        {
          title: "Block",
          dataIndex: "blocked",
          align: "center",
          key: "blocked",
          render: (record: boolean, data: any) => {
            if (record)
              return (
                <div className="block_item">
                  Blocked&nbsp;
                  <LockFilled />
                </div>
              );
          },
        },
        {
          title: "Edit",
          key: "detail",
          align: "center",
          render: (_, record) => (
            <EditOutlined
              onClick={() =>
                navigate(`${appRoutes.ACCOUNT.ROOT}/${record?._id}`)
              }
            />
          ),
        },
        {
          title: "Soft Delete",
          dataIndex: "deleted",
          align: "center",
          key: "deleted",
          width: "6%",
          render: (record: boolean, data: any) => {
            return (
              <CheckboxSlider
                _id={data?._id}
                check={record}
                action={update}
                dataName="deleted"
              />
            );
          },
        },
        {
          title: "Delete",
          align: "center",
          width: "4%",
          render: (_, record) => {
            return (
              <Popconfirm
                title="Xác nhận xóa mục này"
                style={{ width: "100px" }}
                onConfirm={(e: any) => {
                  e.preventDefault();
                  mutation.mutate(record?._id);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            );
          },
        },
      ];

  const onFilter = (dt: any) => {
    setEmail(dt.email);
    setTen(dt.ten);
    setDienthoai(dt.dienthoai);
    setMembership(dt.membership);
    setRole(dt.role);
    setCurrentPage(1);
  };

  return (
    <PageWrap
      title="Tài khoản thành viên"
      content={
        <div className="slider_list">
          <TopContent
            content={
              <Row>
                <Col>
                  <b>Tài khoản thành viên</b>
                </Col>
              </Row>
            }
          />
          <Card bordered={false} className="register_common">
            <>
              <Form className="register_common__form">
                <Row
                  style={{ marginBottom: "30px" }}
                  align="bottom"
                  gutter={[20, 10]}
                >
                  <Col md={4} sm={24} xs={12}>
                    <InputField
                      label="Họ tên"
                      placeholder="Họ tên"
                      name="ten"
                      control={control}
                    />
                  </Col>
                  <Col md={4} sm={24} xs={12}>
                    <InputField
                      label="Email"
                      placeholder="Email"
                      name="email"
                      control={control}
                    />
                  </Col>
                  <Col md={4} sm={24} xs={12}>
                    <InputField
                      label="Điện thoại"
                      placeholder="Điện thoại"
                      control={control}
                      name="dienthoai"
                    />
                  </Col>
                  <Col md={4} sm={24} xs={12}>
                    <SelectField
                      label="Hạng thành viên"
                      placeholder="Hạng thành viên"
                      options={memberType}
                      control={control}
                      name="membership"
                      allowClear={true}
                      listHeight={160}
                    />
                  </Col>
                  <Col md={4} sm={24} xs={12}>
                    <SelectField
                      label="Loại thành viên"
                      placeholder="Loại thành viên"
                      options={roles}
                      control={control}
                      name="role"
                      allowClear={true}
                    />
                  </Col>
                  <Col md={4} sm={24} xs={12}>
                    <Row justify="end">
                      <Button
                        htmlType="submit"
                        className="primary_btn"
                        onClick={handleSubmit(onFilter)}
                      >
                        Lọc
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <TableWrap
                loading={isLoading || isFetching}
                columns={columns}
                isShowTotal
                dataSource={data?.data?.source}
                rowKey="_id"
                bordered
                pagination={{
                  pageSize: limit,
                  current: currentPage,
                  total: data?.data?.totalItems,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page);
                    setLimit(pageSize);
                  },
                }}
                className="hover table-slide"
                hasDefaultColumn={false}
              />
            </>
          </Card>
        </div>
      }
    />
  );
};

export default AccountList;

import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import listEmailApi from "../../api/listEmailApi";
import TopContent from "../../components/TopContent";
import PageWrap from "../../layout/PageWrap";
import MailTable from "./MailTable";
import "./style.scss";

const ListMails = () => {
  const [enable, setEnable] = useState<boolean>(false);
  const { isLoading, isFetching } = useQuery(
    "download-list-emails",
    listEmailApi.download,
    {
      enabled: enable,
      onSuccess: () => setEnable(false),
    }
  );

  return (
    <PageWrap
      title="Danh sách emails"
      content={
        <div className="list_emails_wrap">
          <TopContent
            content={
              <Row>
                <Col>Danh sách email đã đăng ký</Col>
                <Col>
                  <Button
                    icon={<DownloadOutlined />}
                    style={{ marginLeft: "10px" }}
                    loading={isLoading || isFetching}
                    onClick={() => setEnable(true)}
                  >
                    Xuất file excel
                  </Button>
                </Col>
              </Row>
            }
          />
          <div className="content">
            <MailTable />
          </div>
        </div>
      }
    />
  );
};

export default ListMails;
